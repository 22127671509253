<template>
  <base-modal class="base-modal-confirm" @close="close">
    <div class="base-modal-confirm__content">
      <slot/>
    </div>
    <div class="base-modal-confirm__buttons" v-if="$slots.success">
      <div class="base-modal-confirm__close" @click="close">
        {{ t('modal.close') }}
      </div>
      <base-button color="danger" @action="success">
        <slot name="success"/>
      </base-button>
    </div>
  </base-modal>
</template>

<script setup>
import BaseModal from '@/components/Base/Modal/BaseModal'
import BaseButton from '@/components/Base/BaseButton'
import {defineEmits} from 'vue'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
const emit = defineEmits(['close', 'success'])

const close = () => emit('close')
const success = () => emit('success')
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.base-modal-confirm {
  &__buttons {
    display: flex;
    align-items: center;
    gap: 36px;
    margin-top: 33px;
  }

  &__close {
    font-weight: 500;
    font-size: 18px;
    color: $color-danger;
    cursor: pointer;
  }
}
</style>

<template>
  <div class="base-checkbox">
    <input
        class="base-checkbox__item"
        name="checkbox-input"
        type="checkbox"
        :id="name"
        :checked="inputValue"
        @change="onChange"
    >
    <label :for="name">
      <slot/>
    </label>
  </div>
</template>

<script>
import {ref, toRefs, watch} from 'vue'

export default {
  name: 'BaseCheckbox',
  emits: ['update:modelValue'],
  props: {
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Number, Boolean],
    },
  },
  setup(props, {emit}) {
    const {modelValue} = toRefs(props)
    const inputValue = ref(modelValue.value)
    const onChange = () => emit('update:modelValue', !inputValue.value)
    watch(modelValue, value => {
      inputValue.value = value
    })
    return {
      inputValue,
      onChange,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.base-checkbox {
  display: inline-block;

  &__item {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 20px;

    & + label {
      display: inline-block;
      position: relative;
      padding: 0 0 0 32px;
      cursor: pointer;
      user-select: none;
    }

    & + label:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 18px;
      height: 18px;
      border-radius: 4px;
      background: white;
      border: 1px solid $border-color;
      transition: .2s;
    }

    & + label:after {
      content: url('~@/assets/ok.svg');
      position: absolute;
      top: -1px;
      left: 4px;
      transition: .2s;
      opacity: 0;
    }

    &:checked + label:before {
      border: 1px solid $color-danger;
    }

    &:checked + label:after {
      opacity: 1;
    }

    &_disabled {
      & + label {
        opacity: .5;
        cursor: default;
      }
    }
  }
}
</style>

<template>
  <div class="alert">
    <slot/>
  </div>
</template>

<style lang="scss" scoped>
.alert {
  padding: 20px;
  border-radius: 14px;
  border: 1px solid transparent;
  text-align: center;
}
</style>

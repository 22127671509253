<template>
  <div class="constructor-how">
    <div class="container">
      <div class="constructor-how__wrapper">
        <header class="constructor-how__header">
          <base-title type="h2">{{ t(`${baseT}title`) }}</base-title>
        </header>
        <div class="constructor-how__steps">
          <div v-for="number in 5" class="constructor-how__step" :key="number">
            <div class="constructor-how__icon">
              <span v-if="number < 5">{{ number }}</span>
              <img v-else :src="imageConstructorHow" alt="constructor how">
            </div>
            <div class="constructor-how__name" v-html="t(`${baseT}step_${number}`)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'
import imageConstructorHow from '@/assets/configurator-how.png'

const {t} = useI18n()
const baseT = 'constructor.how.'
</script>

<style lang="scss" scoped>
@import "~@/scss/mixins.scss";

.constructor-how {
  position: relative;

  &__wrapper {
    position: relative;
    box-shadow: 0 7px 41px -9px rgba(106, 113, 128, 0.31);
    border-radius: 40px;
    padding: 48px 0 56px;
    z-index: 1;

    &::before {
      position: absolute;
      inset: 0;
      content: '';
      background: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(10px);
      border-radius: 40px;
    }

    & > * {
      position: relative;
    }
  }

  &__header {
    text-align: center;
  }

  &__steps {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 30px 21px;
    margin-top: 71px;
    text-align: center;
  }

  &__step {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 21px;
  }

  &__icon {
    @include background-gradient;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 96px;
    border-radius: 48px;
    margin-bottom: 24px;

    &::before {
      position: absolute;
      inset: 3px;
      content: '';
      background-color: white;
      border-radius: 48px;
      border: 5px solid #ececec;
    }

    & > * {
      position: relative;
    }

    span {
      @include background-gradient;

      font-weight: 500;
      font-size: 42px;
      line-height: 49px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

@media (max-width: 991px) {
  .constructor-how {
    &__steps {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media (max-width: 767px) {
  .constructor-how {
    &__steps {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: 575px) {
  .constructor-how {
    &__wrapper {
      padding: 40px 0 56px;
    }

    &__header {
      .base-title-h2 {
        font-size: 28px;
        line-height: 33px;
      }
    }

    &__steps {
      margin-top: 39px;
      grid-template-columns: 1fr;
      padding: 0 18px;
    }

    &__step {
      display: grid;
      grid-template-columns: 80px 1fr;
      align-items: center;
      gap: 13px;
      text-align: left;
    }

    &__icon {
      width: 80px;
      height: 80px;
      margin-bottom: 0;

      span {
        font-size: 32.3333px;
        line-height: 38px;
      }

      img {
        max-width: 47px;
      }
    }
  }
}
</style>

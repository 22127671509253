<template>
  <form class="faq-form">
    <fieldset>
      <legend>{{ t(`${baseT}title`) }}</legend>
      <BaseTextarea v-model="question" :placeholder="t(`${baseT}question_label`)"/>
      <BaseInput
          v-model="email"
          inputmode="email"
          type-input="email"
          :placeholder="t(`${baseT}email_label`)"
      />
      <div class="faq-form__footer">
        <BaseLoading v-if="pending"/>
        <base-button @action="send" :disabled="pending">{{ t(`${baseT}button`) }}</base-button>
      </div>
    </fieldset>
    <teleport to="body">
      <base-modal-message v-if="openFieldsError" @close="closeModalFields">
        {{ t('form.error_fields') }}
      </base-modal-message>
      <base-modal-message v-if="openSendError" @close="closeModalSendError">
        {{ t('form.error_send', {email: 'info@erachain.org'}) }}
      </base-modal-message>
      <base-modal-alert v-if="openSuccess" @close="closeModalSuccess">
        <p>{{ t(`${baseT}success_title`) }}</p>
        <p>{{ t(`${baseT}success_message`) }}</p>
      </base-modal-alert>
    </teleport>
  </form>
</template>

<script setup>
import BaseTextarea from '@/components/Base/BaseTextarea'
import {ref} from 'vue'
import BaseInput from '@/components/Base/BaseInput'
import {useI18n} from 'vue-i18n'
import BaseButton from '@/components/Base/BaseButton'
import {sendEmailFaq} from '@/services/email'
import useForm from '@/use/form'
import BaseModalMessage from '@/components/Base/Modal/BaseModalMessage'
import BaseModalAlert from '@/components/Base/Modal/BaseModalAlert'
import BaseLoading from '@/components/Base/BaseLoading'

const {reset} = useForm()

const {t} = useI18n()
const baseT = 'faq.form.'

const question = ref('')
const email = ref('')

const openFieldsError = ref(false)
const openSuccess = ref(false)
const openSendError = ref(false)

const pending = ref(false)

const closeModal = openStatus => {
  openStatus.value = false
}

const closeModalFields = () => closeModal(openFieldsError)
const closeModalSuccess = () => closeModal(openSuccess)
const closeModalSendError = () => closeModal(openSendError)

const send = () => {
  if (![question, email].every(({value}) => !!value)) {
    return openFieldsError.value = true
  }

  const formData = new FormData()
  formData.append('question', question.value)
  formData.append('email', email.value)

  pending.value = true

  sendEmailFaq(formData).then(sendStatus => {
    if (sendStatus) {
      openSuccess.value = true
      reset([question, email])
    } else {
      openSendError.value = true
    }
  }).catch(() => {
    openSendError.value = true
  }).finally(() => {
    pending.value = false
  })
}
</script>

<style lang="scss" scoped>
.faq-form {
  background: #F5F5FB;
  border: 1px solid #6153DB;
  border-radius: 25px;
  padding: 40px 48px 48px;

  fieldset {
    text-align: right;
  }

  legend {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: black;
    margin-bottom: 33px;
    text-align: left;
  }

  .base-textarea {
    background: #FFFFFF;
    border: 1px solid #DEDDE7;
    border-radius: 15px;
    min-height: 84px;
    padding: 15px 22px;
  }

  .base-input {
    background: #FFFFFF;
    border: 1px solid #DEDDE7;
    border-radius: 15px;
    height: 48px;
    padding: 0 22px;
    margin-top: 13px;
    margin-bottom: 27px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
  }
}
</style>

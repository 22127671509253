<template>
  <div class="base-modal" @click="close">
    <div class="base-modal__wrapper" @click.stop>
      <slot/>
    </div>
  </div>
</template>

<script setup>
import {defineEmits} from 'vue'

const emit = defineEmits(['close'])

const close = () => {
  emit('close')
}
</script>

<style lang="scss" scoped>
.base-modal {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 410px;
    max-width: 556px;
    margin: auto;
    background: #FFFFFF;
    box-shadow: 0 13px 50px -19px rgba(71, 81, 104, 0.47);
    border-radius: 26px;
    padding: 47px 53px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
}
</style>

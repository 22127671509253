<template>
  <span
      ref="input"
      contenteditable="true"
      class="base-input-flexible"
      :class="{'base-input-flexible__error': error && saveForm}"
      :placeholder="placeholder"
      :data-postfix="postfix"
      @input="setInputValue"
      @keypress="onKeypress"
  />
</template>

<script>
import {ref, toRefs, watch, computed, inject} from 'vue'

export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    postfix: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const {modelValue, type, required} = toRefs(props)
    const input = ref(null)
    const setInputValue = event => {
      emit('update:modelValue', type.value === 'number'
          ? Number(event.target.textContent || 0)
          : event.target.textContent || '')
    }
    const saveForm = inject('saveForm')
    const error = computed(() => required.value && !input.value)

    const onKeypress = e => {
      if (type.value === 'number') {
        let x = e.charCode || e.keyCode
        if (isNaN(String.fromCharCode(e.which)) && x !== 46 || x === 32 || x === 13 ||
            (x === 46 && e.currentTarget.innerText.includes('.'))) e.preventDefault()
      }
    }

    watch(modelValue, value => {
      if (input?.value?.textContent && input.value.textContent !== value) {
        input.value.textContent = value
      }
    })
    return {
      saveForm,
      error,
      input,
      setInputValue,
      onKeypress,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

.base-input-flexible {
  min-width: 100%;
  border-bottom: 1px solid $border-color;
  outline: none;
  color: $color-text;
  padding: 0;
  font-feature-settings: 'pnum' on, 'lnum' on;

  &:after {
    content: attr(data-postfix);
  }

  &[placeholder]:empty:before {
    content: attr(placeholder);
    color: $border-color;
  }

  &[placeholder]:empty:focus::before {
    content: "";
  }

  &:focus {
    border-bottom: 1px solid $color-primary;
  }

  &__error {
    border-bottom: 1px solid $color-danger !important;
  }
}
</style>

export const sendGTMEvent = eventName => {
  try {
    // eslint-disable-next-line no-undef
    dataLayer.push({'event': eventName})
  } catch (e) {
    console.warn('dataLayer', e)
  }
}

export const sendYMEvent = eventName => {
  try {
    // eslint-disable-next-line no-undef
    ym(process.env.VUE_APP_YM_ID, 'reachGoal', eventName)
  } catch (e) {
    console.warn('ym', e)
  }
}

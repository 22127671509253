<template>
  <div class="home-architecture">
    <div class="container">
      <header class="home-architecture__header">
        <base-title type="h2" big>{{ t(`${baseT}title`) }}</base-title>
      </header>
      <div class="home-architecture__list row gy-4">
        <div
            v-for="(solution, idx) in solutions"
            class="col-md-6 col-lg-5"
            :class="{'offset-lg-1': idx % 2 === 0}"
            :key="solution"
        >
          <div class="home-architecture__solution">
            <img :src="imageSolutions[solution]" :alt="solution">
            <div class="home-architecture__solution-name">{{ t(`${baseT}${solution}.name`) }}</div>
            <div class="home-architecture__solution-desc">{{ t(`${baseT}${solution}.desc`) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'
import imageArchitectureFunctional from '@/assets/architecture-functional.png'
import imageArchitectureTokens from '@/assets/architecture-tokens.png'
import imageArchitectureOnChain from '@/assets/architecture-on-chain.png'
import imageArchitectureProfile from '@/assets/architecture-profile.png'

const {t} = useI18n()
const baseT = 'home.architecture.'

const solutions = ['functional', 'profile', 'tokens', 'on-chain']
const imageSolutions = {
  functional: imageArchitectureFunctional,
  tokens: imageArchitectureTokens,
  'on-chain': imageArchitectureOnChain,
  profile: imageArchitectureProfile,
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables.scss';

.home-architecture {
  &__header {
    margin-bottom: 124px;
    text-align: center;

    @media (max-width: 767px) {
      margin-bottom: 60px;
    }
  }

  &__solution {
    background-color: #F5F5FB;
    border-radius: 23px;
    height: 100%;
    padding: $row ($row + $row * 0.333333);

    &-name {
      margin: 14px 0;
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;
    }
  }
}

@media (max-width: 767px) {
  .home-architecture__header, .home-architecture__image {
    text-align: center;
  }
}
</style>

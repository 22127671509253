import {createRouter, createWebHistory, RouterView} from 'vue-router'
import ConstructorPage from '@/pages/Constructor/ConstructorPage'
import HomePage from '@/pages/Home/HomePage.vue'
import TechnologyPage from '@/pages/Technology/TechnologyPage'
import CheckoutPage from '@/pages/Checkout/CheckoutPage'
import ContactsPage from '@/pages/Contacts/ContactsPage'
import FAQPage from '@/pages/FAQ/FAQPage'

const routes = [
  {
    path: '/',
    component: RouterView,
    redirect: {name: 'home'},
    children: [
      {
        path: 'home',
        name: 'home',
        component: HomePage,
      },
      {
        path: 'technology',
        name: 'technology',
        component: TechnologyPage,
      },
      {
        path: 'checkout',
        name: 'checkout',
        component: CheckoutPage,
      },
      {
        path: 'contacts',
        name: 'contacts',
        component: ContactsPage,
      },
      {
        path: 'constructor',
        name: 'constructor',
        component: ConstructorPage,
      },
      {
        path: 'faq',
        name: 'faq',
        component: FAQPage,
      },
    ],
  },
  {path: '/:pathMatch(.*)', redirect: {name: 'home'}},
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from) {
    if (to.name !== from.name) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({left: 0, top: 0})
        }, 0)
      })
    }
  },
})

export default router

<template>
  <div class="constructor-application-block" :class="{'constructor-application-block_active': status}">
    <header class="constructor-application-block__header">
      <div class="constructor-application-block__icon">
        <img :src="iconUrl" alt="icon">
      </div>
      <div class="constructor-application-block__title" v-if="$slots.title">
        <span>
          <slot name="title"/>
        </span>
        <div class="constructor-application-block__desc" v-if="$slots.desc">
          <slot name="desc"/>
        </div>
      </div>
    </header>
    <div class="constructor-application-block__info" v-if="$slots.info">
      <slot name="info"/>
    </div>
    <div class="constructor-application-block__footer">
      <div class="constructor-application-block__status" @click="changeStatus">
        <img :src="status ? imageStatusTrue : imageStatusFalse" alt="status">
        <span>{{ t(`constructor.steps.two.applications.status_${+status}`) }}</span>
      </div>
      <div class="constructor-application-block__price">
        <span v-if="price > 0">{{ priceOutput }}</span>
        <span v-else>{{ t('constructor.steps.two.applications.free') }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, defineEmits, defineProps, toRefs} from 'vue'
import {useI18n} from 'vue-i18n'
import imageStatusTrue from '@/assets/status-true.svg'
import imageStatusFalse from '@/assets/status-false.svg'
import {numberToCurrency} from '@/helpers'

const props = defineProps({
  iconUrl: {
    type: String,
    required: true,
  },
  status: {
    type: Boolean,
    required: true,
  },
  price: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits(['changeStatus'])

const {status, price} = toRefs(props)
const {t, locale} = useI18n()
const priceOutput = computed(() => numberToCurrency(price.value, locale.value))

const changeStatus = () => {
  emit('changeStatus')
}
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.constructor-application-block {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #212121;
  border-radius: 20px;
  padding: 20px;

  &__header {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 16px;
    margin-bottom: 40px;
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    padding-right: 50px;
  }

  &__desc {
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: $border-color;
  }

  &__info {
    margin-bottom: 76px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
  }

  &__status {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    color: $color-primary;
    font-weight: 500;
  }

  &__price {
    padding: 14px 20px;
    border-top: 1px solid #000000;
    border-left: 1px solid #000000;
    border-radius: 20px 0;
    margin-bottom: -21px;
    margin-right: -21px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
  }

  &_active {
    border: 1px solid $color-primary;
    outline: 1px solid $color-primary;

    .constructor-application-block {
      &__price {
        border-color: $color-primary;
        background-color: $color-primary;
        color: white;
      }

      &__status {
        color: $color-text;
        font-weight: 400;
      }
    }
  }
}
</style>

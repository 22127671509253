<template>
  <div class="constructor-step-four">
    <constructor-step-title>{{ t('constructor.steps.title', {number: 4}) }}</constructor-step-title>
    <div class="container">
      <header class="constructor-step-four__header">
        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <base-title type="h2">{{ t(`${baseT}title`) }}</base-title>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 offset-lg-2">
            <div class="constructor-step-four__desc">
              {{ t(`${baseT}desc`) }}
            </div>
          </div>
        </div>
      </header>
      <div class="constructor-step-four__period">
        <div
            class="constructor-step-four__period-background"
            :class="{'constructor-step-four__period-background_right': activeSLAPeriod === 1}"
        />
        <div
            v-for="period in periods"
            :key="period"
            class="constructor-step-four__period-item"
            :class="{'constructor-step-four__period-item_active': period === activeSLAPeriod}"
            @click="changeSLAPeriod(period)"
        >{{ t(`${baseT}period_${period}`) }}
        </div>
      </div>
      <div class="constructor-step-four__list">
        <div class="row gy-4">
          <div
              class="col-md-6 col-lg-4 col-xxl-3"
              v-for="{name, price, options: {count, notes, variables}} in slaOptions"
              :key="name"
          >
            <price-item
                :name="t(`${baseT}${name}.title`)"
                :amount="price"
                :period="activeSLAPeriod"
                :active="activeSLA === name"
                @action="changeSLA(name)"
            >
              <price-item-option v-for="number in count" :key="`${name}-${number}`">
                <span v-if="variables[number] && variables[number] === 'title'">
                  {{
                    t(`${baseT}${name}.option_${number}`, {
                      variable: t(`${baseT}${name}.option_${number}_${activeSLAPeriod}`),
                    })
                  }}
                </span>
                <span v-else>{{ t(`${baseT}${name}.option_${number}`) }}</span>
                <template v-if="notes.includes(number)" #note>
                  <span v-if="variables[number] && variables[number] === 'note'">
                    {{
                      t(`${baseT}${name}.option_${number}_note`, {
                        variable: t(`${baseT}${name}.option_${number}_note_${activeSLAPeriod}`),
                      })
                    }}
                  </span>
                  <span v-else>{{ t(`${baseT}${name}.option_${number}_note`) }}</span>
                </template>
              </price-item-option>
            </price-item>
          </div>
        </div>
      </div>
      <div class="constructor-step-four__notes">
        <ConstructorPriceNotes/>
      </div>
    </div>
  </div>
</template>

<script setup>
import ConstructorStepTitle from '@/pages/Constructor/components/ConstructorStepTitle'
import {useI18n} from 'vue-i18n'
import PriceItem from '@/components/Price/PriceItem'
import PriceItemOption from '@/components/Price/PriceItemOption'
import {computed, provide} from 'vue'
import BaseTitle from '@/components/Base/BaseTitle'
import ConstructorPriceNotes from '@/pages/Constructor/components/ConstructorPriceNotes'
import {useStore} from 'vuex'
import priceList from '@/helpers/priceList'
import {numberToCurrency} from '@/helpers'

const {t, locale} = useI18n()
const baseT = 'constructor.steps.four.'
provide('baseT', baseT)

const store = useStore()
const periods = [6, 1]

const activeSLA = computed(() => store.state.general.sla)
const activeSLAPeriod = computed(() => store.state.general.slaPeriod)
const slaOptions = computed(() =>([
  {
    name: 'free',
    price: 0,
    options: {count: 4, notes: [4], variables: {}},
  },
  {
    name: 'base',
    price: {
      1: numberToCurrency(priceList[locale.value].sla_base_1, locale.value),
      6: numberToCurrency(priceList[locale.value].sla_base_6, locale.value),
    },
    options: {count: 5, notes: [4, 5], variables: {1: 'title', 5: 'note'}},
  },
  {
    name: 'standard',
    price: {
      1: numberToCurrency(priceList[locale.value].sla_standard_1, locale.value),
      6: numberToCurrency(priceList[locale.value].sla_standard_6, locale.value),
    },
    options: {
      count: 9,
      notes: [6, 7, 8, 9],
      variables: {1: 'title', 8: 'note', 9: 'note'},
    },
  },
  {
    name: 'pro',
    price: {
      1: numberToCurrency(priceList[locale.value].sla_pro_1, locale.value),
      6: numberToCurrency(priceList[locale.value].sla_pro_6, locale.value),
    },
    options: {
      count: 12,
      notes: [5, 8, 9, 10, 11],
      variables: {1: 'title', 10: 'note', 11: 'note'},
    },
  },
]))
const changeSLA = sla => {
  store.dispatch('general/updateSLA', sla)
}

const changeSLAPeriod = period => {
  store.dispatch('general/updateSLAPeriod', period)
}
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.constructor-step-four {
  &__header {
    text-align: center;
    margin-top: 30px;
  }

  &__desc {
    margin-top: $row;
    font-size: 24px;
    line-height: 32px;
  }

  &__period {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 80px 0 36px;
    width: 276px;
    background: #ECEEF3;
    border-radius: 18px;

    &-item {
      position: relative;
      text-align: center;
      padding: 6px 0;
      color: $border-color;
      cursor: pointer;

      &_active {
        color: white;
        cursor: default;
      }
    }

    &-background {
      position: absolute;
      width: 50%;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: $color-danger;
      border-radius: 18px;
      transition: .5s;

      &_right {
        left: 50%;
      }
    }
  }

  &__notes {
    margin-top: 40px;
  }

  &__list {
    margin-top: 80px;
  }
}
</style>

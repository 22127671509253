<template>
  <textarea
      v-model="inputValue"
      :placeholder="placeholder"
      class="base-textarea"
      :class="{'base-textarea__error': error}"
      @change="setInputValue"
  />
</template>

<script>
import {ref, toRefs, watch} from 'vue'

export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const {modelValue} = toRefs(props)
    const inputValue = ref(modelValue.value)
    const setInputValue = () => emit('update:modelValue', inputValue.value || 0)
    watch(modelValue, value => {
      inputValue.value = value
    })
    return {
      inputValue,
      setInputValue,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

.base-textarea {
  width: 100%;
  border: 1px solid $border-color;
  outline: none;
  color: $color-text;
  font-feature-settings: 'pnum' on, 'lnum' on;
  resize: vertical;
  border-radius: 14px;
  min-height: 150px;
  padding: 15px 16px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: $border-color;
  }

  &:focus {
    border-bottom: 1px solid $color-primary;
  }

  &:disabled {
    background-color: transparent;
    border: 1px solid $color-text;
  }

  &__error {
    border: 1px solid $color-danger !important;
  }
}
</style>

const MINUTES_IN_DAY = 60 * 24
const TRANSACTIONS_IN_MB = 6594
const MB_IN_GB = 1000

const DEFAULT_VALUES = {
  blockInterval: 0.5,
  blockSize: 1,
}

export default {
  namespaced: true,
  state() {
    return {
      ...DEFAULT_VALUES
    }
  },
  getters: {
    blocksInDay: (state) => {
      return (1 / state.blockInterval) * MINUTES_IN_DAY
    },
    performance: (state, getters) => {
      if (getters.blocksInDay) {
        return (
            Math.floor(
                (getters.blocksInDay * state.blockSize * 10) / MB_IN_GB) /
            10
        ).toFixed(1)
      }
      return 0
    },
    txInDay: (state, getters) => {
      if (getters.blocksInDay) {
        return (
            Math.floor(
                (getters.blocksInDay * TRANSACTIONS_IN_MB * state.blockSize) /
                100000,
            ) / 10
        ).toFixed(1)
      }
      return 0
    },
  },
  mutations: {
    UPDATE_PROTOCOL(state, {field, value}) {
      state[field] = value
    },
  },
  actions: {
    async reset({commit}) {
      Object.entries(DEFAULT_VALUES).forEach(([field, value]) => {
        commit('UPDATE_PROTOCOL', {field, value})
      })
    },
    UPDATE_PROTOCOL({commit, dispatch}, {field, value}) {
      commit('UPDATE_PROTOCOL', {field, value})
      dispatch('general/resetConfigStatus', null, {root: true})
    },
  },
}

<template>
  <div class="constructor-checkout">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="constructor-checkout__wrapper">
            <header class="constructor-checkout__header">
              <div class="constructor-checkout__icon">
                <img :src="imageFlag" alt="flag">
              </div>
              <base-title type="h2">{{ t(`${baseT}title`) }}</base-title>
            </header>
            <CartDetail class="constructor-checkout__detail"/>
            <CartTotal class="constructor-checkout__total"/>
            <div
                class="constructor-checkout__license"
                v-html="t('constructor.checkout.license', {baseURL})"
            />
            <div>{{ t(`${baseT}go_order`) }}</div>
            <footer class="constructor-checkout__footer">
              <CartActions @save="save"/>
            </footer>
          </div>
        </div>
      </div>
    </div>
    <teleport to="body">
      <base-modal-alert v-if="openSave" @close="closeModalSave">
        <template #default>{{ t('constructor.message_save') }}</template>
        <template #button>{{ t('constructor.message_save_button') }}</template>
      </base-modal-alert>
    </teleport>
  </div>
</template>

<script setup>
import BaseTitle from '@/components/Base/BaseTitle'
import imageFlag from '@/assets/configurator-how.png'
import {useI18n} from 'vue-i18n'
import CartDetail from '@/components/Cart/CartDetail'
import CartTotal from '@/components/Cart/CartTotal'
import CartActions from '@/components/Cart/CartActions'
import {ref, defineEmits} from 'vue'
import BaseModalAlert from '@/components/Base/Modal/BaseModalAlert'
import config from '@/config'

const emit = defineEmits(['save'])

const {t} = useI18n()
const baseT = 'checkout.info.'

const baseURL = config.baseURL

const openSave = ref(false)
const closeModalSave = () => {
  openSave.value = false
  emit('save')
}

const save = () => {
  openSave.value = true
}
</script>

<style lang="scss" scoped>
@import "~@/scss/mixins.scss";
@import "~@/scss/variables.scss";

.constructor-checkout {
  &__wrapper {
    @include background-gradient;

    position: relative;
    border-radius: 14px;
    padding: 52px 60px;

    &::before {
      position: absolute;
      content: '';
      background-color: rgba(255, 255, 255, .975);
      inset: 1px;
      border-radius: 13px;
    }

    & > * {
      position: relative;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    @include background-gradient;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 96px;
    border-radius: 48px;
    margin-bottom: 24px;

    &::before {
      position: absolute;
      inset: 3px;
      content: '';
      background-color: white;
      border-radius: 48px;
      border: 5px solid #ececec;
    }

    & > * {
      position: relative;
    }
  }

  &__total {
    font-size: 24px;
    line-height: 32px;
    border-top: 1px solid #171717;
    border-bottom: 1px solid #171717;
  }

  &__license {
    border: 1px solid #000000;
    border-radius: 12px;
    padding: 12px 14px;
    margin: 30px 0;
  }

  &__message {
    margin-top: 24px;
    border: 1px solid red;
    border-radius: 14px;
    padding: 20px;
    text-align: center;
  }

  &__footer {
    margin-top: 40px;
  }

  &__detail {
    font-size: 24px;
    line-height: 32px;
    margin-top: 56px;
    margin-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .constructor-checkout__detail {
    font-size: 18px;
    line-height: 24px;
  }
}

@media (max-width: 575px) {
  .constructor-checkout {
    &__wrapper {
      padding: 20px 30px;
    }

    &__list, &__total {
      font-size: 18px;
      line-height: 26px;
    }
  }
}
</style>

<template>
  <div class="constructor-step-title">
    <span><slot/></span>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.constructor-step-title {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #D4D8E4;
  color: $border-color;

  span {
    position: relative;
    padding: 0 28px;
    display: inline-block;
    background-color: white;
    margin-bottom: -12px;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }
}
</style>

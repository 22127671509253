<template>
  <home-capability-block class="home-capability-block-blockchain">
    <template #title>{{ t(`${baseT}title`) }}</template>
    <template #info>
      <p>{{ t(`${baseT}desc_1`) }}</p>
      <ul>
        <li>{{ t(`${baseT}li_1`) }}</li>
        <li>{{ t(`${baseT}li_2`) }}</li>
        <li>{{ t(`${baseT}li_3`) }}</li>
        <li>{{ t(`${baseT}li_4`) }}</li>
        <li>{{ t(`${baseT}li_5`) }}</li>
        <li>{{ t(`${baseT}li_6`) }}</li>
      </ul>
    </template>
    <template #image>
      <div class="home-capability-block-blockchain__image">
        <img :src="capabilityBlockchain" alt="capability blockchain">
        <img
            class="home-capability-block-blockchain__image-shadow"
            :src="capabilityShadow"
            alt="capability shadow"
        >
      </div>
    </template>
  </home-capability-block>
</template>

<script setup>
import capabilityBlockchain from '@/assets/capability-blockchain.png'
import capabilityShadow from '@/assets/capability-shadow.png'
import HomeCapabilityBlock from '@/pages/Home/components/HomeCapabilityBlock'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
const baseT = 'home.capability.blockchain.'
</script>

<style lang="scss">
.home-capability-block-blockchain {
  &__image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;

    &-shadow {
      position: absolute;
      width: 132.51%;
      height: auto;
      margin-left: -10%;
      z-index: -1;
    }
  }
}
</style>

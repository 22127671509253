<template>
  <div class="home-advantage-item-tokens">
    <div class="row gy-4">
      <div class="col-lg-5 order-last order-lg-first">
        <div class="home-advantage-item-tokens__info">
          <div class="home-advantage-item-tokens__info-header">
            <div class="home-advantage-item-tokens__info-title">{{ t(`${baseT}info.title`) }}</div>
          </div>
          <div class="home-advantage-item-tokens__item" v-html="t(`${baseT}token_era_desc`)"/>
          <div class="home-advantage-item-tokens__item" v-html="t(`${baseT}token_compu_desc`)"/>
        </div>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <header>
          <base-title type="h3">{{ t(`${baseT}title`) }}</base-title>
        </header>
        <div class="home-advantage-item-tokens__desc">
          <p>{{ t(`${baseT}desc_1`) }}</p>
          <p>{{ t(`${baseT}desc_2`) }}</p>
          <p>{{ t(`${baseT}desc_3`) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
const baseT = 'home.advantage.items.tokens.'
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.home-advantage-item-tokens {
  &__info {
    border: 2px solid $color-danger;
    border-radius: 29px;
    overflow: hidden;
    padding: 0 32px 12px;

    &-header {
      margin: 0 -32px;
      padding: 16px 32px;
      background-color: $color-danger;
      color: white;
    }

    &-title {
      font-weight: 500;
      font-size: 24px;
      line-height: 26px;
    }
  }

  &__item {
    padding: 20px 0;

    &:last-child {
      border-top: 1px solid $color-danger;
    }
  }

  &__desc {
    margin-top: $row * 1.5;
    font-size: 24px;
    line-height: 34px;
  }
}
</style>

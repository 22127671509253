const SETTINGS_DEFAULT = 'default'

const DEFAULT_VALUES = {
  autoDistribution: true,
  settingsType: SETTINGS_DEFAULT,
}

export default {
  namespaced: true,
  state() {
    return {
      ...DEFAULT_VALUES,
    }
  },
  mutations: {
    UPDATE_DISTRIBUTION(state, {field, value}) {
      state[field] = value
    },
  },
  actions: {
    async reset({commit}) {
      Object.entries(DEFAULT_VALUES).forEach(([field, value]) => {
        commit('UPDATE_DISTRIBUTION', {field, value})
      })
    },
    UPDATE_DISTRIBUTION({commit, dispatch}, {field, value}) {
      commit('UPDATE_DISTRIBUTION', {field, value})
      dispatch('general/resetConfigStatus', null, {root: true})
    },
  },
}

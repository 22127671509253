<template>
  <div class="home-tools">
    <div class="container">
      <header class="home-tools__header">
        <base-title type="h2" big>
          {{ t(`${baseT}title`) }}
        </base-title>
      </header>
      <div class="home-tools__info">
        <div class="row gy-4">
          <div class="col-md-5">
            <base-title type="h3">
              {{ t(`${baseT}desc_1`) }}
            </base-title>
          </div>
          <div class="col-md-6 offset-md-1">
            <div class="home-tools__desc">
              {{ t(`${baseT}desc_2`) }}
            </div>
          </div>
        </div>
      </div>
      <div class="home-tools__list">
        <div
            v-for="{name, image} in tools"
            class="home-tools__item"
            :key="name"
        >
          <div class="home-tools__icon">
            <img :src="image" :alt="name">
          </div>
          <span>{{ t(`${baseT}item.${name}`) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
const baseT = 'home.tools.'

const tools = [
  {name: 'digital-id', image: require('@/assets/tools-digital-id.png')},
  {name: 'tokenization', image: require('@/assets/tools-tokenization.png')},
  {name: 'dex', image: require('@/assets/tools-dex.png')},
  {name: 'docs', image: require('@/assets/tools-docs.png')},
  {name: 'staking', image: require('@/assets/tools-staking.png')},
  {name: 'dao', image: require('@/assets/tools-dao.png')},
  {name: 'nft', image: require('@/assets/tools-nft.png')},
  {name: 'ido-ico', image: require('@/assets/tools-ido-ico.png')},
  {name: 'mail', image: require('@/assets/tools-mail.png')},
  {name: 'chat', image: require('@/assets/tools-chat.png')},
  {name: 'digital-print', image: require('@/assets/tools-digital-print.png')},
  {name: 'voting', image: require('@/assets/tools-voting.png')},
  {name: 'status', image: require('@/assets/tools-status.png')},
  {name: 'auctions', image: require('@/assets/tools-auctions.png')},
  {name: 'airdrop', image: require('@/assets/tools-airdrop.png')},
  {name: 'time-tracking', image: require('@/assets/tools-time-tracking.png')},
  {name: 'bonuses', image: require('@/assets/tools-bonuses.png')},
  {name: 'referral-program', image: require('@/assets/tools-referral-program.png')},
]
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.home-tools {
  &__header {
    text-align: center;
    margin-bottom: 124px;

    @media (max-width: 767px) {
      margin-bottom: 60px;
    }
  }

  &__desc {
    font-size: 24px;
    line-height: 34px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 56px;
    margin-top: $row * 4;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-align: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 94px;
    height: 94px;
    background: white;
    box-shadow: 0 4.60703px 30.9329px -5.26518px rgba(45, 74, 136, 0.16);
    border-radius: 33.5655px;
  }
}

@media (max-width: 1199px) {
  .home-tools__list {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (max-width: 991px) {
  .home-tools__list {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 767px) {
  .home-tools__list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 575px) {
  .home-tools__list {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>

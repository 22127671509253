<template>
  <div class="base-input-range" ref="range">
    <div class="base-input-range__current">{{ currentValue }}</div>
    <div class="base-input-range__field">
      <div class="base-input-range__track"/>
      <div
          class="base-input-range__progress"
          :style="{'--progress-size': progressSize + 'px'}"
      />
      <input
          v-model="inputValue"
          type="range"
          class="base-input-range__input"
          :min="min"
          :max="max"
          :step="step"
          @input="setInputValue"
      >
    </div>
    <div class="base-input-range__minmax">
      <div class="base-input-range__min">{{ minView }}</div>
      <div class="base-input-range__min">{{ maxView }}</div>
    </div>
  </div>
</template>

<script>
import {computed, ref, toRefs, watch} from 'vue'

export default {
  name: 'BaseInputRange',
  props: {
    rangeValue: {
      type: [String, Number],
      default: '',
    },
    min: {
      type: [String, Number],
      default: 0,
    },
    max: {
      type: [String, Number],
      default: 10,
    },
    step: {
      type: [String, Number],
      default: 5,
    },
    minView: {
      type: String,
      default: '',
    },
    maxView: {
      type: String,
      default: '',
    },
    currentValue: {
      type: [String],
      default: '',
    },
  },
  emits: ['update:rangeValue'],
  setup(props, {emit}) {
    const {rangeValue, max, step} = toRefs(props)
    const inputValue = ref(rangeValue.value)
    const setInputValue = ({target: {value}}) => emit('update:rangeValue', value)
    const range = ref()
    const progressSize = computed(() => {
      if (range.value instanceof HTMLElement) {
        const {width} = range.value.getBoundingClientRect()
        const count = max.value / step.value - 1
        const current = inputValue.value / step.value - 1
        return Math.ceil(width / count * current)
      }
      return 0
    })

    watch(rangeValue, () => {
      inputValue.value = rangeValue.value
    })
    return {
      range,
      inputValue,
      progressSize,
      setInputValue,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

.base-input-range {
  $track-color: #B7C0DF;

  @mixin track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: transparent;
    border-radius: 4px;
    border: none;
  }

  @mixin thumb {
    border: 5px solid white;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    background: $color-primary;
    cursor: pointer;
    box-shadow: 0 0 0 1px $color-primary;
    box-sizing: border-box;
  }

  &__current {
    line-height: 20px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-weight: 500;
  }

  &__input {
    position: relative;
    -webkit-appearance: none;
    width: 100%;
    height: 30px;
    background: transparent;

    &:focus {
      outline: none;
    }

    &::-webkit-slider-thumb {
      @include thumb;
      -webkit-appearance: none;
      margin-top: -7px;
    }

    &::-webkit-slider-runnable-track {
      @include track;
    }

    &::-moz-range-track {
      @include track;
    }

    &::-moz-range-thumb {
      @include thumb;
    }
  }

  &__field {
    position: relative;
    height: 30px;
    margin: 12px 0;
  }

  &__progress, &__track {
    @include track;

    position: absolute;
    bottom: 12px;
  }

  &__track {
    background: $track-color;
  }

  &__progress {
    background: $color-primary;
    width: var(--progress-size);
  }

  &__minmax {
    display: flex;
    justify-content: space-between;
    cursor: default;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: $border-color;
  }
}
</style>

<template>
  <div class="home-no-code">
    <div class="container">
      <div class="home-no-code__wrapper">
        <div class="row">
          <div class="home-no-code__desc col-lg-8 col-xl-5">
            {{ t('home.no_code.desc') }}
          </div>
        </div>
        <div class="position-relative">
          <div class="home-no-code__text page-title__big home-no-code__text_hide">
            {{ fullText }}
          </div>
          <div class="home-no-code__text page-title__big">
            {{ text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useI18n} from 'vue-i18n'
import {computed, ref} from 'vue'

const {t} = useI18n()
const text = ref('')
const timer = ref()
const fullText = computed(() => t('home.no_code.actions'))

const startInputText = () => {
  text.value = ''
  clearInterval(timer.value)

  timer.value = setInterval(() => {
    if (text.value.length < fullText.value.length) {
      text.value += fullText.value[text.value.length]
    } else {
      clearInterval(timer.value)
    }
  }, 60)
}

startInputText()
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";
@import "~@/scss/functions.scss";

@keyframes caret {
  50% {
    opacity: 0;
  }
}

.home-no-code {
  position: relative;
  z-index: 0;

  &__wrapper {
    position: relative;
    z-index: 0;

    &:after, &:before {
      position: absolute;
      content: '';
      z-index: -1;
      background-size: cover;
      filter: blur(90px);
    }

    &:before {
      width: getWidthPercentShadow(512);
      padding-top: getHeightPercentShadowPrimary(512);
      top: -92%;
      left: -36%;
      background-image: url("~@/assets/shadow-primary.svg");
      opacity: 0.6;
    }

    &:after {
      width: getWidthPercentShadow(580);
      padding-top: getHeightPercentShadowDanger(580);
      top: -55%;
      left: -27%;
      background-image: url("~@/assets/shadow-danger.svg");
    }
  }

  &__desc {
    font-size: 42px;
    line-height: 1.16;
    color: #060606;
    margin-bottom: 12px;
  }

  &__text {
    color: $color-danger;

    &_hide {
      opacity: 0;
    }

    &:nth-child(2) {
      position: absolute;
      top: 0;
    }

    &:after {
      content: '|';
      font-weight: normal;
      opacity: 1;
      animation: caret 1s steps(1) infinite;
    }
  }
}
</style>

<template>
  <div class="cart-detail">

    <div class="cart-detail__list">
      <div
          v-for="{name, price, removeDispatch} in items"
          class="cart-detail__item"
          :key="name"
      >
        <div>{{ t(`${baseT}${name}`) }}</div>
        <div>{{ outputPrice(price) }}</div>
        <div>
          <IconRemove
              v-if="removeDispatch"
              class="cart-detail__remove"
              @click="removeItem(removeDispatch)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useStore} from 'vuex'
import {computed} from 'vue'
import {numberToCurrency} from '@/helpers'
import {useI18n} from 'vue-i18n'
import IconRemove from '@/components/Icon/IconRemove'

const {t, locale} = useI18n()
const baseT = 'checkout.info.'

const store = useStore()
const items = computed(() => store.state.cart.items)

const removeItem = (remove) => {
  store.dispatch(...remove)
}

const outputPrice = number => {
  if (number === null) {
    return t(`${baseT}under_contract`)
  }

  return numberToCurrency(number, locale.value)
}
</script>

<style lang="scss">
@import "~@/scss/variables.scss";

.cart-detail {
  &__list {
    display: grid;
    gap: 10px;
  }

  &__item {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 160px 24px;
    gap: 17px;
    text-align: right;
    font-feature-settings: 'pnum' on, 'lnum' on;

    & > *:first-child {
      text-align: left;
    }
  }

  &__remove {
    color: $border-color;
    cursor: pointer;

    &:hover {
      color: $color-danger;
    }
  }
}

@media (max-width: 767px) {
  .cart-detail__item {
    grid-template-columns: 1fr 100px 24px;
  }
}
</style>

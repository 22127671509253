<template>
  <div
      v-if="nameRoute !== 'checkout'"
      :class="[
          'cart',
          {'cart_active': configStatus},
          {'cart_active-menu': isActiveMenu}
      ]"
  >
    <div class="cart__wrapper" @click="toggleCartStatus">
      <img v-if="configStatus" :src="imageCartActive" alt="cart">
      <img v-else :src="imageCart" alt="cart">
      <div class="cart__price">{{ price }}</div>
    </div>
    <TheCartInfo v-if="showCart" class="cart__info"/>
  </div>
</template>

<script setup>
import imageCart from '@/assets/cart.svg'
import imageCartActive from '@/assets/cart-active.svg'
import {useStore} from 'vuex'
import {defineProps, computed, ref, toRefs, watch} from 'vue'
import TheCartInfo from '@/components/TheCartInfo'
import {useRoute} from 'vue-router'
import {useI18n} from 'vue-i18n'
import {numberToCurrency} from '@/helpers'

defineProps({
  isActiveMenu: {
    type: Boolean,
    default: false,
  },
})

const store = useStore()
const route = useRoute()
const {name: nameRoute} = toRefs(route)
const {locale} = useI18n()
const configStatus = computed(() => store.state.general.status)

const price = computed(() => {
  return numberToCurrency(configStatus.value ? store.getters['cart/totalAndVat'] : 0, locale.value)
})

const cartStatus = ref(false)

const toggleCartStatus = () => {
  if (price.value && configStatus.value) {
    cartStatus.value = !cartStatus.value
  }
}

const showCart = computed(() => {
  return cartStatus.value && price.value
})

watch(nameRoute, () => {
  cartStatus.value = false
})
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.cart {
  position: relative;
  border-radius: 53px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  border: 1px solid $border-color;
  color: $border-color;
  background: transparent;

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 9px 15px;
  }

  &_active {
    background: linear-gradient(76.09deg, #F44D61 13.91%, #FE7443 107.34%);
    box-shadow: 0 4px 6px rgba(244, 77, 97, 0.37);
    color: white;
    border: 1px solid #F44D61;

    .cart__wrapper {
      cursor: pointer;
    }
  }

  &__info {
    position: absolute;
    top: 60px;
    right: 0;
    width: 360px;
    color: $color-text;
  }
}

@media (max-width: 767px) {
  .cart:not(.cart_active-menu) {
    width: 50px;
    height: 50px;

    .cart__wrapper {
      justify-content: center;
      height: 100%;
    }

    .cart__price {
      display: none;
    }

    .cart__info {
      position: fixed;
      top: auto;
      bottom: 90px;
      left: 10px;
      right: 10px;
    }
  }
  .cart_active-menu {
    .cart__info {
      position: fixed;
      top: auto;
      bottom: 110px;
      left: 10px;
      right: 10px;
    }
  }
}
</style>

<template>
  <div class="home-capability-platforms">
    <div class="home-capability-platforms__title">
      {{ t(`${baseT}platform`) }}
    </div>
    <div class="home-capability-platforms__list">
      <div
          class="home-capability-platforms__item"
          v-for="{name, image} in platforms"
          :key="name"
      >
        <img :src="image" :alt="name">
        <span>{{ t(`${baseT}platform_${name}`) }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import imagePlatformMac from '@/assets/platform_mac.png'
import imagePlatformAndroid from '@/assets/platform_android.png'
import imagePlatformWindows from '@/assets/platform_windows.png'
import imagePlatformLinux from '@/assets/platform_linux.png'
import {useI18n} from 'vue-i18n'
import {inject} from 'vue'

const {t} = useI18n()
const baseT = inject('baseT')

const platforms = [
  {
    name: 'mac',
    image: imagePlatformMac,
  },
  {
    name: 'android',
    image: imagePlatformAndroid,
  },
  {
    name: 'windows',
    image: imagePlatformWindows,
  },
  {
    name: 'linux',
    image: imagePlatformLinux,
  },
]
</script>

<style lang="scss" scoped>
.home-capability-platforms {
  &__title {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
  }

  &__list {
    display: flex;
    align-items: stretch;
    gap: 20px;
    margin-top: 22px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background: white;
    border-radius: 20px;
    padding: 10px 0;
    width: 73px;

    span {
      margin-top: auto;
      font-weight: 400;
      font-size: 9px;
      line-height: 11px;
    }
  }
}
</style>

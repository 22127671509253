<template>
  <div class="footer-widget">
    <div class="footer-widget__label">
      <slot name="label"/>
    </div>
    <div
        class="footer-widget__button"
        :class="`footer-widget__button_${type}`"
        @click="goRoute"
    >
      <span>
        <slot name="button"/>
      </span>
      <IconButtonGo/>
    </div>
  </div>
</template>

<script setup>
import {defineProps} from 'vue'
import {useRouter} from 'vue-router'
import IconButtonGo from '@/components/Icon/IconButtonGo'

const props = defineProps({
  router: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'light',
    validator(value) {
      return ['light', 'white'].includes(value)
    },
  },
})

const router = useRouter()
const goRoute = () => {
  router.push({name: props.router})
}
</script>

<style lang="scss" scoped>
.footer-widget {
  display: flex;
  align-items: center;
  color: white;
  gap: 30px;

  &__button {
    display: flex;
    align-items: center;
    gap: 24px;
    border: 1px solid white;
    border-radius: 63px;
    padding: 11px 11px 11px 22px;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    transition: .5s;

    &_white {
      background-color: white;
      color: black;
    }

    &:hover {
      box-shadow: 3px 3px 10px black;
    }
  }
}
</style>

<template>
  <base-modal class="base-modal-alert" @close="close">
    <img :src="svgAlertOk" alt="Ok">
    <div class="base-modal-alert__content">
      <slot/>
    </div>
    <div class="base-modal-alert__buttons">
      <base-button color="danger" @action="close">
        <template v-if="$slots.button">
          <slot name="button"/>
        </template>
        <template v-else>
          {{ t('modal.proceed') }}
        </template>
      </base-button>
    </div>
  </base-modal>
</template>

<script setup>
import BaseModal from '@/components/Base/Modal/BaseModal'
import BaseButton from '@/components/Base/BaseButton'
import {defineEmits} from 'vue'
import svgAlertOk from '@/assets/alert-ok.svg'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
const emit = defineEmits(['close'])

const close = () => emit('close')
</script>

<style lang="scss">
@import "~@/scss/variables.scss";

.base-modal-alert {
  &__content {
    text-align: center;
    margin-top: 19px;

    p {
      margin: 0 0 7px;
    }

    &:last-child {
      margin: 0;
    }
  }

  &__buttons {
    margin-top: 33px;
  }
}
</style>

<template>
  <div class="technology-security">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 offset-lg-1">
          <div class="technology-security__wrapper">
            <div class="technology-security__title page-title__big">{{ t('home.security.title') }}</div>
            <base-button class="technology-security__button" @action="goConstructor">
              {{ isMobile ? t('home.security.button_mini') : t('home.security.button') }}
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useI18n} from 'vue-i18n'
import BaseButton from '@/components/Base/BaseButton'
import useActionRouter from '@/use/actionRouter'
import {inject} from 'vue'

const isMobile = inject('isMobile')

const {goConstructor} = useActionRouter()
const {t} = useI18n()
</script>

<style lang="scss" scoped>
.technology-security {
  &__button {
    margin-top: 64px;
  }

  &__title {
    @media all and (max-width: 767px) {
      font-size: 28px;
    }
  }

  &__button {

  }

  &__wrapper {
    position: relative;
    overflow: hidden;
    text-align: center;
    color: #060606;
    background: #F5F5FB;
    border-radius: 30px;
    padding: 66px 80px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    z-index: 0;

    &::after {
      position: absolute;
      top: 5%;
      right: 5%;
      width: 339.67px;
      height: 280.98px;
      content: '';
      background: linear-gradient(45.01deg, #18AFF2 14.78%, #5F52D8 33.84%, #AA42B9 52.19%, #F44D61 67.73%, #FE7443 85.38%);
      opacity: 0.8;
      filter: blur(91px);
      border-radius: 59px;
      transform: matrix(0.99, 0.37, 0.11, 0.94, 0, 0);
      z-index: -1;
    }

    &::before {
      position: absolute;
      bottom: -20%;
      left: -15%;
      width: 261.22px;
      height: 306.65px;
      content: '';
      background: linear-gradient(45.01deg, #18AFF2 14.78%, #5F52D8 33.84%, #AA42B9 52.19%, #F44D61 67.73%, #FE7443 85.38%);
      filter: blur(91px);
      border-radius: 59px;
      transform: matrix(0.98, -0.7, 0.13, 0.86, 0, 0);
      z-index: -1;
    }

    span {
      position: relative;
      z-index: 1;
    }
  }
}

@media (max-width: 991px) {
  .technology-security__wrapper {
    padding: 50px 30px;
  }
}
</style>

<template>
  <svg :width="width" :height="height" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M11.1562 22C17.2314 22 22.1562 17.0751 22.1562 11C22.1562 4.92487 17.2314 0 11.1562 0C5.08112 0 0.15625 4.92487 0.15625 11C0.15625 17.0751 5.08112 22 11.1562 22ZM17.3999 10.4163L12.1469 5.16332C11.8246 4.84097 11.302 4.84097 10.9796 5.16332C10.6573 5.48566 10.6573 6.00829 10.9796 6.33064L14.8235 10.1745H5.49571V11.8254H14.8235L10.9796 15.6692C10.6573 15.9916 10.6573 16.5142 10.9796 16.8366C11.302 17.1589 11.8246 17.1589 12.1469 16.8366L17.3999 11.5836C17.7222 11.2613 17.7222 10.7386 17.3999 10.4163Z"
          :fill="color"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 23,
    },
    height: {
      type: Number,
      default: 22,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },
}
</script>

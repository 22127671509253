<template>
  <div class="home-advantage">
    <div class="container">
      <header class="home-advantage__header">
        <base-title type="h2" big>{{ t('home.advantage.title') }}</base-title>
      </header>
      <HomeAdvantageItems/>
    </div>
  </div>
</template>

<script setup>
import HomeAdvantageItems from '@/pages/Home/components/HomeAdvantageItems'
import {useI18n} from 'vue-i18n'
import BaseTitle from '@/components/Base/BaseTitle'

const {t} = useI18n()
</script>

<style lang="scss" scoped>
.home-advantage {
  &__header {
    text-align: center;
    margin-bottom: 92px;

    @media (max-width: 767px) {
      margin-bottom: 50px;
    }
  }
}
</style>

<template>
  <div class="tabs">
    <div class="tabs__wrapper">
      <div class="tabs__background" :style="{'width': width, 'left': left}"/>
      <div
          v-for="({name, label}, i) in tabs" :key="name"
          class="tabs__item"
          :class="{'tabs__item_active': name === activeTab}"
          :ref="el => { tabsElement[i] = el }"
          @click="setActive(name)"
      >{{ label }}
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, ref, toRefs, watch, onMounted} from 'vue'

const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  },
  activeTab: {
    type: String,
    required: true,
  },
})

const {tabs, activeTab} = toRefs(props)
const tabsElement = ref([])
const emit = defineEmits(['update:activeTab'])
const width = ref('auto')
const left = ref('auto')

const setActive = name => {
  emit('update:activeTab', name)
}

const setWidth = () => {
  if (tabs.value.length) {
    const idx = tabs.value.findIndex(t => t.name === activeTab.value)
    if (tabsElement.value[idx]) {
      const {width: widthElement} = tabsElement.value[idx].getBoundingClientRect()
      return width.value = widthElement + 'px'
    }
  }

  return width.value = 'auto'
}

const setLeft = () => {
  if (tabs.value.length) {
    const idx = tabs.value.findIndex(t => t.name === activeTab.value)
    if (tabsElement.value[idx]) {
      return left.value = tabsElement.value[idx].offsetLeft + 'px'
    }
  }

  return left.value = 'auto'
}

watch(activeTab, () => {
  setTimeout(() => {
    setWidth()
    setLeft()
  }, 0)
})

onMounted(() => {
  setWidth()
  setLeft()
})

</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.tabs {
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #ECEEF3;
    border-radius: 18px;
  }

  &__item {
    position: relative;
    text-align: center;
    padding: 6px 35px;
    color: $border-color;
    cursor: pointer;

    &_active {
      color: white;
      cursor: default;
    }
  }

  &__background {
    position: absolute;
    width: 50%;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: $color-danger;
    border-radius: 18px;
    transition: .5s;

    &_right {
      left: 50%;
    }
  }
}
</style>

<template>
  <BaseInputRange
      v-model:rangeValue="blockSize"
      min="1"
      max="8"
      step="1"
      min-view="1 Mb"
      max-view="8 Mb"
      :current-value="blockSizeView"
  />
</template>

<script>
import BaseInputRange from '../../Base/BaseInputRange.vue'
import {computed, ref, toRefs, watch} from 'vue'

export default {
  name: 'RangeBlockSize',
  components: {BaseInputRange},
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const {modelValue} = toRefs(props)
    const blockSize = ref(modelValue.value)
    const blockSizeView = computed(() => blockSize.value + ' Mb')

    watch(modelValue, () => blockSize.value = modelValue.value)
    watch(blockSize, () => {
      if (blockSize.value !== modelValue.value) {
        emit('update:modelValue', blockSize.value)
      }
    })

    return {
      blockSize,
      blockSizeView,
    }
  },
}
</script>

<template>
  <div
      :class="['interface-sidebar', {'interface-sidebar_hide': hide}]"
      :style="{
      '--color-sidebar': '#' + colorSidebar,
      '--color-link': '#' + colorLink,
    }"
  >
    <div class="interface-sidebar__logo">
      <img v-if="hide" :src="logoUrl" alt="Logo">
      <img v-else :src="logoHorizontalUrl" alt="Logo">
    </div>
    <div class="interface-sidebar__nav">
      <div class="interface-sidebar__item interface-sidebar__item_active">
        <div class="interface-sidebar__item-icon">
          <IconDashboard :color="`#${colorLink}`"/>
        </div>
        <div class="interface-sidebar__item-name">
          {{ t(`${baseT}sidebar_dashboard`) }}
        </div>
      </div>
      <div class="interface-sidebar__item">
        <div class="interface-sidebar__item-icon">
          <IconWallet color="white"/>
        </div>
        <div class="interface-sidebar__item-name">
          {{ t(`${baseT}sidebar_wallet`) }}
        </div>
      </div>
      <div class="interface-sidebar__item">
        <div class="interface-sidebar__item-icon">
          <IconServices color="white"/>
        </div>
        <div class="interface-sidebar__item-name">
          {{ t(`${baseT}sidebar_services`) }}
        </div>
      </div>
      <div class="interface-sidebar__item">
        <div class="interface-sidebar__item-icon">
          <IconExplorer color="white"/>
        </div>
        <div class="interface-sidebar__item-name">
          {{ t(`${baseT}sidebar_explorer`) }}
        </div>
      </div>
    </div>
    <div class="interface-sidebar__footer">
      <div class="interface-sidebar__hide" @click="toggleHide">
        <img :src="hideIcon" alt="Hide"/>
      </div>
      <div class="interface-sidebar__copyright">Powered by Erachain</div>
    </div>
  </div>
</template>

<script setup>
import {computed, inject, ref} from 'vue'
import {useI18n} from 'vue-i18n'
import {useStore} from 'vuex'
import hideIcon from '@/assets/hide.svg'
import defaultLogo from '@/assets/default-logo.svg'
import defaultLogoHorizontal from '@/assets/default-logo-horizontal.svg'
import IconExplorer from '@/components/Icon/IconExplorer.vue'
import IconServices from '@/components/Icon/IconServices.vue'
import IconWallet from '@/components/Icon/IconWallet.vue'
import IconDashboard from '@/components/Icon/IconDashboard.vue'

const {t} = useI18n()
const baseT = inject('baseT')

const hide = ref(false)

const store = useStore()
const colorSidebar = inject('colorSidebar')
const colorLink = inject('colorLink')
const logoHorizontalUrl = computed(() => {
  const logoHorizontal = store.state.style.logoHorizontal
  return logoHorizontal ? URL.createObjectURL(logoHorizontal) : defaultLogoHorizontal
})
const logoUrl = computed(() => {
  const logo = store.state.style.logo
  return logo ? URL.createObjectURL(logo) : defaultLogo
})
const toggleHide = () => {
  hide.value = !hide.value
}
</script>

<style lang="scss" scoped>
.interface-sidebar {
  display: flex;
  flex-direction: column;
  padding: 11px;
  background-color: var(--color-sidebar);
  border-radius: 7px 0 0 7px;
  width: 220px;

  &_hide {
    width: 56px;

    .interface-sidebar__item-name, .interface-sidebar__copyright {
      display: none;
    }

    .interface-sidebar__hide {
      justify-content: center;

      img {
        transform: scale(-1, 1);
      }
    }

    .interface-sidebar__logo {
      padding: 0;
      text-align: center;
    }

    .interface-sidebar__item {
      grid-template-columns: 1fr;
      padding: 7px 0;

      &-icon {
        display: flex;
        justify-content: center;
      }

      &_active {
        &:after {
          content: none;
        }
      }
    }
  }

  &__logo {
    max-width: 162px;
    padding: 10px 0 0 8px;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__nav {
    margin-top: 30px;
  }

  &__item {
    position: relative;
    display: grid;
    grid-template-columns: 22px 1fr;
    align-items: center;
    gap: 10px;
    padding: 7px 10px;
    color: white;
    border-radius: 6px;

    &_active {
      background-color: rgba(255, 255, 255, .15);

      &:after {
        position: absolute;
        left: -3px;
        content: '';
        width: 3px;
        height: 60%;
        top: 20%;
        background-color: var(--color-link);
        border-radius: 10px 0 0 10px;
      }
    }

    &-icon svg {
      display: block;
    }

    &-name {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__hide {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    transform: scale(1, 1);
  }

  &__footer {
    margin-top: auto;
  }

  &__copyright {
    padding: 8px 0 0 8px;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.02em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #838297;
  }
}
</style>

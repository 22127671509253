<template>
  <div class="constructor-node-options">
    <div class="constructor-node-options__label">{{ t(`${baseT}title`) }}</div>
    <div class="constructor-node-options__list">
      <div
          v-for="{name, image} in options" :key="name"
          class="constructor-node-options__item"
      >
        <div class="constructor-node-options__value">{{ t(`${baseT}${name}_value`) }}</div>
        <div class="constructor-node-options__name">
          <img :src="image" :alt="name">
          <span>{{ t(`${baseT}${name}`) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useI18n} from 'vue-i18n'
import {inject} from 'vue'

const {t} = useI18n()
const baseT = inject('baseT') + 'node.'
const options = [
  {name: 'cpu', image: require('@/assets/machine-cpu.svg')},
  {name: 'ram', image: require('@/assets/machine-ram.svg')},
  {name: 'hdd', image: require('@/assets/machine-hdd.svg')},
]
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.constructor-node-options {
  border: 1px solid #000000;
  border-radius: 29px;
  padding: 26px 36px;

  &__list {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  &__value {
    margin-bottom: 13px;
    font-weight: 600;
    font-size: 42px;
    line-height: 49px;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  &__name {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $border-color;
  }
}

@media (max-width: 1199px) {
  .constructor-node-options__value {
    font-size: 32px;
    line-height: 40px;
  }
}

@media (max-width: 575px) {
  .constructor-node-options__list {
    flex-direction: column;
    gap: 40px;
    align-items: center;
  }
}
</style>

import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import {numberToCurrency} from '@/helpers'
import JSZip from 'jszip'

export default function() {
  const store = useStore()
  const {t, locale} = useI18n()

  const addSetGetField = ({base, field, action}) => ({
    get: () => store.state[base][field],
    set: value => store.dispatch(`${base}/${action}`, {field, value}),
  })

  const generateDataForEmail = () => {
    let output = ''
    output += generateDataCheckout()
    output += generateDataOrder()
    output += generateDataOrderTotal()
    output += generateDataConstructor()

    if (store.state.general.web) {
      output += generateDataWeb()
    }

    return output
  }

  const generateDataCheckout = () => {
    let html = '<p><strong>Данные пользователя:</strong></p>'
    html += '<ul>'
    Object.keys(store.state.checkout).forEach(key => {
      html += `<li>${t(
          `checkout.form.${key}`)}: ${store.state.checkout[key]}</li>`
    })
    html += '</ul>'

    return html
  }

  const generateDataOrder = () => {
    let html = '<p><strong>Данные заказа:</strong></p>'
    html += '<ul>'
    store.state.cart.items.forEach(({name, price}) => {
      html += `
        <li>${t(`checkout.info.${name}`)}: ${numberToCurrency(price, locale.value)}</li>`
    })
    html += '</ul>'

    return html
  }

  const generateDataOrderTotal = () => {
    let html = '<p><strong>Всего к оплате:</strong></p>'
    html += '<ul>'
    html += `<li>${t('checkout.info.total')}: ${numberToCurrency(store.getters['cart/total'], locale.value)}</li>`
    html += `<li>${t('checkout.info.vat')}: ${numberToCurrency(store.getters['cart/vat'], locale.value)}</li>`
    html += `<li>${t('checkout.info.total_and_vat')}: ${numberToCurrency(store.getters['cart/totalAndVat'],
        locale.value)}</li>`
    html += '</ul>'

    return html
  }

  const generateDataConstructor = () => {
    const {name, addresses} = store.state.general
    const {blockSize, blockInterval} = store.state.protocol
    const {
      investTokenName,
      investTokenCount,
      fuelTokenName,
      fuelTokenCount,
    } = store.state.emission
    const {minCount, fuelTokenByYear, APYByYear} = store.state.stake

    const baseT = 'constructor.steps.one.'

    let html = '<p><strong>Конфигурация блокчейна</strong>:</p>'
    html += '<ul>'
    html += `<li>${t(`${baseT}name.title`)}: ${name}</li>`
    html += `<li>${t(`${baseT}protocol.interval`)}: ${blockInterval}</li>`
    html += `<li>${t(`${baseT}protocol.size`)}: ${blockSize}</li>`
    html += `<li>${t(
        `${baseT}emission.invest_name`)}: ${investTokenName} (${investTokenCount})</li>`
    html += `<li>${t(
        `${baseT}emission.fuel_name`)}: ${fuelTokenName} (${fuelTokenCount})</li>`
    html += `<li>${t(`${baseT}stake.min_stake`)}: ${minCount}</li>`
    html += `<li>${t(`${baseT}stake.apy_percent_by_year`)}: ${APYByYear}</li>`
    html += `<li>${t(`${baseT}stake.gas_by_year`)}: ${fuelTokenByYear}</li>`
    html += '</ul>'

    html += '<p><strong>Список адресов</strong>:</p>'
    html += generateDataDistribution(addresses)

    return html
  }

  const generateDataDistribution = addresses => {
    const baseT = 'constructor.steps.one.'

    let html = '<table>'
    html += `<tr>
        <th>${t(`${baseT}distribution.th_address`)}</th>
        <th>${t(`${baseT}distribution.th_invest`)}</th>
        <th>${t(`${baseT}distribution.th_fuel`)}</th>
    </tr>`
    addresses.forEach(({address, investCount, fuelCount}) => {
      html += `<tr>
        <td>${address}</td>
        <td>${investCount}</td>
        <td>${fuelCount}</td>
    </tr>`
    })
    html += '</table>'

    return html
  }

  const generateDataWeb = () => {
    const {colorSidebar, colorButton, colorLink} = store.state.style

    const baseT = 'constructor.steps.two.web.'

    let html = '<p><strong>Настройка веб-приложения</strong>:</p>'
    html += '<ul>'
    html += `<li>${t(`${baseT}sidebar`)}: #${colorSidebar}</li>`
    html += `<li>${t(`${baseT}button`)}: #${colorButton}</li>`
    html += `<li>${t(`${baseT}link`)}: #${colorLink}</li>`
    html += '</ul>'

    return html
  }

  const generateDataArchive = () => {
    const {logo, logoHorizontal} = store.state.style
    const {investTokenImage, fuelTokenImage} = store.state.emission
    const zip = new JSZip()

    if (logo) {
      zip.file(getCorrectName('logo', logo.name), logo)
    }

    if (logoHorizontal) {
      zip.file(getCorrectName('logoHorizontal', logoHorizontal.name), logoHorizontal)
    }

    zip.file(getCorrectName('1', investTokenImage.name), investTokenImage)
    zip.file(getCorrectName('2', fuelTokenImage.name), fuelTokenImage)

    return zip.generateAsync({type: 'blob'})
  }

  const getCorrectName = (name, nameOld) => {
    const splitName = nameOld.split('.')
    return `${name}.${splitName[splitName.length - 1]}`
  }

  return {
    addSetGetField,
    generateDataForEmail,
    generateDataArchive,
  }
}

<template>
  <div class="price-item" :class="{'price-item_active': active}" @click="click">
    <div class="price-item__header">
      <h3 class="price-item__title">{{ name }}</h3>
      <template v-if="locale === 'ru'">
        <div v-if="amountSplit.length > 1" class="price-item__amount">
          {{ amountSplit[0] }} <sup>{{ amountSplit[1] }}</sup>
        </div>
      </template>
      <div v-else-if="amount" class="price-item__amount">
        {{ amount[period] }}
      </div>
    </div>
    <div class="price-item__options">
      <slot/>
    </div>
  </div>
</template>

<script setup>
import {computed, defineEmits, defineProps, toRefs} from 'vue'
import {useI18n} from 'vue-i18n'

const emit = defineEmits(['action'])

const props = defineProps({
  name: {
    type: String,
    default: '',
  },
  amount: {
    type: [Number, Object],
    default: 0,
  },
  active: {
    type: Boolean,
    default: false,
  },
  period: {
    type: [Number, String],
    required: true,
  },
})

const {locale} = useI18n()

const {amount, period} = toRefs(props)
const amountSplit = computed(() => {
  return amount.value ? amount.value[period.value].split(' ') : []
})

const click = () => {
  emit('action')
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.price-item {
  position: relative;
  padding: 24px 20px;
  border: 1px solid black;
  border-radius: 20px;
  height: 100%;
  cursor: pointer;
  transition: $transition-default;

  &:hover {
    border: 1px solid $color-primary;

    .price-item__amount {
      border-color: $color-primary;
    }
  }

  &_active {
    border: 1px solid $color-primary;
    box-shadow: inset 0 0 0 1px $color-primary;

    .price-item__amount {
      border-color: $color-primary;
      background-color: $color-primary;
      color: white;
    }
  }

  &__header {
    margin-bottom: 40px;
  }

  &__title {
    margin: 0;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
  }

  &__amount {
    position: absolute;
    top: -1px;
    right: 0;
    padding: 24px 20px 12px;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    border-radius: 0 20px;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    transition: $transition-default;
  }

  &__options {
    display: grid;
    gap: 14px;
  }
}
</style>

<template>
  <div class="the-cookies" v-if="!iAgree">
    <div class="the-cookies__info">
      <p>{{ t('cookies.desc') }}</p>
      <a href="https://erachain.ru/assets/docs/cookies_policy.pdf" target="_blank">
        {{ t('cookies.link_title') }}
      </a>
    </div>
    <div class="the-cookies__button">
      <base-button color="white" size="small" @action="success">
        {{ t('cookies.button') }}
      </base-button>
    </div>
  </div>
</template>

<script setup>
import BaseButton from '@/components/Base/BaseButton'
import {useI18n} from 'vue-i18n'
import {ref} from 'vue'

const {t} = useI18n()
const iAgree = ref(Boolean(localStorage.getItem('iAgree')))

const success = () => {
  iAgree.value = true
  localStorage.setItem('iAgree', 'true')
}
</script>

<style lang="scss" scoped>
.the-cookies {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  background-color: #02071C;
  box-shadow: 0 4px 18px -4px rgba(0, 0, 0, 0.56);
  border-radius: 12px;
  font-size: 16px;
  line-height: 22px;
  color: white;
  padding: 17px 30px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }

  p {
    margin-bottom: 5px;
  }

  a {
    text-decoration: none;
    color: #42B0F5;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>

export default {
  en: {
    blockchain: 7990,
    desktop: 0,
    web: 1450,
    mobile: 1950,
    node4: 100,
    node8: 200,
    node16: 400,
    rent: null,
    sla_free: 0,
    sla_base_1: 180,
    sla_standard_1: 280,
    sla_pro_1: 450,
    sla_base_6: 695,
    sla_standard_6: 1350,
    sla_pro_6: 2450,
  },
  ru: {
    blockchain: 500_000,
    desktop: 0,
    web: 150_000,
    mobile: 200_000,
    node4: 3_000,
    node8: 6_000,
    node16: 12_000,
    rent: null,
    sla_free: 0,
    sla_base_1: 9_990,
    sla_standard_1: 16_700,
    sla_pro_1: 27_700,
    sla_base_6: 40_500,
    sla_standard_6: 84_500,
    sla_pro_6: 149_500,
  }
}

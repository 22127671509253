<template>
  <div class="constructor-logo">
    <base-form-block>
      <template #title>{{ t(`${baseT}title`) }}</template>
      <template #content>
        <div class="constructor-logo__grid">
          <base-form-field-info>
            {{ t(`${baseT}horizontal`) }}
          </base-form-field-info>
          <BaseUploadImage
              v-model:uploadImage="horizontalLogo"
              width="154px"
              height="40px"
          />
          <base-form-field-info>
            {{ t(`${baseT}square`) }}
          </base-form-field-info>
          <BaseUploadImage v-model:uploadImage="logo"/>
        </div>
      </template>
    </base-form-block>
  </div>
</template>

<script setup>
import BaseFormFieldInfo from '@/components/Base/BaseFormFieldInfo'
import BaseUploadImage from '@/components/Base/BaseUploadImage'
import {useI18n} from 'vue-i18n'
import BaseFormBlock from '@/components/Base/BaseFormBlock'
import useCheckout from '@/use/checkout'
import {computed} from 'vue'

const {t} = useI18n()
const baseT = 'constructor.steps.two.logo.'

const {addSetGetField} = useCheckout()

const base = 'style'
const action = 'UPDATE_STYLE'
const logo = computed(addSetGetField({base, action, field: 'logo'}))
const horizontalLogo = computed(addSetGetField({base, action, field: 'logoHorizontal'}))
</script>

<style lang="scss" scoped>
.constructor-logo {
  margin: 112px 0 72px;

  &__grid {
    display: grid;
    grid-template-columns: 3fr 2fr 3fr 2fr;
    gap: 32px;
  }
}

@media (max-width: 991px) {
  .constructor-logo__grid {
    grid-template-columns: 1fr auto;
  }
}

@media (max-width: 575px) {
  .constructor-logo__grid {
    grid-template-columns: 1fr;
  }
}
</style>

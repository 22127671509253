<template>
  <div class="home-enterprise">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 offset-xl-2 position-relative">
          <header class="home-enterprise__header text-center">
            <base-title type="h2">{{ t(`${baseT}title`) }}</base-title>
          </header>
          <div class="home-enterprise__table">
            <div class="home-enterprise__tbody">
              <div class="home-enterprise__tr">
                <div class="home-enterprise__td">{{ t(`${baseT}blockchain`) }}</div>
                <div class="home-enterprise__td">{{ blockchainPrice }}</div>
              </div>
              <div class="home-enterprise__thead">
                <div class="home-enterprise__td">{{ t(`${baseT}app_thead`) }}</div>
              </div>
              <div class="home-enterprise__tr">
                <div class="home-enterprise__td">{{ t(`${baseT}app_mobile`) }}</div>
                <div class="home-enterprise__td">{{ mobilePrice }}</div>
              </div>
              <div class="home-enterprise__tr">
                <div class="home-enterprise__td">{{ t(`${baseT}app_web`) }}</div>
                <div class="home-enterprise__td">{{ webPrice }}</div>
              </div>
              <div class="home-enterprise__tr">
                <div class="home-enterprise__td">{{ t(`${baseT}app_desktop`) }}</div>
                <div class="home-enterprise__td">{{ t(`${baseT}app_desktop_value`) }}</div>
              </div>
              <div class="home-enterprise__thead">
                <div class="home-enterprise__td">{{ t(`${baseT}services_thead`) }}</div>
              </div>
              <div class="home-enterprise__tr">
                <div class="home-enterprise__td">{{ t(`${baseT}services_node`) }}</div>
                <div class="home-enterprise__td">{{ nodeMinPrice }}-{{ nodeMaxPrice }}</div>
              </div>
              <div class="home-enterprise__tr">
                <div class="home-enterprise__td">{{ t(`${baseT}services_sla`) }}</div>
                <div class="home-enterprise__td">{{ slaMinPrice }}-{{ slaMaxPrice }}</div>
              </div>
            </div>
            <div class="home-enterprise__tfoot">
              <div class="home-enterprise__td">{{ t(`${baseT}total`) }}</div>
              <div class="home-enterprise__td">{{ blockchainPrice }} - {{ totalMax }}</div>
            </div>
          </div>
          <router-link class="home-enterprise__button" :to="{name: 'constructor'}">
            {{ t(`${baseT}button`) }}
          </router-link>
          <img class="home-enterprise__figure-one" :src="enterpriseFigureOne" alt="enterprise figure">
          <img class="home-enterprise__figure-two" :src="enterpriseFigureTwo" alt="enterprise figure">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'
import priceList from '@/helpers/priceList'
import {numberToCurrency} from '@/helpers'
import enterpriseFigureOne from '@/assets/enterprise-figure-1.png'
import enterpriseFigureTwo from '@/assets/enterprise-figure-2.png'
import {onMounted, ref} from 'vue'

const {t, locale} = useI18n()
const baseT = 'home.enterprise.'

const blockchainPrice = ref()
const mobilePrice = ref()
const webPrice = ref()
const nodeMinPrice = ref()
const nodeMaxPrice = ref()
const slaMinPrice = ref()
const slaMaxPrice = ref()
const totalMax = ref()

const setPrices = locale => {
  const {blockchain, mobile, web, node4, node16, sla_base_6, sla_pro_6} = priceList[locale]
  blockchainPrice.value = numberToCurrency(blockchain, locale)
  mobilePrice.value = numberToCurrency(mobile, locale)
  webPrice.value = numberToCurrency(web, locale)
  nodeMinPrice.value = numberToCurrency(node4, locale)
  nodeMaxPrice.value = numberToCurrency(node16, locale)
  slaMinPrice.value = numberToCurrency(sla_base_6, locale)
  slaMaxPrice.value = numberToCurrency(sla_pro_6, locale)
  totalMax.value = numberToCurrency(blockchain + mobile + web + node16 + sla_pro_6, locale)
}

onMounted(() => {
  setPrices(locale.value)
})
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.home-enterprise {
  --border-color: #171717;
  position: relative;
  z-index: 0;

  &__figure-one, &__figure-two {
    position: absolute;
  }

  &__figure-one {
    width: 53.82%;
    left: -47%;
    bottom: -20%;
  }

  &__figure-two {
    width: 61.81%;
    top: -18%;
    right: -58%;
  }

  &:after {
    position: absolute;
    inset: 3%;
    border-radius: 30vw;
    content: none;
    background: linear-gradient(49.43deg, #5F52D8 10.31%, #AA42B9 34%, #FE7443 79.09%);
    opacity: 0.7;
    filter: blur(278px);
    z-index: -1;
  }

  &__header {
    margin-bottom: 47px;
  }

  &__table {
    border: 2px solid var(--border-color);
    border-radius: 30px;
    overflow: hidden;
    padding: 36px 33px;
    font-size: 24px;
    line-height: 1.17;

    @media (max-width: 767px) {
      padding: 20px 10px 10px;
      font-size: 18px;
    }
  }

  &__tbody {
    padding: 0 24px;
  }

  &__tr {
    display: grid;
    grid-template-columns: 1fr 225px;

    @media (max-width: 767px) {
      grid-template-columns: 1fr 90px;
    }
  }

  &__thead {
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    padding: 6px 0;
    margin-top: 31px;
  }

  &__tr {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  &__td {
    font-feature-settings: 'pnum' on, 'lnum' on;

    &:nth-child(2) {
      text-align: right;
    }
  }

  &__tfoot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;
    background: white;
    border-radius: 0 0 24px 24px;
    padding: 19px 24px;
    color: $color-text;
  }

  &__button {
    display: block;
    width: 100%;
    text-align: center;
    background-color: white;
    color: $color-danger;
    height: 64px;
    line-height: 64px;
    margin-top: 30px;
    border-radius: 32px;
    font-weight: 600;
    font-size: 20px;
    text-decoration: none;
  }
}
</style>

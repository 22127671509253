<template>
  <div class="home-integration">
    <div class="container">
      <div class="row gy-4">
        <div class="col-lg-5">
          <header>
            <base-title type="h2">{{ t(`${baseT}title`) }}</base-title>
          </header>
          <div class="home-integration__desc" v-html="t(`${baseT}desc_1`)"/>
          <a :href="linkDeveloper" class="home-integration__dev" target="_blank">
            <span>{{ t(`${baseT}desc_2`) }}</span>
            <IconLink/>
          </a>
        </div>
        <div class="home-integration__image col-lg-6 offset-lg-1 order-first order-lg-last">
          <img :src="imageIntegration" alt="integration">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import imageIntegration from '@/assets/integration.png'
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'
import IconLink from '@/components/Icon/IconLink'

const {t, locale} = useI18n()
const baseT = 'home.integration.'
const linkDeveloper = locale.value === 'ru'
    ? 'https://erachain.ru/home-developers'
    : 'https://erachain.org/home-developers'
</script>

<style lang="scss">
@import "~@/scss/variables.scss";

.home-integration {
  overflow: hidden;

  &__desc {
    margin-top: 36px;
    font-size: 24px;
    line-height: 34px;

    a {
      color: $color-danger;
      text-decoration: none;
      transition: .5s;

      &:hover {
        color: darken($color-danger, 20%);
      }
    }
  }

  &__dev {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 28px;
    text-decoration: none;
    color: inherit;
    font-weight: 600;
    transition: .5s;

    &:hover {
      color: $color-danger;
    }
  }
}

@media (max-width: 991px) {
  .home-integration__image {
    text-align: center;

    img {
      max-width: 60%;
    }
  }
}
</style>

<template>
  <div class="contacts-info-expert">
    <header class="contacts-info-expert__header">
      <div class="contacts-info-expert__title">{{ t(`${baseT}expert.title`) }}</div>
    </header>
    <div class="contacts-info-expert__list">
      <contacts-info-expert-item
          v-for="expert in experts" :key="expert.name"
          class="contacts-info-expert__item"
          :socials="expert.socials"
      >
        <template #image>
          <img :src="expert.avatar" :alt="expert.name"/>
        </template>
        <template #name>{{ expert.name }}</template>
        <template #position>{{ expert.position }}</template>
        <template #qr>
          <img :src="expert.qr" :alt="expert.telegram"/>
        </template>
      </contacts-info-expert-item>
    </div>
  </div>
</template>

<script setup>
import {computed, inject} from 'vue'
import {useI18n} from 'vue-i18n'
import ContactsInfoExpertItem from '@/pages/Contacts/components/ContactsInfoExpertItem'

const {t} = useI18n()
const baseT = inject('baseT')

const experts = computed(() => ([
  {
    name: t(`${baseT}expert.expert_1_name`),
    position: t(`${baseT}expert.expert_1_position`),
    socials: [
      {name: 'Telegram', link: 'https://t.me/franticellen'},
      {name: 'WhatsApp', link: 'https://wa.me/79778877968'},
      {name: 'E-mail', link: 'mailto:elena@erachain.org'},
    ],
    avatar: require('@/assets/expert-elena.png'),
    qr: require('@/assets/expert-elena-qr.png'),
  },
  {
    name: t(`${baseT}expert.expert_2_name`),
    position: t(`${baseT}expert.expert_2_position`),
    socials: [
      {name: 'Telegram', link: 'https://t.me/vatko1'},
      {name: 'E-mail', link: 'mailto:vas@erachain.org'},
    ],
    avatar: require('@/assets/expert-vasiliy.png'),
    qr: require('@/assets/expert-vasiliy-qr.png'),
  },
]))
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.contacts-info-expert {
  &__title {
    font-weight: 300;
    font-size: 16px;
    color: $border-color;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 22px;
    gap: 32px;
  }
}

@media (max-width: 767px) {
  .contacts-info-expert {
    &__list {
      margin: -4px -16px -26px;
      padding: 26px 16px;
      grid-template-columns: 249px 249px;
      -webkit-overflow-scrolling: touch;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

}
</style>

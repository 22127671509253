import {EraChain} from 'erachain-js-api'

export const generateSeed = () => EraChain.Crypt.generateSeed()

/**
 * Генерация определенного кол-ва адресов по сиду
 * @param {String} seed
 * @param {Number} count
 */
export const generateAddressesBySeed = async (seed, count) => {
  const addresses = []
  for (let i = 0; i < count; i++) {
    addresses.push(await getAddressBySeedAndNumber(seed, i))
  }

  return addresses
}

/**
 * Получение адреса по сиду и порядковому номеру
 * @param {String} seed
 * @param {Number} number
 * @return {Promise<Object>}
 */
export const getAddressBySeedAndNumber = async (seed, number) => {
  const accountSeed = await EraChain.Crypt.generateAccountSeed(seed, number)
  const {publicKey} = await EraChain.Crypt.getKeyPairFromSeed(accountSeed, 0)
  return {
    address: await EraChain.Crypt.addressByPublicKey(publicKey),
    investCount: 0,
    fuelCount: 0,
  }
}

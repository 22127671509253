const DEFAULT_VALUES = {
  minCount: 0.02,
  APYByYear: null,
  fuelTokenByYear: null,
}

export default {
  namespaced: true,
  state() {
    return {...DEFAULT_VALUES}
  },
  mutations: {
    UPDATE_STAKE(state, {field, value}) {
      state[field] = value
    },
  },
  actions: {
    async reset({commit}) {
      Object.entries(DEFAULT_VALUES).forEach(([field, value]) => {
        commit('UPDATE_STAKE', {field, value})
      })
    },
    UPDATE_STAKE({commit, dispatch}, {field, value}) {
      commit('UPDATE_STAKE', {field, value})
      dispatch('general/resetConfigStatus', null, {root: true})
    },
  },
}

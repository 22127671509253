<template>
  <BaseInputRange
      v-model:rangeValue="blockInterval"
      min="0.5"
      max="5"
      step="0.5"
      min-view="30 сек"
      max-view="5 мин"
      :current-value="blockIntervalView"
  />
</template>

<script>
import BaseInputRange from '../../Base/BaseInputRange.vue'
import {computed, ref, watch} from 'vue'

export default {
  name: 'RangeBlockInterval',
  components: {BaseInputRange},
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const blockInterval = ref(props.modelValue)
    const blockIntervalView = computed(() => {
      if (blockInterval.value < 1) return blockInterval.value * 60 + ' сек'

      const test = String(blockInterval.value).split('.')
      return test.length === 1
          ? test[0] + ' мин'
          : `${test[0]} мин 30 сек`
    })

    watch(blockInterval, () => emit('update:modelValue', blockInterval.value))

    return {
      blockInterval,
      blockIntervalView,
    }
  },
}
</script>

<template>
  <svg width="41" height="50" viewBox="0 0 41 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.5079 18.4291V28.2116V44.6572L38.4529 41.3967V15.1554L35.5079 18.4291ZM39.9947 42.5175C40.2407 42.2741 40.4162 41.8401 40.4162 41.097V13.8798C40.4162 13.3592 40.2093 12.8596 39.8412 12.4915C39.4731 12.1234 38.9735 11.9165 38.4529 11.9165H8.73805C7.88917 11.9467 7.06787 12.2268 6.37722 12.7216L0.551161 17.2271C0.277259 17.5133 0.137895 17.9025 0.16813 18.2973V46.2754C0.168569 47.0564 0.479281 47.8049 1.03147 48.3571C1.58366 48.9093 2.33221 49.22 3.11317 49.2205H32.9014C32.9014 49.2205 33.8924 49.2306 34.7335 48.3571C34.7778 48.3111 34.8195 48.2649 34.8589 48.2185C34.8724 48.2053 34.8855 48.1916 34.8984 48.1774L39.9026 42.637C39.9369 42.599 39.9676 42.559 39.9947 42.5175ZM8.73737 13.8789C8.32369 13.9096 7.92445 14.0463 7.57911 14.2764L3.64706 17.3147H33.8679L36.96 13.8789H8.73737ZM6.05758 44.3109H10.9659C11.508 44.3109 11.9476 43.8714 11.9476 43.3293C11.9476 42.7872 11.508 42.3476 10.9659 42.3476H7.03925V38.4209C7.03925 37.8788 6.59969 37.4393 6.05758 37.4393C5.51548 37.4393 5.07591 37.8788 5.07591 38.4209V43.3293C5.07766 43.8709 5.51591 44.3092 6.05758 44.3109Z"
        fill="url(#paint0_linear_1418_1078)"
    />
    <path
        d="M21.8503 0.611251C21.4779 0.238839 20.8741 0.238839 20.5017 0.611251L14.4329 6.68005C14.0605 7.05247 14.0605 7.65627 14.4329 8.02868C14.8053 8.40109 15.4091 8.40109 15.7815 8.02868L21.176 2.63419L26.5705 8.02868C26.9429 8.40109 27.5467 8.40109 27.9191 8.02868C28.2916 7.65626 28.2916 7.05247 27.9191 6.68005L21.8503 0.611251ZM22.1296 16.0947L22.1296 1.28556L20.2224 1.28556L20.2224 16.0947L22.1296 16.0947Z"
        fill="currentColor"
    />
    <defs>
      <linearGradient
          id="paint0_linear_1418_1078"
          x1="20.2901"
          y1="11.9165"
          x2="20.2901"
          y2="49.2205"
          gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="currentColor"/>
        <stop offset="1" stop-color="currentColor" stop-opacity="0.33"/>
      </linearGradient>
    </defs>
  </svg>
</template>

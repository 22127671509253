<template>
  <div class="eralauncher" ref="view" :style="{'--view-width': viewWidth}">
    <TheHeader class="eralauncher__header"/>
    <RouterView/>
    <TheFooter/>
    <div class="eralauncher__cookies">
      <div class="container">
        <TheCookies/>
      </div>
    </div>
  </div>
</template>

<script setup>
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import {useStore} from 'vuex'
import {computed, onMounted, provide, ref} from 'vue'
import TheCookies from '@/components/TheCookies'

const store = useStore()

const view = ref()
const viewWidth = ref(0)

const isMobile = computed(() => {
  if (viewWidth?.value) {
    return viewWidth.value < 767
  }
  return false
})

const getViewWidth = () => {
  if (typeof view?.value?.getBoundingClientRect !== 'function') return
  const sizes = view.value.getBoundingClientRect()
  if (sizes?.width) viewWidth.value = sizes.width
}

onMounted(() => {
  getViewWidth()
})

provide('isMobile', isMobile)
store.dispatch('init')
</script>

<style lang="scss">
.eralauncher {
  display: flex;
  padding-top: 69px;
  min-height: 100vh;
  flex-direction: column;

  &__cookies {
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
    z-index: 99999;
  }
}

@media (max-width: 767px) {
  .eralauncher {
    padding-top: 100px;
  }
}
</style>

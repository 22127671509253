<template>
  <div class="download" @click="download">
    <svg width="20" height="20" viewBox="0 0 348 348" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M174 246.8C178.453 246.796 182.723 245.023 185.871 241.87L264.945 162.8L241.199 139.054L190.801 189.452V0.402283H157.199V189.462L106.801 139.064L83.055 162.806L162.129 241.876C165.277 245.029 169.547 246.802 174 246.806V246.8Z"
          fill="currentColor"/>
      <path
          d="M174 347.6C208.477 347.577 242.164 337.291 270.77 318.057C299.379 298.819 321.614 271.498 334.633 239.577C347.656 207.655 350.883 172.581 343.895 138.817C336.91 105.059 320.032 74.145 295.407 50.012L271.887 74.035C298.52 100.14 313.637 135.793 313.887 173.082C314.133 210.375 299.492 246.223 273.211 272.68C246.93 299.137 211.18 314.016 173.887 314.016C136.598 314.016 100.844 299.137 74.5627 272.68C48.2817 246.223 33.6407 210.375 33.8907 173.082C34.1407 135.793 49.2577 100.141 75.8867 74.035L52.3667 50.012C27.7337 74.157 10.8467 105.094 3.87071 138.871C-3.10979 172.652 0.132407 207.746 13.1832 239.671C26.2342 271.601 48.4992 298.921 77.1442 318.144C105.785 337.363 139.507 347.617 173.999 347.593L174 347.6Z"
          fill="currentColor"/>
    </svg>
  </div>
</template>

<script setup>
import {defineProps} from 'vue'
import {saveAs} from 'file-saver'

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'text/plain;charset=utf-8',
  },
  content: {
    type: String,
    required: true,
  },
})

const download = () => {
  const blob = new Blob([props.content], {type: props.type})
  saveAs(blob, props.name)
}
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.download {
  color: $color-danger;
  cursor: pointer;
}
</style>

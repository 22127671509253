<template>
  <div class="technology-data-collection">
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <header class="technology-data-collection__header">
            <base-title type="h3">{{ t(`${baseT}title`) }}</base-title>
          </header>
          <div class="technology-data-collection__desc">
            {{ t(`${baseT}desc`) }}
          </div>
        </div>
        <div class="col-md-6 offset-md-1">
          <img :src="imageData" alt="Data">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'
import imageData from '@/assets/data.png'

const {t} = useI18n()
const baseT = 'home.data_collection.'
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.technology-data-collection {
  font-size: 24px;
  line-height: 34px;

  &__header {
    padding-top: $row * 2;
    margin-bottom: $row * 1.5;
  }
}
</style>

<template>
  <div class="home-demo">
    <div class="container">
      <div class="home-demo__wrapper row gy-4 gy-lg-0">
        <div class="col-lg-5">
          <header class="home-demo__header">
            <base-title type="h2">{{ t(`${baseT}title`) }}</base-title>
          </header>
          <div class="home-demo__desc">{{ t(`${baseT}desc`) }}</div>
          <a class="home-demo__download" :href="urlDemoNet" target="_blank">
            <span>{{ t(`${baseT}download`) }}</span>
            <IconLink/>
          </a>
        </div>
        <div class="col-lg-7 d-flex">
          <div class="home-demo__image">
            <img :src="locale === 'en' ? imageDemoEn : imageDemo" alt="demo">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import imageDemo from '@/assets/demo.png'
import imageDemoEn from '@/assets/demo-en.png'
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'
import IconLink from '@/components/Icon/IconLink'

const {t, locale} = useI18n()
const baseT = 'home.demo.'

const urlDemoNet = 'https://web.erachain.org?mode=test'
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";
@import "~@/scss/mixins.scss";

.home-demo {
  &__wrapper {
    position: relative;
    z-index: 1;

    &:after {
      position: absolute;
      content: '';
      top: -100px;
      bottom: 0;
      left: 0;
      width: 66%;
      background-image: url("~@/assets/demo.svg");
      background-size: contain;
      background-repeat: no-repeat;
      z-index: -1;

      @media (max-width: 767px) {
        content: none;
      }
    }
  }

  &__header, &__desc, &__download {
    margin-left: 82px;
  }

  &__desc {
    margin-top: 34px;
    margin-bottom: 52px;
    margin-right: 30px;
    font-size: 24px;
    line-height: 34px;
  }

  &__download {
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    color: inherit;
    font-weight: 600;
    transition: .5s;

    &:hover {
      color: $color-danger;
    }
  }

  &__image {
    position: relative;
    top: 70px;
    z-index: 0;

    img {
      display: block;
      max-width: 100%;
      border-radius: 28px;
    }

    &::before {
      position: absolute;
      content: '';
      inset: -4px;
      border-radius: 30px;
      border: 5px solid #F7F7FF;
    }

    &::after {
      position: absolute;
      content: '';
      z-index: -1;
      width: 100%;
      height: 95%;
      top: 10%;
      opacity: 0.5;
      filter: blur(22px);
      border-radius: 59px;
      background: linear-gradient(45.01deg, #18AFF2 14.78%, #5F52D8 33.84%, #AA42B9 52.19%, #F44D61 67.73%, #FE7443 85.38%);
    }
  }
}
</style>

<template>
  <div class="checkout-form">
    <base-form-block class="checkout-form__block">
      <template #title>{{ t(`${baseT}title`) }}</template>
      <template #content>
        <div class="checkout-form__grid">
          <div>
            <base-form-field>
              <template #label>{{ t(`${baseT}name`) }}</template>
              <template #default>
                <BaseInput v-model="name" :placeholder="t(`${baseT}name_placeholder`)"/>
              </template>
            </base-form-field>
          </div>
          <div>
            <base-form-field>
              <template #label>{{ t(`${baseT}city`) }}</template>
              <template #default>
                <BaseInput v-model="city" :placeholder="t(`${baseT}city_placeholder`)"/>
              </template>
            </base-form-field>
          </div>
          <base-form-field>
            <template #label>{{ t(`${baseT}email`) }}</template>
            <template #default>
              <BaseInput v-model="email" placeholder="email@email.com"/>
            </template>
          </base-form-field>
          <base-form-field>
            <template #label>{{ t(`${baseT}phone`) }}</template>
            <template #default>
              <BaseInput v-model="phone" :placeholder="t(`${baseT}phone_placeholder`)"/>
            </template>
          </base-form-field>
          <base-form-field>
            <template #label>{{ t(`${baseT}company`) }}</template>
            <template #default>
              <BaseInput v-model="company" :placeholder="t(`${baseT}company_placeholder`)"/>
            </template>
          </base-form-field>
          <base-form-field>
            <template #label>{{ t(`${baseT}business`) }}</template>
            <template #default>
              <BaseInput v-model="business" :placeholder="t(`${baseT}business_placeholder`)"/>
            </template>
          </base-form-field>
          <base-form-field class="checkout-form__textarea">
            <template #label>{{ t(`${baseT}usage`) }}</template>
            <template #default>
              <BaseTextarea v-model="usage" :placeholder="t(`${baseT}usage_placeholder`)"/>
            </template>
          </base-form-field>
        </div>
      </template>
    </base-form-block>
    <base-form-block class="checkout-form__block">
      <template #title>{{ t(`${baseT}payments`) }}</template>
      <template #content>
        <p>{{ t(`${baseT}payments_2`) }}</p>
        <p>{{ t(`${baseT}payments_3`) }}</p>
      </template>
    </base-form-block>
    <base-form-block class="checkout-form__block">
      <template #content>
        <div class="checkout-form__checkbox">
          <base-checkbox v-model="policy" name="policy">
            <span v-html="t(`${baseT}policy`, {baseURL})"/>
          </base-checkbox>
          <base-checkbox v-model="license" name="license">
            <span v-html="t(`${baseT}license`, {baseURL})"/>
          </base-checkbox>
        </div>
      </template>
    </base-form-block>
    <div class="checkout-form__footer">
      <router-link class="checkout-form__back" :to="{name: 'constructor'}">
        <IconBack/>
        <span>{{ t(`${baseT}back`) }}</span>
      </router-link>
      <BaseLoading v-if="pending"/>
      <base-button @action="send" :disabled="pending">{{ t(`${baseT}button`) }}</base-button>
    </div>
    <teleport to="body">
      <base-modal-message v-if="openFieldsError" @close="closeModalFields">
        {{ t('form.error_fields') }}
      </base-modal-message>
      <base-modal-message v-if="openSendError" @close="closeModalSendError">
        {{ t('form.error_send', {email: 'info@erachain.org'}) }}
      </base-modal-message>
      <base-modal-alert v-if="openSuccess" @close="closeModalSuccess">
        <p>{{ t(`${baseT}success_title`) }}</p>
        <p>{{ t(`${baseT}success_message`) }}</p>
      </base-modal-alert>
    </teleport>
  </div>
</template>

<script setup>
import {useI18n} from 'vue-i18n'
import BaseFormBlock from '@/components/Base/BaseFormBlock'
import BaseFormField from '@/components/Base/BaseFormField'
import BaseInput from '@/components/Base/BaseInput'
import BaseTextarea from '@/components/Base/BaseTextarea'
import IconBack from '@/components/Icon/IconBack'
import BaseButton from '@/components/Base/BaseButton'
import BaseCheckbox from '@/components/Base/BaseCheckbox'
import {computed, onMounted, ref} from 'vue'
import useCheckout from '@/use/checkout'
import http from '@/http'
import useActionRouter from '@/use/actionRouter'
import {useStore} from 'vuex'
import BaseModalMessage from '@/components/Base/Modal/BaseModalMessage'
import BaseModalAlert from '@/components/Base/Modal/BaseModalAlert'
import BaseLoading from '@/components/Base/BaseLoading'
import config from '@/config'
import {sendGTMEvent, sendYMEvent} from '@/helpers/analytics'

const {t} = useI18n()
const baseT = 'checkout.form.'

const {addSetGetField, generateDataForEmail, generateDataArchive} = useCheckout()

const base = 'checkout'
const action = 'UPDATE_CHECKOUT'

const baseURL = config.baseURL

const store = useStore()
const {goHome} = useActionRouter()

const openFieldsError = ref(false)
const openSuccess = ref(false)
const openSendError = ref(false)

const pending = ref(false)

const closeModal = openStatus => {
  openStatus.value = false
}

const closeModalFields = () => closeModal(openFieldsError)
const closeModalSendError = () => closeModal(openSendError)
const closeModalSuccess = () => {
  closeModal(openSuccess)
  goHome()
}

onMounted(() => {
  if (!store.state.general.name) {
    goHome()
  }
})

const name = computed(addSetGetField({base, action, field: 'name'}))
const city = computed(addSetGetField({base, action, field: 'city'}))
const email = computed(addSetGetField({base, action, field: 'email'}))
const phone = computed(addSetGetField({base, action, field: 'phone'}))
const company = computed(addSetGetField({base, action, field: 'company'}))
const business = computed(addSetGetField({base, action, field: 'business'}))
const usage = computed(addSetGetField({base, action, field: 'usage'}))
const policy = computed(addSetGetField({base, action, field: 'policy'}))
const license = computed(addSetGetField({base, action, field: 'license'}))

const required = ['name', 'email', 'phone', 'policy', 'license']

const invalidRequired = () => {
  return !required.every(req => !!store.state.checkout[req])
}

const send = async () => {
  if (invalidRequired()) {
    return openFieldsError.value = true
  }

  const formData = new FormData()
  formData.append('html', generateDataForEmail())

  formData.append('file_name', 'media.zip')
  formData.append('file', await generateDataArchive())

  pending.value = true

  http.post('/form-checkout', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then(response => {
    if (response?.data === 'ok') {
      openSuccess.value = true
      sendGTMEvent('send_checkout_form')
      sendYMEvent('send_checkout_form')
      store.dispatch('reset')
    } else {
      openSendError.value = true
    }
  }).catch(() => {
    openSendError.value = true
  }).finally(() => {
    pending.value = false
  })
}
</script>

<style lang="scss">
@import "~@/scss/variables.scss";

.checkout-form {
  &__block {
    border-top: 2px solid $color-primary;
    padding: 32px 0;
  }

  &__grid {
    display: grid;
    gap: 32px 24px;
    grid-template-columns: 1fr 1fr;
  }

  &__textarea {
    grid-column: 1 / 3;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 40px;
  }

  &__back {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $color-primary;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__checkbox {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #69749B;
  }

  &__error {
    border-radius: 14px;
    padding: 20px;
    text-align: center;
    border: 1px solid red;
    margin-bottom: 24px;
  }
}

@media (max-width: 575px) {
  .checkout-form {
    &__grid {
      grid-template-columns: 1fr;
      gap: 24px;
    }

    &__textarea {
      grid-column: 1 / 2;
    }

    &__footer {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    &__back {
      order: 1;
    }
  }
}
</style>

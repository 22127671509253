<template>
  <div class="constructor-step-three">
    <constructor-step-title>{{ t('constructor.steps.title', {number: 3}) }}</constructor-step-title>
    <div class="container">
      <header class="constructor-step-three__header">
        <base-title type="h2">{{ t(`${baseT}title`) }}</base-title>
        <div class="constructor-step-three__desc">{{ t(`${baseT}desc`) }}</div>
      </header>
      <div class="row">
        <div class="col-xl-10 offset-xl-1">
          <div class="constructor-step-three__info">
            <ConstructorNodeMin/>
            <ConstructorNodeOptions/>
          </div>
        </div>
      </div>
      <ConstructorDeploymentOptions/>
    </div>
  </div>
</template>

<script setup>
import ConstructorStepTitle from '@/pages/Constructor/components/ConstructorStepTitle'
import {useI18n} from 'vue-i18n'
import BaseTitle from '@/components/Base/BaseTitle'
import {provide} from 'vue'
import ConstructorNodeOptions from '@/pages/Constructor/components/ConstructorNodeOptions'
import ConstructorNodeMin from '@/pages/Constructor/components/ConstructorNodeMin'
import ConstructorDeploymentOptions from '@/pages/Constructor/components/ConstructorDeploymentOptions'

const {t} = useI18n()
const baseT = 'constructor.steps.three.'

provide('baseT', baseT)
</script>

<style lang="scss">
@import "~@/scss/variables.scss";

.constructor-step-three {
  &__header {
    margin: 30px 0 76px;
    text-align: center;
  }

  &__desc {
    font-size: 24px;
    line-height: 32px;
    margin-top: 26px;
  }

  &__info {
    display: grid;
    grid-template-columns: 3.25fr 6fr;
    gap: 93px;
    margin-bottom: 102px;
  }
}

@media (max-width: 991px) {
  .constructor-step-three__info {
    grid-template-columns: 1fr;
    gap: 32px;
  }
}
</style>

<template>
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M2.68656 0.889893C1.33328 0.889893 0.23146 1.9915 0.230469 3.34585C0.230469 4.70019 1.33229 5.80201 2.68656 5.80201C4.04034 5.80201 5.1416 4.70019 5.1416 3.34585C5.1416 1.99164 4.04027 0.889893 2.68656 0.889893Z"
        fill="currentColor"/>
    <path
        d="M4.52996 6.78906H0.843028C0.667648 6.78906 0.525391 6.93125 0.525391 7.10677V18.9739C0.525391 19.1494 0.667648 19.2916 0.843028 19.2916H4.52989C4.70534 19.2916 4.8476 19.1493 4.8476 18.9739V7.10677C4.84767 6.93118 4.70542 6.78906 4.52996 6.78906Z"
        fill="currentColor"/>
    <path
        d="M13.956 6.64893C12.6067 6.64893 11.421 7.05977 10.6975 7.72973V7.10634C10.6975 6.93082 10.5552 6.78863 10.3798 6.78863H6.8431C6.66765 6.78863 6.52539 6.93082 6.52539 7.10634V18.9735C6.52539 19.1489 6.66765 19.2911 6.8431 19.2911H10.5265C10.7019 19.2911 10.8442 19.1489 10.8442 18.9735V13.1022C10.8442 11.4177 11.1541 10.3735 12.7004 10.3735C14.2239 10.3754 14.3379 11.495 14.3379 13.2029V18.9735C14.3379 19.1489 14.4802 19.2911 14.6557 19.2911H18.3408C18.5162 19.2911 18.6584 19.1489 18.6584 18.9735V12.4638C18.6584 9.7565 18.1239 6.64893 13.956 6.64893Z"
        fill="currentColor"/>
  </svg>
</template>

<template>
  <div
      class="interface-main"
      :style="{'--color-button': '#' + colorButton, '--color-link': '#' + colorLink}"
  >
    <div class="interface-main__balance">
      <header class="interface-main__header">
        <h4 class="interface-main__title">{{ t(`${baseT}balance_title`) }}</h4>
        <div class="interface-main__select">
          <span>{{ t(`${baseT}balance_title`) }}</span>
          <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.63462 5.26459L0.950317 0.735352L2.35561 0.735352L6.00937 4.19444L9.68186 0.735352L11.0497 0.735352L6.3279 5.26459H5.63462Z"
                fill="#787878"/>
          </svg>
        </div>
      </header>
      <div class="interface-main__table">
        <div class="interface-main__tr">
          <div class="interface-main__th">{{ t(`${baseT}balance_name`) }}</div>
          <div class="interface-main__th">{{ t(`${baseT}balance_count`) }}</div>
        </div>
        <div class="interface-main__tr">
          <div class="interface-main__td interface-main__td_active">Mycoin</div>
          <div class="interface-main__td">1 000 000 000</div>
          <div class="interface-main__point">
            <img :src="pointIcon" alt="Point">
          </div>
        </div>
        <div class="interface-main__tr">
          <div class="interface-main__td">Gas</div>
          <div class="interface-main__td">1 000</div>
        </div>
        <div class="interface-main__tr">
          <div class="interface-main__td">BTC</div>
          <div class="interface-main__td">0,0005</div>
        </div>
        <div class="interface-main__tr">
          <div class="interface-main__td">AirdropGift</div>
          <div class="interface-main__td">1 000</div>
        </div>
        <div class="interface-main__tr">
          <div class="interface-main__td">NFT. CryptoPunk #32</div>
          <div class="interface-main__td">1</div>
        </div>
      </div>
      <div class="interface-main__footer">
        <div class="interface-main__button">
          <span>{{ t(`${baseT}balance_all`) }}</span>
          <img :src="arrowButtonIcon" alt="Button">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {inject} from 'vue'
import arrowButtonIcon from '@/assets/arrow-button.svg'
import pointIcon from '@/assets/point.svg'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
const baseT = inject('baseT')

const colorLink = inject('colorLink')
const colorButton = inject('colorButton')
</script>

<style lang="scss" scoped>
.interface-main {
  padding: 20px;

  &__balance {
    width: 380px;
    background-color: white;
    border-radius: 17px;
    padding: 14px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid var(--color-button);
    padding-bottom: 12px;
    margin-bottom: 24px;
  }

  &__title {
    margin: 0;
    font-size: 18px;
    line-height: 21px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: black;
  }

  &__select {
    display: flex;
    align-items: center;
    padding: 4px 7px;
    gap: 7px;
    font-size: 14px;
    line-height: 16px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #787878;
    background-color: #F2F2F2;
    border-radius: 4px;
  }

  &__tr {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 110px;
    border-bottom: 1px solid #D9D7E7;
  }

  &__th {
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.02em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #787878;
    padding-bottom: 3px;
  }

  &__td {
    white-space: nowrap;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    padding: 5px 0;

    &_active {
      color: var(--color-link);
    }
  }

  &__point {
    position: absolute;
    top: 13px;
    left: 37px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }

  &__button {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 9px 27px;
    border: 1px solid var(--color-button);
    color: var(--color-button);
    font-size: 14px;
    line-height: 16px;
    border-radius: 30px;
  }
}
</style>

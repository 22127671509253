<template>
  <div class="homa-advantage-items" ref="slider" v-if="!isMobile">
    <swiper
        @swiper="onSwiper"
        :modules="modules"
        :pagination="{type:'custom', renderCustom}"
        :space-between="200"
        navigation
    >
      <swiper-slide>
        <HomeAdvantageItemLight/>
      </swiper-slide>
      <swiper-slide>
        <HomeAdvantageItemFlexible/>
      </swiper-slide>
      <swiper-slide>
        <HomeAdvantageItemTokens/>
      </swiper-slide>
      <swiper-slide>
        <HomeAdvantageItemBlock/>
      </swiper-slide>
    </swiper>
  </div>
  <div class="homa-advantage-items_no-slider" v-else>
    <HomeAdvantageItemLight/>
    <HomeAdvantageItemFlexible/>
    <HomeAdvantageItemTokens/>
    <HomeAdvantageItemBlock/>
  </div>
</template>

<script setup>
import {Navigation, Pagination} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue.js'
import 'swiper/swiper-bundle.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import HomeAdvantageItemLight from '@/pages/Home/components/HomeAdvantageItemLight'
import HomeAdvantageItemFlexible from '@/pages/Home/components/HomeAdvantageItemFlexible'
import HomeAdvantageItemBlock from '@/pages/Home/components/HomeAdvantageItemBlock'
import HomeAdvantageItemTokens from '@/pages/Home/components/HomeAdvantageItemTokens'
import {useI18n} from 'vue-i18n'
import {gsap} from '@/myGsap'
import {onMounted, ref, inject, computed, onUnmounted} from 'vue'

const isMobile = inject('isMobile')

const {t} = useI18n()
const modules = [Pagination, Navigation]

const paginationNames = computed(() => ([
  t('home.advantage.items.light.title_slide'),
  t('home.advantage.items.flexible.title_slide'),
  t('home.advantage.items.tokens.title_slide'),
  t('home.advantage.items.block.title_slide'),
]))

const renderCustom = (swiper, current, total) => {
  let output = '<div class="homa-advantage-items__pagination">'
  const title = paginationNames.value[current - 1]

  for (let i = 1; i <= total; i++) {
    output += '<div class="homa-advantage-items__pagination-item">'
    output += current === i ? title : '&nbsp;'
    output += '</div>'
  }

  output += '</div>'

  return output
}

const slider = ref()
const sw = ref()
const timelineSlider = ref()

const onSwiper = swiper => {
  sw.value = swiper
}

onMounted(() => {
  setTimeout(() => {
    if (slider.value instanceof HTMLElement) {
      timelineSlider.value = gsap.timeline({
        scrollTrigger: {
          trigger: slider.value,
          start: 'top center-=300px',
          end: 'bottom+=400px center-=200px',
          toggleActions: 'play reverse play reset',
          easy: 'none',
          pin: true,
          onUpdate: (self) => {
            if (self.progress > 0 && self.progress <= 0.25) {
              if (sw.value.snapIndex !== 0) sw.value.slideTo(0)
            } else if (self.progress > 0.25 && self.progress <= 0.5) {
              if (sw.value.snapIndex !== 1) sw.value.slideTo(1)
            } else if (self.progress > 0.5 && self.progress <= 0.75) {
              if (sw.value.snapIndex !== 2) sw.value.slideTo(2)
            } else if (self.progress > 0.75 && self.progress <= 1) {
              if (sw.value.snapIndex !== 3) sw.value.slideTo(3)
            }
          },
        },
      })
    }
  }, 1000)
})

onUnmounted(() => {
  if (timelineSlider.value) {
    timelineSlider.value.kill()
  }
})
</script>

<style lang="scss">
@import "~@/scss/variables.scss";

.homa-advantage-items {
  &_no-slider {
    display: flex;
    flex-direction: column;
    gap: 100px;
  }

  .swiper {
    overflow: visible;

    &-slide {
      padding-top: 100px;
      padding-bottom: 60px;
    }

    &-button-prev, &-button-next {
      top: auto;
      bottom: 0;
      z-index: 99;
    }

    &-button-prev {
      left: 34%;
    }

    &-button-next {
      right: 34%;
    }
  }

  &__pagination {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 20px;

    &-item {
      position: relative;
      padding: 10px;
      color: #69749B;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.02em;

      &::after {
        position: absolute;
        content: '';
        height: 4px;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #B7C0DF;
        border-radius: 2px;
      }
    }
  }
}

@media (max-width: 991px) {
  .homa-advantage-items {
    .swiper {
      &-button-prev {
        left: 10%;
      }

      &-button-next {
        right: 10%;
      }
    }
  }
}

@media (max-width: 575px) {
  .homa-advantage-items {
    &__pagination {
      gap: 10px;
    }

    .swiper {
      &-button-prev {
        left: 0;
      }

      &-button-next {
        right: 0;
      }
    }
  }
}
</style>

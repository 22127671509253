<template>
  <div class="contacts-form">
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <header class="contacts-form__header">
          <div class="contacts-form__title">{{ t(`${baseT}title`) }}</div>
        </header>
        <div class="contacts-form__wrapper">
          <div class="contacts-form__name">
            <span>{{ t(`${baseT}name`) }}</span>
            <BaseInput v-model="name" class="contacts-form__input"/>
            <span>.</span>
          </div>
          <div class="contacts-form__target">
            <span>{{ t(`${baseT}target`) }}</span>
            <BaseTextareaFlexible
                v-model="idea"
                class="contacts-form__textarea"
                :placeholder="t(`${baseT}target_placeholder`)"
            />
          </div>
          <div class="contacts-form__date">
            <span>{{ t(`${baseT}date`) }}</span>
            <BaseInputFlexible
                v-model="date"
                class="contacts-form__input-flexible"
                :placeholder="t(`${baseT}date_placeholder_1`)"
            />
            <span>{{ t(`${baseT}date_in`) }}</span>
            <BaseInputFlexible
                v-model="time"
                class="contacts-form__input-flexible"
                :placeholder="t(`${baseT}date_placeholder_2`)"
            />
            <span>?</span>
          </div>
          <div class="contacts-form__email">
            <span>{{ t(`${baseT}email`) }}</span>
            <BaseInput v-model="email" class="contacts-form__input"/>
            <span>.</span>
          </div>
        </div>
        <div class="contacts-form__footer">
          <BaseLoading v-if="pending"/>
          <base-button
              color="danger"
              class="contacts-form__button"
              :disabled="pending"
              @click="send"
          >
            {{ t(`${baseT}button`) }}
          </base-button>
        </div>
        <teleport to="body">
          <base-modal-message v-if="openFieldsError" @close="closeModalFields">
            {{ t('form.error_fields') }}
          </base-modal-message>
          <base-modal-message v-if="openSendError" @close="closeModalSendError">
            {{ t('form.error_send', {email: 'info@erachain.org'}) }}
          </base-modal-message>
          <base-modal-alert v-if="openSuccess" @close="closeModalSuccess">
            <p>{{ t(`${baseT}success_title`) }}</p>
            <p>{{ t(`${baseT}success_message`) }}</p>
          </base-modal-alert>
        </teleport>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useI18n} from 'vue-i18n'
import BaseInput from '@/components/Base/BaseInput'
import BaseTextareaFlexible from '@/components/Base/BaseTextareaFlexible'
import BaseInputFlexible from '@/components/Base/BaseInputFlexible'
import BaseButton from '@/components/Base/BaseButton'
import {ref} from 'vue'
import {sendEmailContact} from '@/services/email'
import BaseModalMessage from '@/components/Base/Modal/BaseModalMessage'
import BaseModalAlert from '@/components/Base/Modal/BaseModalAlert'
import BaseLoading from '@/components/Base/BaseLoading'
import {sendGTMEvent, sendYMEvent} from '@/helpers/analytics'

const {t} = useI18n()
const baseT = 'contacts.form.'

const name = ref('')
const idea = ref('')
const date = ref('')
const time = ref('')
const email = ref('')

const openFieldsError = ref(false)
const openSuccess = ref(false)
const openSendError = ref(false)

const pending = ref(false)

const closeModal = openStatus => {
  openStatus.value = false
}

const closeModalFields = () => closeModal(openFieldsError)
const closeModalSuccess = () => closeModal(openSuccess)
const closeModalSendError = () => closeModal(openSendError)

const reset = () => {
  name.value = ''
  idea.value = ''
  date.value = ''
  time.value = ''
  email.value = ''
}

const send = () => {
  const outputDate = date.value + ' ' + time.value
  if (![name, idea, date, time, email].every(({value}) => !!value)) {
    return openFieldsError.value = true
  }

  const formData = new FormData()
  formData.append('name', name.value)
  formData.append('idea', idea.value)
  formData.append('date', outputDate)
  formData.append('email', email.value)

  pending.value = true

  sendEmailContact(formData).then(sendStatus => {
    if (sendStatus) {
      openSuccess.value = true
      sendGTMEvent('send_contact_form')
      sendYMEvent('send_contact_form')
      reset()
    } else {
      openSendError.value = true
    }
  }).catch(() => {
    openSendError.value = true
  }).finally(() => {
    pending.value = false
  })
}
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.contacts-form {
  background-color: #FCFCFF;
  border: 1px solid $color-danger;
  box-sizing: border-box;
  border-radius: 35px;
  padding: 72px 0;

  &__header {
    text-align: center;
    margin-bottom: 72px;
    font-weight: 300;
    font-size: 24px;
    line-height: 24px;
  }

  &__wrapper {
    font-size: 42px;
    line-height: 49px;
  }

  &__name, &__email {
    display: grid;
    gap: 10px;

    span {
      white-space: nowrap;
    }
  }

  &__name {
    grid-template-columns: auto 1fr auto;
  }

  &__email {
    grid-template-columns: auto 1fr auto;
    margin-top: 15px;
  }

  &__input {
    text-align: center;
    border-radius: 0;
  }

  &__target {
    margin-top: 80px;
  }

  &__textarea {
    margin-top: 30px;
  }

  &__textarea, &__input, &__input-flexible {
    color: $color-primary;

    &::placeholder, &[placeholder]:empty:before {
      font-weight: 300;
      font-size: 24px;
    }
  }

  &__textarea[placeholder]:empty:before {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__date {
    margin-top: 24px;
    line-height: 72px;

    & *:not(:first-child) {
      margin-left: 10px;
    }
  }

  &__input-flexible {
    position: relative;
    display: inline-block;
    min-width: 230px;
    text-align: center;
    padding: 0 10px;
    line-height: 49px;

    &[placeholder]:empty:before {
      position: absolute;
      left: 0;
      right: 0;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    margin-top: 66px;
    text-align: right;
  }

  &__button {
    height: 53px;
    line-height: 53px;
    border-radius: 53px;
  }
}

@media (max-width: 991px) {
  .contacts-form {
    &__wrapper {
      font-size: 24px;
      line-height: 32px;
    }

    &__textarea, &__input, &__input-flexible {
      line-height: 32px;

      &::placeholder, &[placeholder]:empty:before {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 767px) {
  .contacts-form {
    padding-right: 30px;
    padding-left: 30px;

    &__wrapper {
      font-size: 22px;
      line-height: 26px;
    }

    &__textarea, &__input, &__input-flexible {
      line-height: 24px;
    }

    &__date {
      display: grid;
      grid-template-columns: auto 1fr auto;
      line-height: 26px;
      gap: 12px;

      span {
        &:nth-child(1) {
          grid-column: 1 / 4;
        }

        &:nth-child(2) {
          grid-column: 1 / 4;
        }
      }
    }

    &__name, &__email {
      grid-template-columns: 1fr auto;

      span:first-child {
        grid-column: 1 / 3;
      }
    }

    &__input-flexible {
      min-width: 100px;
    }
  }
}

@media (max-width: 575px) {
  .contacts-form {
    padding: 44px 16px;

    &__header {
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 52px;
    }

    &__target, &__date, &__email {
      margin-top: 36px;
    }

    &__date {
      gap: 24px 12px;

      & *[data-v-4a801065]:not(:first-child) {
        margin-left: 0;
      }
    }

    &__email {
      gap: 24px 12px;
    }

    &__textarea, &__input, &__input-flexible {
      font-size: 16px;

      &::placeholder, &[placeholder]:empty:before {
        font-size: 16px;
      }
    }

    &__footer {
      margin-top: 60px;
      justify-content: center;
    }
  }
}
</style>

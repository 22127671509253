<template>
  <span class="required">
    {{ multi ? t(`${baseT}required_multi`) : t(`${baseT}required`) }}
  </span>
</template>

<script setup>
import {defineProps, inject} from 'vue'
import {useI18n} from 'vue-i18n'

defineProps({
  multi: {
    type: Boolean,
    default: false,
  },
})

const {t} = useI18n()
const baseT = inject('baseT')
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

@mixin style-required {
  border: 1px solid #EA4B5F;
  border-radius: 6px;
  background-color: white;
}

.required {
  @include style-required;

  position: relative;
  display: inline-block;
  color: $color-danger;
  margin-left: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.02em;
  padding: 0 10px;
  z-index: 1;

  &:before {
    @include style-required;

    position: absolute;
    content: '';
    top: 2px;
    left: -8px;
    height: 22px;
    width: 23px;
    transform: rotate(-45deg);
    transform-origin: center center;
    z-index: -1;
  }

  &:after {
    position: absolute;
    content: '';
    inset: 0;
    border-radius: 6px;
    background-color: white;
    z-index: -1;
  }
}
</style>

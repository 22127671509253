<template>
  <div class="interface-top-panel" :style="{'--color-link': '#' + colorLink}">
    <div class="interface-top-panel__addresses">
      <div class="interface-top-panel__label">{{ t(`${baseT}top_panel_address`) }}</div>
      <div class="interface-top-panel__address">
        <span>7R8W397MSbhc9UyA3ta9Cv...</span>
        <img :src="arrowAddress" alt="Arrow address">
      </div>
    </div>
    <div class="interface-top-panel__network">
      <div class="interface-top-panel__label">{{ t(`${baseT}top_panel_network`) }}</div>
      <div class="interface-top-panel__mode">mainnet</div>
    </div>
  </div>
</template>

<script setup>
import arrowAddress from '@/assets/arrow-address.svg'
import {inject} from 'vue'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
const baseT = inject('baseT')

const colorLink = inject('colorLink')
</script>

<style lang="scss" scoped>
.interface-top-panel {
  display: flex;
  gap: 20px;
  background-color: white;
  padding: 9px 24px;
  border-radius: 0 7px 0 0;

  &__label {
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.01em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #787878;
    margin-bottom: 4px;
  }

  &__address {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    line-height: 16px;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  &__network {
    padding-left: 20px;
    border-left: 1px solid #EAEAEA;
  }

  &__mode {
    font-size: 14px;
    line-height: 16px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: var(--color-link);
  }
}
</style>

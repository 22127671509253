<template>
  <div class="faq-item" :class="{'faq-item_active': active}">
    <div class="faq-item__question" @click="toggleActive">
      <slot name="question"/>
    </div>
    <div class="faq-item__answer">
      <span>
        <slot name="answer"/>
      </span>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue'

const active = ref(false)
const toggleActive = () => {
  active.value = !active.value
}
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.faq-item {
  border-top: 1px solid $border-color;
  padding: 10px 0;

  &:last-child {
    border-bottom: 1px solid $border-color;
  }

  &__question {
    position: relative;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    cursor: pointer;
    padding-left: 34px;

    &::before, &::after {
      position: absolute;
      top: 50%;
      margin-top: -1px;
      left: 0;
      content: '';
      width: 18px;
      height: 2px;
      background-color: $color-danger;
    }

    &::after {
      content: '';
      width: 2px;
      height: 18px;
      margin-top: -9px;
      left: 8px;
    }
  }

  &__answer {
    visibility: hidden;
    overflow: hidden;
    height: 0;
    opacity: 0;
    padding-left: 34px;
    transition: .5s;

    span {
      display: block;
      padding-top: 11px;
    }
  }

  &_active {
    .faq-item__question {
      &::after {
        content: none;
      }
    }

    .faq-item__answer {
      visibility: visible;
      height: 100%;
      opacity: 1;
    }
  }
}
</style>

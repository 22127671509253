<template>
  <div class="base-tag">
    <slot/>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.base-tag {
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  color: $color-danger;
  padding: 6px 10px;
  border: 1px solid $color-danger;
  border-radius: 50px;
}
</style>

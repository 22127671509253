<template>
  <article class="home-project-item">
    <header class="home-project-item__header">
      <base-tag>{{ t(`${baseT}tags.${data.tag}`) }}</base-tag>
      <a v-if="data.link" :href="data.link" target="_blank">
        <IconLink/>
      </a>
    </header>
    <div class="home-project-item__grid">
      <div class="home-project-item__content">
        <div class="home-project-item__name" v-html="t(`${baseT}${data.name}_name`)"/>
        <div class="home-project-item__desc">{{ t(`${baseT}${data.name}_desc`) }}</div>
      </div>
      <div class="home-project-item__image">
        <img :src="data.image" :alt="data.name">
      </div>
    </div>
  </article>
</template>

<script setup>
import {defineProps} from 'vue'
import IconLink from '@/components/Icon/IconLink'
import BaseTag from '@/components/Base/BaseTag'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
const baseT = 'home.projects.'

defineProps({
  data: {
    type: Object,
    required: true,
  },
})
</script>

<style lang="scss" scoped>
.home-project-item {
  position: relative;
  background: rgba(6, 13, 44, 0.5);
  border: 0.5px solid #EA4B5F;
  box-shadow: 0 5px 47px -19px rgba(0, 45, 141, 0.53);
  border-radius: 20px;
  padding: 28px 31px;
  height: 280px;
  user-select: none;

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: -10px;
    z-index: 1;
  }

  &__grid {
    display: grid;
    grid-template-columns: 60% 40%;
    align-items: flex-end;
    height: 192px;
  }

  &__name {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 12px;
    color: white;
  }

  &__desc {
    font-size: 16px;
    line-height: 19px;
    color: #ADB0BC;
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    img {
      position: absolute;
    }
  }
}
</style>

<template>
  <svg :width="width" :height="height" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.1865" cy="12.4131" r="11.4" :stroke="color" stroke-width="1.2"/>
    <path
        d="M17.6251 16.4953L16.1467 17.9401L12.0475 13.8745L7.98191 17.9401L6.53711 16.4617L10.6027 12.4297L6.57071 8.36414L8.04911 6.91934L12.0475 10.9513L16.1131 6.88574L17.5579 8.36414L13.5259 12.4297L17.6251 16.4953Z"
        :fill="color"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },
}
</script>

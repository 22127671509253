<template>
  <div class="constructor-seed">
    <div class="constructor-seed__wrapper">
      <div class="constructor-seed__info">
        <h3 class="constructor-seed__title">
          {{ t(`${baseT}seed.label`) }}
          <base-tooltip>{{ t(`${baseT}seed.info`) }}</base-tooltip>
        </h3>
        <div class="constructor-seed__value">
          <span>{{ seed }}</span>
          <BaseDownload name="seed.txt" :content="seed"/>
        </div>
      </div>
      <div class="constructor-seed__warning">
        <div class="constructor-seed__warning-title">{{ t(`${baseT}seed.warning_title`) }}</div>
        <span>{{ t(`${baseT}seed.warning_desc`) }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, inject} from 'vue'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import BaseTooltip from '@/components/Base/BaseTooltip'
import BaseDownload from '@/components/Base/BaseDownload'

const {t} = useI18n()
const baseT = inject('baseT')

const store = useStore()
const seed = computed(() => store.state.general.seed)
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

$background-gradient: linear-gradient(90deg, rgb(24, 175, 242) 0%, rgb(95, 82, 216) 27%, rgb(170, 66, 185) 53%, rgb(244, 77, 97) 75%, rgb(254, 116, 67) 100%), #FFFFFF;

.constructor-seed {
  position: relative;
  border-radius: 14px;
  background: $background-gradient;

  &::after {
    position: absolute;
    inset: 1px;
    content: '';
    background-color: rgba(255, 255, 255, .975);
    border-radius: 13px;
  }

  &__wrapper {
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr 38%;
    gap: 24px;
    padding: 24px;
  }

  &__value {
    display: grid;
    grid-template-columns: 1fr 20px;
    max-width: 100%;
    align-items: center;
    gap: 10px;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__title {
    display: flex;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  &__warning {
    border-left: 1px solid $color-danger;
    padding-left: 12px;
    font-size: 16px;
    line-height: 19px;
    font-feature-settings: 'pnum' on, 'lnum' on;

    &-title {
      font-weight: 500;
      margin-bottom: 5px;
    }
  }
}

@media (max-width: 1199px) {
  .constructor-seed {
    &__wrapper {
      grid-template-columns: 1fr;
    }

    &__warning {
      border-left: none;
      padding-left: 0;
      border-top: 1px solid $color-danger;
      padding-top: 12px;
    }
  }
}
</style>

<template>
  <div class="home-capability-block">
    <div class="row">
      <div class="col-md-4">
        <header class="home-capability-block__header">
          <h3 class="home-capability-block__title">
            <slot name="title"/>
          </h3>
        </header>
        <div class="home-capability-block__info">
          <slot name="info"/>
        </div>
        <slot name="after"/>
      </div>
      <div class="col-md-8 col-lg-7 offset-lg-1 d-none d-md-block">
        <div class="home-capability-block__image">
          <slot name="image"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../../../scss/variables";

.home-capability-block {
  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin: 0;
  }

  &__image {
    @media (max-width: 1199px) {
      img {
        max-width: 100%;
      }
    }
  }

  &__info {
    margin-top: 28px;

    p {
      margin: $row 0;

      b {
        font-weight: 500;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      margin: 0;
      padding-left: 20px;
      list-style: none;

      li {
        position: relative;
        margin-bottom: 10px;

        &:before {
          position: absolute;
          left: -15px;
          content: '•';
        }
      }
    }
  }
}
</style>

<template>
  <div class="contacts-info">
    <div class="contacts-info__wrapper">
      <header class="contacts-info__header">
        <base-title type="h2">{{ t(`${baseT}title`) }}</base-title>
      </header>
      <ContactsInfoExpert class="contacts-info__expert"/>
      <ContactsInfoBusiness/>
    </div>
  </div>
</template>

<script setup>
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'
import {provide} from 'vue'
import ContactsInfoExpert from '@/pages/Contacts/components/ContactsInfoExpert'
import ContactsInfoBusiness from '@/pages/Contacts/components/ContactsInfoBusiness'

const {t} = useI18n()
const baseT = 'contacts.info.'

provide('baseT', baseT)
</script>

<style lang="scss" scoped>
.contacts-info {
  &__expert {
    margin-top: 57px;
    margin-bottom: 72px;
  }
}

@media (max-width: 575px) {
  .contacts-info {
    &__expert {
      margin-top: 32px;
      margin-bottom: 47px;
    }
  }
}
</style>

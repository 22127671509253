<template>
  <div class="constructor-node-min">
    <base-tooltip class="constructor-node-min__info">{{ t(`${baseT}info`) }}</base-tooltip>
    <div class="constructor-node-min__value" v-html="t(`${baseT}value`, {count:countNode})"></div>
    <div class="constructor-node-min__desc">{{ t(`${baseT}desc`) }}</div>
  </div>
</template>

<script setup>
import BaseTooltip from '@/components/Base/BaseTooltip'
import {useI18n} from 'vue-i18n'
import {inject} from 'vue'

const {t} = useI18n()
const baseT = inject('baseT') + 'node.min_'

const countNode = 4
</script>

<style lang="scss">
@import "~@/scss/variables.scss";

.constructor-node-min {
  position: relative;
  background: white;
  box-shadow: 0 4.60703px 30.9329px -5.26518px rgba(45, 74, 136, 0.16);
  border-radius: 29px;
  padding: 32px 36px 36px;

  &__info {
    position: absolute;
    top: 19px;
    right: 19px;
  }

  &__value {
    font-weight: 600;
    font-size: 42px;
    line-height: 49px;
    font-feature-settings: 'pnum' on, 'lnum' on;

    big {
      font-size: 72px;
    }
  }

  &__desc {
    margin-top: 7px;
    color: $border-color;
  }
}
</style>

<template>
  <base-modal class="base-modal-message" @close="close">
    <div class="base-modal-message__close" @click="close"/>
    <div class="base-modal-message__content">
      <slot/>
    </div>
  </base-modal>
</template>

<script setup>
import BaseModal from '@/components/Base/Modal/BaseModal'
import {defineEmits} from 'vue'

const emit = defineEmits(['close'])
const close = () => emit('close')
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.base-modal-message {
  &__close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 18px;
    right: 18px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: #0D0D0D;
    cursor: pointer;
    transition: $transition-default;

    &:hover {
      background-color: $color-danger;
    }

    &:after, &:before {
      position: absolute;
      content: '';
      width: 10px;
      height: 2px;
      background-color: white;
    }

    &:after {
      transform: rotate(45deg);
    }

    &:before {
      transform: rotate(-45deg);
    }
  }
}
</style>

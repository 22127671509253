<template>
  <div class="cart-total">
    <div class="cart-total__item">
      <div class="cart-total__label">{{ t(`${baseT}total`) }}</div>
      <div class="cart-total__value">{{ totalPrice }}</div>
    </div>
    <div class="cart-total__item">
      <div class="cart-total__label">{{ t(`${baseT}vat`) }}</div>
      <div class="cart-total__value">{{ vatPrice }}</div>
    </div>
    <div class="cart-total__item">
      <div class="cart-total__label">{{ t(`${baseT}total_and_vat`) }}</div>
      <div class="cart-total__value">{{ totalAndVatPrice }}</div>
    </div>
  </div>
</template>

<script setup>
import {numberToCurrency} from '@/helpers'
import {useStore} from 'vuex'
import {computed} from 'vue'
import {useI18n} from 'vue-i18n'

const {t, locale} = useI18n()
const baseT = 'checkout.info.'

const store = useStore()
const totalPrice = computed(() => numberToCurrency(store.getters['cart/total'], locale.value))
const vatPrice = computed(() => numberToCurrency(store.getters['cart/vat'], locale.value))
const totalAndVatPrice = computed(() => numberToCurrency(store.getters['cart/totalAndVat'], locale.value))
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.cart-total {
  &__item {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    font-feature-settings: 'pnum' on, 'lnum' on;
    border-bottom: 1px solid $color-text;

    &:last-child {
      font-weight: 600;
      border-bottom: none;
    }
  }
}
</style>

<template>
  <base-form-field class="base-form-field-info" :class="{'base-form-field-info_error': error && saveForm}">
    <div class="base-form-field-info__text">
      <slot/>
    </div>
  </base-form-field>
</template>

<script setup>
import BaseFormField from './BaseFormField.vue'
import {inject, defineProps} from 'vue'

defineProps({
  error: {
    type: Boolean,
    default: false,
  },
})
const saveForm = inject('saveForm')
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.base-form-field-info {
  border-bottom: 1px solid $border-color;

  &_error {
    border-bottom: 1px solid $color-danger;
  }

  &__text {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.02em;
    padding-bottom: 8px;
  }
}
</style>

<template>
  <base-form-block class="constructor-protocol">
    <template #title>{{ t(`${baseT}protocol.title`) }}</template>
    <template #content>
      <div class="constructor-protocol__grid">
        <base-form-field>
          <template #label>{{ t(`${baseT}protocol.interval`) }}</template>
          <RangeBlockInterval v-model="blockInterval"/>
        </base-form-field>
        <base-form-field>
          <template #label>{{ t(`${baseT}protocol.size`) }}</template>
          <RangeBlockSize v-model="blockSize"/>
        </base-form-field>
        <base-form-field>
          <template #label>{{ t(`${baseT}protocol.performance`) }}</template>
          <div class="constructor-protocol__field-info">
            {{ performance }}
          </div>
        </base-form-field>
        <base-form-field>
          <template #label>
            <span>{{ t(`${baseT}protocol.tx_count`) }}</span>
            <base-tooltip>{{ t(`${baseT}protocol.tx_count_info`) }}</base-tooltip>
          </template>
          <div class="constructor-protocol__field-info">
            {{ txInDay }}
          </div>
        </base-form-field>
      </div>
    </template>
  </base-form-block>
</template>

<script setup>
import {computed, inject} from 'vue'
import {useI18n} from 'vue-i18n'
import {useStore} from 'vuex'
import BaseFormBlock from '@/components/Base/BaseFormBlock.vue'
import BaseFormField from '@/components/Base/BaseFormField.vue'
import RangeBlockInterval from '@/components/Form/Range/RangeBlockInterval.vue'
import RangeBlockSize from '@/components/Form/Range/RangeBlockSize.vue'
import BaseTooltip from '@/components/Base/BaseTooltip'
import useCheckout from '@/use/checkout'

const {t} = useI18n()
const baseT = inject('baseT')

const {addSetGetField} = useCheckout()

const base = 'protocol'
const action = 'UPDATE_PROTOCOL'
const blockInterval = computed(addSetGetField({base, action, field: 'blockInterval'}))
const blockSize = computed(addSetGetField({base, action, field: 'blockSize'}))

const store = useStore()
const performance = computed(() => {
  return store.getters['protocol/performance'] + ' ' + t(`${baseT}protocol.performance_postfix`)
})
const txInDay = computed(() => {
  return store.getters['protocol/txInDay'] + ' ' + t(`${baseT}protocol.tx_count_postfix`)
})
</script>

<style lang="scss" scoped>
.constructor-protocol {
  &__grid {
    display: grid;
    grid-template-columns: 3fr 3fr 2fr 2fr;
    gap: 32px;
  }

  &__field-info {
    padding: 0 11px;
    line-height: 37px;
    background: #F5F5FB;
    border-radius: 8px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-weight: 500;
  }
}

@media (max-width: 991px) {
  .constructor-protocol__grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .constructor-protocol__grid {
    grid-template-columns: 1fr;
  }
}
</style>

<template>
  <section class="constructor-promo">
    <div class="container">
      <div class="row">
        <header class="constructor-promo__header col-lg-6">
          <base-title>{{ t(`${baseT}title`) }}</base-title>
          <div class="constructor-promo__desc">{{ t(`${baseT}desc`) }}</div>
          <base-button v-scroll-to="'#step-one'">{{ t(`${baseT}button`) }}</base-button>
        </header>
        <div class="constructor-promo__image col-lg-6">
          <img :src="promoImage" alt="Promo">
          <img :src="promoImageShadowOne" alt="Promo shadow" class="constructor-promo__image-shadow-one">
          <img :src="promoImageShadowTwo" alt="Promo shadow" class="constructor-promo__image-shadow-two">
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import {useI18n} from 'vue-i18n'
import BaseButton from '@/components/Base/BaseButton.vue'
import promoImage from '@/assets/configurator-promo.png'
import promoImageShadowOne from '@/assets/configurator-promo-shadow-1.png'
import promoImageShadowTwo from '@/assets/configurator-promo-shadow-2.png'
import BaseTitle from '@/components/Base/BaseTitle'

const {t} = useI18n()
const baseT = 'constructor.promo.'
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.constructor-promo {
  &__header {
    margin-top: 130px;

    @media all and (max-width: 767px) {
      margin-top: 33px;
    }
  }

  &__desc {
    margin: 30px 0 40px;
    font-size: 24px;
    line-height: 32px;
    color: #060606;
  }

  .base-button {
    padding: 0 48px;
  }

  &__image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    &-shadow-one, &-shadow-two {
      position: absolute;
      z-index: -1;
    }

    &-shadow-one {
      left: -10%;
      bottom: 5%;
    }

    &-shadow-two {
      left: -17%;
      top: 25%;
    }

    @media (max-width: 991px) {
      text-align: center;

      img {
        max-width: 100%;
      }
    }

    @media all and (max-width: 767px) {
      margin-top: 96px;

      img {
        max-width: 130%;
      }
    }
  }
}

@media (max-width: 991px) {
  .constructor-promo {
    overflow: visible;
  }
}
</style>

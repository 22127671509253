<template>
  <constructor-deployment-option class="constructor-deployment-self">
    <template #icon>
      <IconDeploymentSelf/>
    </template>
    <template #title>{{ t(`${baseT}tab`) }}</template>
    <template #desc>{{ t(`${baseT}desc`) }}</template>
    <template #default>
      <div class="constructor-deployment-self__guide">
        <div class="constructor-deployment-self__guide-list">
          <a
              v-for="{name, component, componentHover, link} in guides" :key="name"
              class="constructor-deployment-self__guide-item"
              :href="link"
              target="_blank"
          >
            <component :is="component" class="constructor-deployment-self__guide-icon"/>
            <component :is="componentHover" class="constructor-deployment-self__guide-icon_hover"/>
            <span>{{ t(`${baseT}guide_${name}`) }}</span>
          </a>
        </div>
      </div>
    </template>
  </constructor-deployment-option>
</template>

<script setup>
import {useI18n} from 'vue-i18n'
import {defineProps, provide, toRefs} from 'vue'
import ConstructorDeploymentOption from '@/pages/Constructor/components/ConstructorDeploymentOption'
import IconVideo from '@/components/Icon/IconVideo'
import IconVideoHover from '@/components/Icon/IconVideoHover'
import IconPdf from '@/components/Icon/IconPdf'
import IconPdfHover from '@/components/Icon/IconPdfHover'
import IconGit from '@/components/Icon/IconGit'
import IconGitHover from '@/components/Icon/IconGitHover'
import IconDeploymentSelf from '@/components/Icon/IconDeploymentSelf'
import config from '@/config'

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
})

const {active} = toRefs(props)
provide('active', active)

const {t, locale} = useI18n()
const baseT = 'constructor.steps.three.deployment.self.'

const guides = [
  {
    name: 'video',
    component: IconVideo,
    componentHover: IconVideoHover,
    link: 'https://www.youtube.com/watch?v=2dtD4WyLbJM',
  },
  {
    name: 'pdf',
    component: IconPdf,
    componentHover: IconPdfHover,
    link: `${config.baseURL}/assets/Erachain_Linux_instruction${locale.value === 'ru' ? '-ru' : ''}.pdf`,
  },
  {
    name: 'git',
    component: IconGit,
    componentHover: IconGitHover,
    link: 'https://github.com/erachain/Erachain',
  },
]
</script>

<style lang="scss">
@import "~@/scss/variables.scss";

.constructor-deployment-self {
  .constructor-deployment-option__header {
    margin-bottom: 56px;
  }

  &__guide {
    &-list {
      display: flex;
      gap: 58px;
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 14px;
      text-decoration: none;
      text-align: center;
      font-size: 16px;
      line-height: 19px;

      span {
        margin-top: auto;
        transition: .5s;
      }

      &:hover {
        .constructor-deployment-self__guide-icon {
          display: none;

          &_hover {
            display: block;
          }
        }
      }
    }

    &-icon {
      &_hover {
        display: none;
      }
    }
  }

  &.constructor-deployment-option {
    &_active {
      border: 1px solid $color-primary;

      .constructor-deployment-option__icon {
        border: 1px solid transparent;
        color: white;
        background: radial-gradient(50% 50% at 50% 50%, #2719A0 0%, #7D71E4 100%);
        box-shadow: inset 0 0 9.63972px rgba(255, 255, 255, 0.25);
      }

      .constructor-deployment-self__guide-icon {
        color: $color-danger;
      }
    }
  }
}

@media (max-width: 575px) {
  .constructor-deployment-self__guide-list {
    flex-direction: column;
    gap: 40px;
  }
}
</style>

<template>
  <div class="technology-access-control">
    <div class="container" id="tech-4">
      <div class="row">
        <div class="col-lg-5">
          <header class="technology-access-control__header">
            <base-title type="h3">{{ t(`${baseT}title`) }}</base-title>
          </header>
        </div>
      </div>
      <div class="row gy-4">
        <div class="col-md-5">
          <div class="technology-access-control__info">
            <div class="technology-access-control__desc">{{ t(`${baseT}desc`) }}</div>
          </div>
        </div>
        <div class="col-md-6 offset-md-1">
          <ol class="technology-access-control__list">
            <li>
              <strong>{{ t(`${baseT}item_1_name`) }}</strong>
              <p>{{ t(`${baseT}item_1_desc`) }}</p>
            </li>
          </ol>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 order-last order-md-first">
          <div class="technology-access-control__info">
            <home-block-darken>
              <template #title>{{ t(`${baseT}auto.title`) }}</template>
              <template #desc>{{ t(`${baseT}auto.desc`, {email}) }}</template>
            </home-block-darken>
          </div>
        </div>
        <div class="col-md-6 offset-md-1">
          <ol class="technology-access-control__list" start="2">
            <li>
              <strong>{{ t(`${baseT}item_2_name`) }}</strong>
              <p>{{ t(`${baseT}item_2_desc`) }}</p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'
import HomeBlockDarken from '@/pages/Home/components/HomeBlockDarken'

const {t} = useI18n()
const baseT = 'home.access_control.'
const email = 'info@erachain.org'
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.technology-access-control {
  &__header {
    margin-bottom: $row * 1.5;
  }

  &__desc {
    font-size: 24px;
    line-height: 34px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__list {
    font-size: 24px;
    line-height: 34px;
    color: #E9E9E9;
    font-weight: 300;

    strong {
      color: #42B0F5;
      font-weight: 500;
    }
  }
}
</style>

<template>
  <div class="technology-verification">
    <div class="container">
      <div class="row gy-4">
        <div class="col-md-5">
          <header class="technology-verification__header">
            <base-title type="h3">{{ t(`${baseT}title`) }}</base-title>
          </header>
          <div class="technology-verification__desc">
            <p>{{ t(`${baseT}desc_1`) }}</p>
            <p>{{ t(`${baseT}desc_2`) }}</p>
            <p>{{ t(`${baseT}desc_3`) }}</p>
          </div>
          <home-block-darken class="technology-verification__footer">
            <template #desc>{{ t(`${baseT}footer`) }}</template>
          </home-block-darken>
        </div>
        <div class="technology-verification__image col-md-6 offset-md-1">
          <img :src="imageVerification" alt="verification">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useI18n} from 'vue-i18n'
import imageVerification from '@/assets/verification.png'
import BaseTitle from '@/components/Base/BaseTitle'
import HomeBlockDarken from '@/pages/Home/components/HomeBlockDarken'

const {t} = useI18n()
const baseT = 'home.verification.'
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.technology-verification {
  &__header {
    padding-top: $row * 1.5;
  }

  &__desc {
    margin-top: $row * 1.5;
    font-size: 24px;
    line-height: 34px;
  }

  &__image {
    text-align: right;

    img {
      margin-right: -9%;
      max-width: 100%;
      height: auto;
    }
  }

  &__footer {
    margin-top: 56px;
  }
}

@media (max-width: 767px) {
  .technology-verification__image {
    text-align: center;

    img {
      max-width: 40%;
    }
  }
}
</style>

<template>
  <svg :width="width" :height="height" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 6.16077L11.1773 6.16077V11.5886H7.78484M1 6.16077L5.07091 10.9102M1 6.16077L5.07091 1.41138"
          :stroke="color" stroke-width="1.35697"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 12,
    },
    height: {
      type: Number,
      default: 13,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },
}
</script>

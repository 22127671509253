<template>
  <div class="home-block-darken">
    <div class="home-block-darken__title">
      <slot name="title"/>
    </div>
    <div class="home-block-darken__desc">
      <slot name="desc"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.home-block-darken {
  padding: 20px 30px;
  background: #161A2B;
  border: 2px solid #212536;
  border-radius: 26px;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  color: #EDF1FF;

  &__title {
    font-weight: 500;
  }

  &__desc {
    color: #C2C8D1;
  }
}
</style>

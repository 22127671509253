import http from '@/http'

const headers = {
  'Content-Type': 'multipart/form-data',
}

const sendEmail = (method, formData) => {
  return http.post(method, formData, {headers})
      .then(response => response?.data === 'ok')
}

export const sendEmailContact = formData => {
  return sendEmail('/form-contacts', formData)
}

export const sendEmailFaq = formData => {
  return sendEmail('/form-faq', formData)
}

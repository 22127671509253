<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M14.9976 1.60689C13.047 0.314858 10.6937 -0.225609 8.37463 0.0861708C6.05582 0.397928 3.92874 1.54038 2.38838 3.30197C0.848294 5.06334 -0.000445065 7.32372 1.75087e-07 9.66334H1.87012C1.87034 7.7787 2.55362 5.95794 3.7935 4.53852C5.03333 3.11887 6.74561 2.19711 8.61312 1.94338C10.4806 1.68967 12.3768 2.12143 13.9503 3.15889C15.91 4.46266 17.182 6.57644 17.4161 8.91862C17.614 10.9544 17.0033 12.9862 15.716 14.5754C14.4288 16.165 12.5682 17.1845 10.5358 17.4143C8.50348 17.6438 6.46233 17.0653 4.85284 15.8031L11.2203 9.4356V13.0916H13.0905V7.16994C13.0905 6.92211 12.9918 6.68426 12.8165 6.50882C12.6411 6.33359 12.4032 6.23489 12.1554 6.23489H6.2337V8.10501H9.89909L2.18196 15.8405L2.84264 16.5012C4.11987 17.7786 5.72952 18.6726 7.48917 19.0813C9.24881 19.49 11.0878 19.3972 12.7973 18.8135C14.5069 18.2297 16.0185 17.1784 17.1607 15.7788C18.3028 14.3793 19.0297 12.6875 19.2589 10.8957C19.488 9.10388 19.2102 7.28357 18.4569 5.64152C17.7038 3.9997 16.5053 2.60181 14.9976 1.60653L14.9976 1.60689Z"
        :fill="color"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 20,
    },
    height: {
      type: [Number, String],
      default: 20,
    },
    color: {
      type: String,
      default: '#EA4B5F',
    },
  },
}
</script>

<template>
  <home-capability-block class="home-capability-block-branding">
    <template #title>{{ t(`${baseT}title`) }}</template>
    <template #info>
      <ul>
        <li>{{ t(`${baseT}li_1`) }}</li>
        <li>{{ t(`${baseT}li_2`) }}</li>
        <li>{{ t(`${baseT}li_3`) }}</li>
      </ul>
    </template>
    <template #image>
      <div class="home-capability-block-branding__image">
        <div class="home-capability-block-branding__image-wrap">
          <img :src="capabilityBranding" alt="capability blockchain">
          <img
              class="home-capability-block-branding__image-shadow"
              :src="capabilityShadow"
              alt="capability blockchain shadow"
          >
        </div>
      </div>
    </template>
  </home-capability-block>
</template>

<script setup>
import capabilityBranding from '@/assets/capability-branding.png'
import capabilityShadow from '@/assets/capability-shadow.png'
import HomeCapabilityBlock from '@/pages/Home/components/HomeCapabilityBlock'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
const baseT = 'home.capability.branding.'
</script>

<style lang="scss">
.home-capability-block-branding {
  padding-bottom: 200px;

  &__image {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &-wrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 0;
    }

    &-shadow {
      position: absolute;
      width: 192.84%;
      margin-right: -40%;
      margin-bottom: -30%;
      height: auto;
      margin-left: -10%;
      z-index: -1;
    }
  }
}
</style>

<template>
  <div class="constructor-deployment-options">
    <header class="constructor-deployment-options__header">
      <div class="constructor-deployment-options__title">{{ t(`${baseT}title`) }}</div>
    </header>
    <BaseTabs
        class="constructor-deployment-options__tabs"
        :tabs="tabs"
        v-model:activeTab="activeTab"
    />
    <div class="constructor-deployment-options__wrapper">
      <div class="row gy-4">
        <div class="col-lg-6 d-lg-block" :class="{'d-none': activeTab !== 'self'}">
          <ConstructorDeploymentSelf :active="activeTab === 'self'"/>
        </div>
        <div class="col-lg-6 d-lg-block" :class="{'d-none': activeTab !== 'professional'}">
          <ConstructorDeploymentProfessional :active="activeTab === 'professional'"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useI18n} from 'vue-i18n'
import {computed, inject} from 'vue'
import BaseTabs from '@/components/Base/BaseTabs'
import ConstructorDeploymentSelf from '@/pages/Constructor/components/ConstructorDeploymentSelf'
import ConstructorDeploymentProfessional from '@/pages/Constructor/components/ConstructorDeploymentProfessional'
import {useStore} from 'vuex'

const {t} = useI18n()
const baseT = inject('baseT') + 'deployment.'

const tabs = computed(() => {
  return [
    {name: 'self', label: t(`${baseT}self.tab`)},
    {name: 'professional', label: t(`${baseT}professional.tab`)},
  ]
})

const store = useStore()
const activeTab = computed({
  get: () => store.state.general.installation,
  set: value => store.dispatch('general/updateInstallation', value),
})
</script>

<style lang="scss" scoped>
.constructor-deployment-options {
  &__title {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }

  &__tabs {
    margin: 32px 0;
  }
}
</style>

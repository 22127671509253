<template>
  <base-form-block class="constructor-stake">
    <template #title>
      <span>{{ t(`${baseT}stake.title`) }}</span>
      <base-tooltip>
        <p>{{ t(`${baseT}stake.info_1`) }}</p>
        {{ t(`${baseT}stake.info_2`) }}
      </base-tooltip>
      <BaseRequired v-if="errorRequired" multi/>
    </template>
    <template #content>
      <div class="constructor-stake__grid">
        <base-form-field>
          <template #label>
            <span>{{ t(`${baseT}stake.min_stake`) }}</span>
            <base-tooltip>
              <p>{{ t(`${baseT}stake.min_stake_info_1`) }}</p>
              {{ t(`${baseT}stake.min_stake_info_2`) }}
            </base-tooltip>
          </template>
          <RangeMinStake v-model="minCount"/>
        </base-form-field>
        <base-form-field>
          <template #label>
            <span>{{ t(`${baseT}stake.apy_percent_by_year`) }}</span>
            <base-tooltip>
              <p>{{ t(`${baseT}stake.apy_percent_by_year_info_1`) }}</p>
              {{ t(`${baseT}stake.apy_percent_by_year_info_2`) }}
            </base-tooltip>
          </template>
          <div class="constructor-stake__flex">
            <BaseInput
                v-model="APYByYear"
                placeholder="0%"
                postfix="%"
                type="number"
                required
            />
            <span>=</span>
            <BaseInput
                :model-value="APYByYear && investCount ? APYByYearView : null"
                :placeholder="APYByYearView"
                disabled
            />
          </div>
        </base-form-field>
        <base-form-field>
          <template #label>
            <span>{{ t(`${baseT}stake.gas_by_year`) }}</span>
            <base-tooltip>
              <p>{{ t(`${baseT}stake.gas_by_year_info_1`) }}</p>
              {{ t(`${baseT}stake.gas_by_year_info_2`) }}
            </base-tooltip>
          </template>
          <div class="constructor-stake__flex">
            <BaseInput
                v-model="fuelTokenByYear"
                type="number"
                placeholder="0"
                required
            />
            <span>{{ t(`${baseT}stake.na`) }}</span>
            <BaseInput
                :model-value="fuelTokenByYear ? enoughTx : null"
                :placeholder="enoughTx"
                disabled
            />
          </div>
        </base-form-field>
      </div>
    </template>
  </base-form-block>
</template>

<script setup>
import {inject} from 'vue'
import {useI18n} from 'vue-i18n'
import BaseFormBlock from '../../../components/Base/BaseFormBlock.vue'
import BaseInput from '../../../components/Base/BaseInput.vue'
import BaseFormField from '../../../components/Base/BaseFormField.vue'
import BaseRequired from '@/components/Base/BaseRequired'
import {computed} from 'vue'
import useCheckout from '@/use/checkout'
import BaseTooltip from '@/components/Base/BaseTooltip'
import RangeMinStake from '@/components/Form/Range/RangeMinStake'
import {calcEnoughTx} from '@/helpers/constructor'
import {useStore} from 'vuex'
import {numberFormat} from '@/helpers'

const {t} = useI18n()
const baseT = inject('baseT')
const {addSetGetField} = useCheckout()

const base = 'stake'
const action = 'UPDATE_STAKE'
const minCount = computed(addSetGetField({base, action, field: 'minCount'}))
const APYByYear = computed(addSetGetField({base, action, field: 'APYByYear'}))
const fuelTokenByYear = computed(addSetGetField({base, action, field: 'fuelTokenByYear'}))
const store = useStore()

const investCount = computed(() => store.state.emission.investTokenCount)

const APYByYearView = computed(() => {
  const investName = store.state.emission.investTokenName || t(`${baseT}emission.invest_name_placeholder`)
  const APYByYearToken = numberFormat(APYByYear.value / 100 * investCount.value)
  return `${APYByYearToken} ${investName}`
})

const enoughTx = computed(() => {
  return t(`${baseT}emission.enough_value`, {
    size: calcEnoughTx(fuelTokenByYear.value),
  })
})

const saveForm = inject('saveForm')
const errorRequired = computed(() => saveForm.value && (!APYByYear.value || !fuelTokenByYear.value))
</script>

<style lang="scss">
.constructor-stake {
  &__grid {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;

    .base-form-field:not(:first-child) {
      .base-form-field__label {
        margin-bottom: 21px;
      }
    }
  }

  &__flex {
    display: flex;
    align-items: center;
    gap: 12px;

    & > *:first-child {
      min-width: 100px !important;
      width: 100px;
    }
  }
}

@media (max-width: 767px) {
  .constructor-stake__grid {
    grid-template-columns: 1fr;
  }
}
</style>

<template>
  <base-form-block class="constructor-emission">
    <template #title>
      <span>{{ t(`${baseT}emission.title`) }}</span>
      <base-tooltip>{{ t(`${baseT}emission.info`) }}</base-tooltip>
      <BaseRequired v-if="errorRequired" multi/>
    </template>
    <template #content>
      <div class="constructor-emission__grid">
        <base-form-field>
          <template #label>
            <span>{{ t(`${baseT}emission.invest_name`) }}</span>
            <base-tooltip>
              <p>{{ t(`${baseT}emission.invest_info_1`) }}</p>
              {{ t(`${baseT}emission.invest_info_2`) }}
            </base-tooltip>
          </template>
          <BaseInput
              v-model="investTokenName"
              :placeholder="t(`${baseT}emission.invest_name_placeholder`)"
              required
          />
        </base-form-field>
        <base-form-field>
          <template #label>{{ t(`${baseT}emission.count`) }}</template>
          <BaseInput
              v-model="investTokenCount"
              type="number"
              placeholder="0"
              required
          />
        </base-form-field>
        <div class="constructor-emission__upload">
          <base-form-field-info :error="!investTokenImage">
            {{ t(`${baseT}emission.icon`) }}
          </base-form-field-info>
          <BaseUploadImage v-model:uploadImage="investTokenImage" required/>
        </div>
      </div>
      <div class="constructor-emission__grid">
        <base-form-field>
          <template #label>
            <span>{{ t(`${baseT}emission.fuel_name`) }}</span>
            <base-tooltip>
              <p>{{ t(`${baseT}emission.fuel_info_1`) }}</p>
              {{ t(`${baseT}emission.fuel_info_2`) }}
            </base-tooltip>
          </template>
          <BaseInput
              v-model="fuelTokenName"
              :placeholder="t(`${baseT}emission.fuel_name_placeholder`)"
              required
          />
        </base-form-field>
        <div class="constructor-emission__gas">
          <base-form-field>
            <template #label>{{ t(`${baseT}emission.count`) }}</template>
            <BaseInput
                v-model="fuelTokenCount"
                type="number"
                placeholder="0"
                required
            />
          </base-form-field>
          <base-form-field>
            <template #label>{{ t(`${baseT}emission.enough`) }}</template>
            <BaseInput :model-value="enoughTx" disabled/>
          </base-form-field>
        </div>
        <div class="constructor-emission__upload">
          <base-form-field-info :error="!fuelTokenImage">
            {{ t(`${baseT}emission.icon`) }}
          </base-form-field-info>
          <BaseUploadImage v-model:uploadImage="fuelTokenImage" required/>
        </div>
      </div>
    </template>
  </base-form-block>
</template>

<script setup>
import {inject, computed} from 'vue'
import BaseFormBlock from '../../../components/Base/BaseFormBlock.vue'
import BaseInput from '../../../components/Base/BaseInput.vue'
import BaseFormField from '../../../components/Base/BaseFormField.vue'
import BaseUploadImage from '../../../components/Base/BaseUploadImage.vue'
import BaseFormFieldInfo from '../../../components/Base/BaseFormFieldInfo.vue'
import BaseTooltip from '@/components/Base/BaseTooltip'
import {useI18n} from 'vue-i18n'
import BaseRequired from '@/components/Base/BaseRequired'
import useCheckout from '@/use/checkout'
import {calcEnoughTx} from '@/helpers/constructor'

const {t} = useI18n()
const baseT = inject('baseT')
const {addSetGetField} = useCheckout()

const base = 'emission'
const action = 'UPDATE_EMISSION'
const investTokenName = computed(addSetGetField({base, action, field: 'investTokenName'}))
const investTokenCount = computed(addSetGetField({base, action, field: 'investTokenCount'}))
const investTokenImage = computed(addSetGetField({base, action, field: 'investTokenImage'}))
const fuelTokenName = computed(addSetGetField({base, action, field: 'fuelTokenName'}))
const fuelTokenCount = computed(addSetGetField({base, action, field: 'fuelTokenCount'}))
const fuelTokenImage = computed(addSetGetField({base, action, field: 'fuelTokenImage'}))
const enoughTx = computed(() => {
  return t(`${baseT}emission.enough_value`, {
    size: calcEnoughTx(fuelTokenCount.value),
  })
})

const saveForm = inject('saveForm')
const errorRequired = computed(() => {
  return saveForm.value && (
      !investTokenName.value
      || !investTokenImage.value
      || !investTokenCount.value
      || !fuelTokenName.value
      || !fuelTokenImage.value
      || !fuelTokenCount.value
  )
})
</script>

<style lang="scss" scoped>
.constructor-emission {
  &__grid {
    display: grid;
    align-items: flex-end;
    grid-template-columns: 3fr 4fr 3fr;
    gap: 32px;

    &:last-child {
      margin-top: 32px;
    }
  }

  &__upload {
    display: flex;
    gap: 18px;

    & > *:first-child {
      flex: 1;
    }
  }

  &__gas {
    display: grid;
    grid-template-columns: 1fr 1.8fr;
    gap: 32px;
  }
}

@media (max-width: 991px) {
  .constructor-emission__grid {
    grid-template-columns: 1fr 1fr;

    &:last-child {
      margin-top: 60px;
    }
  }
}

@media (max-width: 767px) {
  .constructor-emission__grid {
    grid-template-columns: 1fr;
  }
}
</style>

<template>
  <base-form-block class="constructor-distribution">
    <template #title>
      <span>{{ t(`${baseT}distribution.title`) }}</span>
      <base-tooltip>{{ t(`${baseT}distribution.info`) }}</base-tooltip>
    </template>
    <template #content>
      <div class="constructor-distribution__settings">
        <div class="constructor-distribution__setting">
          <base-radio v-model="settingsType" :option="SETTINGS_DEFAULT">
            {{ t(`${baseT}distribution.setting_default_name`) }}
          </base-radio>
          <div class="constructor-distribution__setting-desc">
            {{ t(`${baseT}distribution.setting_default_desc`) }}
          </div>
        </div>
        <div class="constructor-distribution__setting">
          <base-radio v-model="settingsType" :option="SETTINGS_EXTENDED">
            {{ t(`${baseT}distribution.setting_extended_name`) }}
          </base-radio>
          <div class="constructor-distribution__setting-desc">
            {{ t(`${baseT}distribution.setting_extended_desc`) }}
          </div>
        </div>
      </div>
      <template v-if="settingsType === SETTINGS_EXTENDED">
        <div :class="['constructor-distribution__mode', {'constructor-distribution__mode_active':autoDistribution}]">
          <span>{{ t(`${baseT}distribution.auto`) }}</span>
          <BaseSwitch v-model="autoDistribution"/>
        </div>
        <div class="constructor-distribution__addresses">
          <div class="constructor-distribution__thead d-none d-md-grid">
            <div class="constructor-distribution__th">№</div>
            <div class="constructor-distribution__th">{{ t(`${baseT}distribution.th_address`) }}</div>
            <div class="constructor-distribution__th">{{ t(`${baseT}distribution.th_invest`) }}</div>
            <div class="constructor-distribution__th">{{ t(`${baseT}distribution.th_fuel`) }}</div>
          </div>
          <ConstructorFormDistributionAddress
              v-for="(address, idx) in addresses"
              :key="`${address.address}/${address.investCount}/${address.fuelCount}`"
              v-model:invest-count="address.investCount"
              v-model:fuel-count="address.fuelCount"
              :number="idx + 1"
              :address="address.address"
              :is-remove="idx > 19 && idx + 1 === addresses.length"
              :auto-distribution="autoDistribution"
              :error-invest-token="errorInvestToken"
              :error-fuel-token="errorFuelToken"
              @removeAddress="removeAddress"
          />
        </div>
        <div class="constructor-distribution__add">
          <img :src="addAddressIcon" alt="Add address" @click="addAddress">
        </div>
      </template>
      <base-alert-error v-if="errorInvestToken" class="mt-4">
        {{ t(`${baseT}distribution.error_invest`, {count: sumInvestToken - investCount}) }}
      </base-alert-error>
      <base-alert-error v-if="errorFuelToken" class="mt-4">
        {{ t(`${baseT}distribution.error_fuel`, {count: sumFuelToken - fuelCount}) }}
      </base-alert-error>
    </template>
  </base-form-block>
</template>

<script setup>
import {computed, inject, provide, ref, watch} from 'vue'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import BaseFormBlock from '@/components/Base/BaseFormBlock.vue'
import ConstructorFormDistributionAddress from './ConstructorFormDistributionAddress'
import addAddressIcon from '../../../assets/add-address.svg'
import BaseTooltip from '@/components/Base/BaseTooltip'
import BaseAlertError from '@/components/Base/Alert/BaseAlertError'
import BaseSwitch from '@/components/Base/BaseSwitch'
import useCheckout from '@/use/checkout'
import BaseRadio from '@/components/Base/BaseRadio'

const {addSetGetField} = useCheckout()

const {t} = useI18n()
const baseT = inject('baseT')

const SETTINGS_DEFAULT = 'default'
const SETTINGS_EXTENDED = 'extended'

const store = useStore()
const addresses = computed(() => store.state.general.addresses)
const addressesJson = computed(() => JSON.stringify(addresses.value))
const investCount = computed(() => store.state.emission.investTokenCount)
const fuelCount = computed(() => store.state.emission.fuelTokenCount)
const sumInvestToken = computed(() => store.getters['general/sumInvestToken'])
const sumFuelToken = computed(() => store.getters['general/sumFuelToken'])
const errorInvestToken = ref(false)
const errorFuelToken = ref(false)

const base = 'distribution'
const action = 'UPDATE_DISTRIBUTION'
const autoDistribution = computed(addSetGetField({base, action, field: 'autoDistribution'}))
const settingsType = computed(addSetGetField({base, action, field: 'settingsType'}))

const addAddress = () => store.dispatch('general/addAddress')
const removeAddress = () => store.dispatch('general/removeAddress')

store.dispatch('general/calculateTokens')

watch(settingsType, value => {
  if (value === SETTINGS_DEFAULT) {
    store.dispatch('general/initAddresses')
    autoDistribution.value = true
  }
})

watch([addressesJson, investCount, fuelCount, autoDistribution], () => {
  store.dispatch('general/calculateTokens')
})

watch(sumInvestToken, () => {
  errorInvestToken.value = sumInvestToken.value > investCount.value
})

watch(sumFuelToken, () => {
  errorFuelToken.value = sumFuelToken.value > fuelCount.value
})

provide('baseT', baseT)
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.constructor-distribution {
  &__addresses {
    display: grid;
    gap: 19px;
  }

  &__add {
    text-align: right;
    padding-top: 19px;

    img {
      cursor: pointer;
    }
  }

  &__thead {
    display: grid;
    grid-template-columns: 24px 1.3fr 1fr 1fr;
    gap: 32px;
    font-size: 14px;
    line-height: 16px;
  }

  &__error {
    margin-top: 20px;
  }

  &__settings {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
  }

  &__setting {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;

    &-desc {
      padding: 0 70px 0 22px;
      margin-top: 9px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.02em;
    }
  }

  &__mode {
    display: flex;
    align-items: center;
    margin-top: 40px;
    gap: 12px;
    border-top: 1px solid #E4E8F3;
    border-bottom: 1px solid #E4E8F3;
    font-feature-settings: 'pnum' on, 'lnum' on;
    padding: 13px 0;
    margin-bottom: 30px;
    color: $border-color;

    &_active {
      font-weight: 500;
      color: $color-text
    }
  }

  @media (max-width: 1199px) {
    &__settings {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 767px) {
    &__settings {
      grid-template-columns: 1fr;
    }
  }
}
</style>

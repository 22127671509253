<template>
  <div class="contacts-info-business">
    <header class="contacts-info-business__header">
      <div class="contacts-info-business__title">{{ t(`${baseT}business.title`) }}</div>
    </header>
    <div class="contacts-info-business__list">
      <contacts-info-button
          v-for="{name, url, image} in businessAccounts" :key="name"
          :href="url"
          arrow
      >
        <template #name>{{ te(`socials.${name}`) ? t(`socials.${name}`) : name }}</template>
        <template #icon>
          <img :src="image" :alt="name">
        </template>
      </contacts-info-button>
    </div>
  </div>
</template>

<script setup>
import {inject} from 'vue'
import {useI18n} from 'vue-i18n'
import ContactsInfoButton from '@/pages/Contacts/components/ContactsInfoButton'
import {getSpecifiedSocial} from '@/helpers/socials'
import imageEmail from '@/assets/contact-email.svg'

const {t, te} = useI18n()
const baseT = inject('baseT')

const businessAccounts = [
  ...getSpecifiedSocial(['vk']),
  {
    name: 'info@erachain.org',
    url: 'mailto:info@erachain.org',
    image: imageEmail,
  },
]
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.contacts-info-business {
  &__title {
    font-weight: 300;
    color: $border-color;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 292px);
    gap: 32px;
    margin-top: 22px;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    margin: -4px -16px -26px;
    padding: 26px 16px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .contacts-info-business {
    &__list {
      grid-template-columns: repeat(2, 249px);
    }
  }
}

</style>

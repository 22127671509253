const DEFAULT_VALUES = {
  name: '',
  city: '',
  email: '',
  phone: '',
  company: '',
  business: '',
  usage: '',
  policy: true,
  license: true,
}

export default {
  namespaced: true,
  state() {
    return {
      ...DEFAULT_VALUES
    }
  },
  mutations: {
    UPDATE_CHECKOUT(state, {field, value}) {
      state[field] = value
    },
  },
  actions: {
    reset({commit}) {
      Object.entries(DEFAULT_VALUES).forEach(([field, value]) => {
        commit('UPDATE_CHECKOUT', {field, value})
      })
    },
    UPDATE_CHECKOUT({commit}, {field, value}) {
      commit('UPDATE_CHECKOUT', {field, value})
    },
  },
}

<template>
  <div
      class="base-upload-image"
      :class="{'base-upload-image_active': fileUrl}"
      :style="{'--width': width, '--height': height}"
      @click="goUploadImage"
  >
    <div
        v-if="!fileUrl"
        class="base-upload-image__add"
    >
      <img :src="addImageIcon" alt="Add">
    </div>
    <template v-else>
      <img :src="fileUrl" alt="Logo">
      <div class="base-upload-image__exit" @click.stop="resetHandler">&times;</div>
    </template>
    <input
        ref="files"
        type="file"
        class="base-upload-image__input"
        accept="image/svg+xml,image/png"
        @change="uploadedHandler"
    >
    <teleport to="body">
      <base-modal-confirm
          v-if="openModalExit"
          @close="closeModalExit"
          @success="successModalExit"
      >
        <template #default>{{ t('upload.remove_title') }}</template>
        <template #success>{{ t('upload.remove_button') }}</template>
      </base-modal-confirm>
    </teleport>
  </div>
</template>

<script setup>
import addImageIcon from '../../assets/add.svg'
import {ref, defineProps, defineEmits} from 'vue'
import BaseModalConfirm from '@/components/Base/Modal/BaseModalConfirm'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
const props = defineProps({
  width: {
    type: String,
    default: '40px',
  },
  height: {
    type: String,
    default: '40px',
  },
  uploadImage: {
    type: [Blob, String],
    default: null,
  },
})

const openModalExit = ref(false)
const closeModalExit = () => openModalExit.value = false
const successModalExit = () => {
  files.value.value = ''
  fileUrl.value = ''
  emit('update:uploadImage', null)
  openModalExit.value = false
}

const goUploadImage = () => {
  files.value.click()
}

const getFileUrl = file => {
  return URL.createObjectURL(file)
}

const emit = defineEmits(['update:uploadImage'])

const files = ref(null)
const fileUrl = ref(
    props.uploadImage ? getFileUrl(props.uploadImage) : '',
)

const uploadedHandler = () => {
  if (files.value?.files?.[0]) {
    fileUrl.value = getFileUrl(files.value.files[0])
    emit('update:uploadImage', files.value.files[0])
  }
}

const resetHandler = () => {
  openModalExit.value = true
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.base-upload-image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--width);
  height: var(--height);
  background: white;
  border: 1px solid $color-danger;
  box-shadow: 0 7px 17px -7px rgba(234, 75, 95, 0.58);
  border-radius: 14px;
  padding: 7px;
  box-sizing: content-box;
  cursor: pointer;

  &_active {
    border-color: $color-primary;
    box-shadow: 0 7px 17px -7px rgba(97, 83, 219, 0.58);
    cursor: default;
  }

  &__exit {
    position: absolute;
    top: -9px;
    right: -9px;
    display: flex;
    font-family: Verdana, 'sans-serif';
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    font-size: 16px;
    line-height: 10px;
    background-color: black;
  }

  &__add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__input {
    display: none;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>

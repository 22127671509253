<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.12" fill-rule="evenodd" clip-rule="evenodd"
          d="M10.9999 20.1668C16.0625 20.1668 20.1666 16.0628 20.1666 11.0002C20.1666 5.93755 16.0625 1.8335 10.9999 1.8335C5.93731 1.8335 1.83325 5.93755 1.83325 11.0002C1.83325 16.0628 5.93731 20.1668 10.9999 20.1668ZM9.541 9.91204C9.4224 10.0707 9.33818 10.256 9.16973 10.6266L7.33325 14.6668L11.3735 12.8303C11.7441 12.6619 11.9294 12.5777 12.088 12.4591C12.2288 12.3539 12.3536 12.229 12.4588 12.0883C12.5774 11.9296 12.6617 11.7443 12.8301 11.3738L14.6666 7.3335L10.6263 9.16998C10.2557 9.33842 10.0705 9.42264 9.9118 9.54124C9.77107 9.64644 9.64619 9.77131 9.541 9.91204Z"
          :fill="color"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M2.52075 11.0002C2.52075 6.31725 6.317 2.521 10.9999 2.521C15.6828 2.521 19.4791 6.31725 19.4791 11.0002C19.4791 15.6831 15.6828 19.4793 10.9999 19.4793C6.317 19.4793 2.52075 15.6831 2.52075 11.0002ZM10.9999 1.146C5.55761 1.146 1.14575 5.55786 1.14575 11.0002C1.14575 16.4425 5.55761 20.8543 10.9999 20.8543C16.4422 20.8543 20.8541 16.4425 20.8541 11.0002C20.8541 5.55786 16.4422 1.146 10.9999 1.146ZM15.2925 7.61799C15.411 7.35709 15.3554 7.05 15.1527 6.84736C14.9501 6.64472 14.643 6.58903 14.3821 6.70762L10.3418 8.5441L10.2871 8.56896C9.96964 8.71306 9.71966 8.82652 9.50019 8.99058C9.30668 9.13522 9.13497 9.30693 8.99033 9.50043C8.82628 9.71991 8.71281 9.96988 8.56872 10.2873L8.54386 10.3421L6.70738 14.3823C6.58879 14.6432 6.64447 14.9503 6.84712 15.153C7.04976 15.3556 7.35685 15.4113 7.61774 15.2927L11.658 13.4562L11.7127 13.4314C12.0302 13.2873 12.2802 13.1738 12.4997 13.0097C12.6932 12.8651 12.8649 12.6934 13.0095 12.4999C13.1736 12.2804 13.287 12.0304 13.4311 11.713L13.4311 11.713L13.456 11.6582L15.2925 7.61799ZM10.9108 9.79586L13.2821 8.71801L12.2042 11.0893C12.0236 11.4867 11.972 11.5913 11.9082 11.6767C11.8424 11.7646 11.7644 11.8427 11.6764 11.9084C11.591 11.9722 11.4865 12.0238 11.089 12.2045L8.71777 13.2823L9.79561 10.9111C9.97627 10.5136 10.0278 10.409 10.0917 10.3237C10.1574 10.2357 10.2355 10.1577 10.3234 10.0919C10.4088 10.0281 10.5134 9.97651 10.9108 9.79586Z"
          :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'IconExplorer',
  props: {
    color: {
      type: String,
      default: '#EA4B5F',
    },
  },
}
</script>

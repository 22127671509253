<template>
  <nav class="navigation">
    <ul class="navigation__list">
      <li
          v-for="page in pages"
          class="navigation__item"
          :key="page"
      >
        <router-link :to="{name: page}">{{ t(`navigation.${page}`) }}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import {useI18n} from 'vue-i18n'

const {t} = useI18n()

const pages = ['constructor', 'technology', 'faq', 'contacts']
</script>

<style lang="scss">
@import "../scss/variables";

.navigation {
  &__list {
    text-align: center;
    margin: 0;
    padding: 0;
  }

  &__item {
    display: inline-block;
    list-style: none;
    margin: 0 30px 0 0;
    cursor: pointer;
    transition: 0.5s;

    &:last-child {
      margin-right: 0;
    }

    a {
      display: inline-block;
      position: relative;
      color: inherit;
      text-decoration: none;
      font-size: 16px;
      line-height: 32px;

      &:not(.router-link-active):hover {
        color: $color-primary;
      }

      &.router-link-active {
        font-weight: 600;
        color: currentColor !important;
        cursor: default;

        &:before {
          position: absolute;
          content: '';
          left: -10px;
          top: 3px;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: $color-primary;
        }
      }
    }
  }
}
</style>

<template>
  <div class="base-form-field">
    <div v-if="$slots.label" class="base-form-field__label">
      <slot name="label"/>
    </div>
    <div class="base-form-field__input">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseFormField',
}
</script>

<style lang="scss">
@import "../../scss/variables";

.base-form-field {
  &__label {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    margin-bottom: 12px;

    .base-tooltip {
      position: relative;
      top: -5px;
    }
  }

  &__input {
    line-height: 24px;
    min-height: 24px;
    margin-bottom: -1px;
  }
}
</style>

import IconFacebook from '@/components/Icon/Social/IconFacebook'
import IconLinkedin from '@/components/Icon/Social/IconLinkedin'
import IconYoutube from '@/components/Icon/Social/IconYoutube'
import IconInstagram from '@/components/Icon/Social/IconInstagram'
import IconTwitter from '@/components/Icon/Social/IconTwitter'
import IconTelegram from '@/components/Icon/Social/IconTelegram'
import IconVk from '@/components/Icon/Social/IconVk'

export const socials = [
  {
    name: 'linkedin',
    component: IconLinkedin,
    url: 'https://www.linkedin.com/company/erachainplatform'
  },
  {
    name: 'youtube',
    component: IconYoutube,
    url: 'https://www.youtube.com/c/ErachainBlockchaindevelopers'
  },
  {
    name: 'facebook',
    component: IconFacebook,
    url: 'https://www.facebook.com/pg/erachaineng/',
    image: require('@/assets/social-facebook.svg'),
  },
  {
    name: 'instagram',
    component: IconInstagram,
    url: 'https://www.instagram.com/erachainofficial/',
    image: require('@/assets/social-instagram.svg'),
  },
  {
    name: 'twitter',
    component: IconTwitter,
    url: 'https://twitter.com/erachain_',
  },
  {
    name: 'telegram',
    component: IconTelegram,
    url: 'https://t.me/erachain',
  },
  {
    name: 'telegram-ru',
    component: IconTelegram,
    url: 'https://t.me/erachainrussia',
  },
  {
    name: 'vk',
    component: IconVk,
    url: 'https://vk.com/erachain',
    image: require('@/assets/social-vk.svg'),
  },
]

/**
 * Получение указанных соцсетей
 * @param {Array} arraySocial
 * @returns Array<Object>
 */
export const getSpecifiedSocial = (arraySocial) => {
  return socials.filter(({name}) => arraySocial.includes(name))
}

<template>
  <svg width="47" height="38" viewBox="0 0 47 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M37.5338 18.2544C35.0531 18.2544 32.6743 19.2398 30.9203 20.994C29.1662 22.7482 28.1807 25.1268 28.1807 27.6076C28.1807 30.0883 29.1661 32.467 30.9203 34.2211C32.6745 35.9752 35.0531 36.9607 37.5338 36.9607C40.0145 36.9607 42.3933 35.9753 44.1474 34.2211C45.9014 32.4669 46.887 30.0883 46.887 27.6076C46.8848 25.1277 45.8985 22.7499 44.1452 20.9962C42.3915 19.2429 40.0136 18.2565 37.5338 18.2544ZM38.9219 31.409V32.0375C38.9219 32.5812 38.481 33.0221 37.9373 33.0221C37.3936 33.0221 36.9528 32.5812 36.9528 32.0375V31.6002H35.9788C35.4351 31.6002 34.9942 31.1594 34.9942 30.6157C34.9942 30.072 35.4351 29.6311 35.9788 29.6311H37.8688C38.4714 29.6311 38.5184 29.2448 38.5184 29.1265C38.5184 28.8255 38.2876 28.6598 37.8688 28.6598H37.1941L37.1945 28.6593C36.5164 28.6941 35.8535 28.4497 35.3595 27.9833C34.8659 27.517 34.5846 26.8691 34.5802 26.19C34.5762 25.5553 34.8246 24.9457 35.2711 24.4948C35.7229 24.0531 36.3216 23.7929 36.9527 23.7634V23.1767C36.9527 22.633 37.3936 22.1921 37.9373 22.1921C38.4809 22.1921 38.9218 22.633 38.9218 23.1767V23.7511H39.084C39.6277 23.7511 40.0685 24.192 40.0685 24.7357C40.0685 25.2794 39.6277 25.7202 39.084 25.7202H37.194C36.6327 25.7202 36.5488 26.0143 36.5488 26.1901C36.5488 26.3074 36.5954 26.6907 37.194 26.6907H37.8687V26.6902C38.5851 26.6648 39.2805 26.9324 39.7947 27.4317C40.2421 27.8814 40.4918 28.4914 40.4874 29.1262C40.4865 29.6202 40.3366 30.1023 40.0575 30.5098C39.778 30.9168 39.3824 31.2306 38.9217 31.4091L38.9219 31.409Z" fill="url(#paint0_linear_1418_1121)"/>
    <path d="M26.2113 27.6081C26.2157 24.8624 27.216 22.2116 29.0265 20.1475C30.8374 18.0835 33.3352 16.7464 36.0567 16.3843V6.57318L39.0103 3.28987V16.3849C39.3453 16.4293 39.6749 16.4882 39.9997 16.562C40.0085 16.5607 40.0169 16.5624 40.0243 16.5668C40.3491 16.6407 40.6643 16.7242 40.9794 16.8226V2.01059C40.9794 1.48843 40.772 0.987364 40.4028 0.618165C40.0336 0.248967 39.5325 0.0415039 39.0103 0.0415039H9.20848C8.35711 0.0718312 7.5334 0.352687 6.84073 0.848919L0.997608 5.36769C0.722903 5.65471 0.583131 6.045 0.613455 6.44103V34.5011C0.613894 35.2843 0.925517 36.0351 1.47933 36.5889C2.03313 37.1427 2.78387 37.4543 3.56712 37.4548H31.9512C31.484 37.1888 31.0366 36.8895 30.6125 36.559C27.8382 34.4185 26.2128 31.1133 26.2115 27.6093L26.2113 27.6081ZM8.04615 2.40828C8.39249 2.17753 8.7929 2.0404 9.2078 2.00963H37.5131L34.4119 5.45553H4.10257L8.04615 2.40828ZM11.4429 32.5308H6.52016C5.9769 32.5291 5.53737 32.0895 5.53562 31.5463V26.6236C5.53562 26.0799 5.97647 25.639 6.52016 25.639C7.06385 25.639 7.5047 26.0799 7.5047 26.6236V30.5617H11.4429C11.9866 30.5617 12.4274 31.0026 12.4274 31.5463C12.4274 32.09 11.9866 32.5308 11.4429 32.5308Z" fill="url(#paint1_linear_1418_1121)"/>
    <defs>
      <linearGradient id="paint0_linear_1418_1121" x1="37.5338" y1="18.2544" x2="37.5338" y2="36.9607" gradientUnits="userSpaceOnUse">
        <stop stop-color="currentColor"/>
        <stop offset="1" stop-color="currentColor" stop-opacity="0.15"/>
      </linearGradient>
      <linearGradient id="paint1_linear_1418_1121" x1="20.7944" y1="0.0415039" x2="20.7944" y2="37.4548" gradientUnits="userSpaceOnUse">
        <stop stop-color="currentColor"/>
        <stop offset="1" stop-color="currentColor" stop-opacity="0.15"/>
      </linearGradient>
    </defs>
  </svg>
</template>\

import {useRouter} from 'vue-router'

export default function() {
  const router = useRouter()

  const goHome = () => {
    return router.push({name: 'home'})
  }

  const goConstructor = () => {
    return router.push({name: 'constructor'})
  }

  const goContacts = () => {
    return router.push({name: 'contacts'})
  }

  const goFAQ = () => {
    return router.push({name: 'faq'})
  }

  return {
    goFAQ,
    goHome,
    goConstructor,
    goContacts,
  }
}

<template>
  <div class="constructor-step-one" id="step-one">
    <constructor-step-title>
      {{ t('constructor.steps.title', {number: 1}) }}
    </constructor-step-title>
    <div class="container">
      <header class="constructor-step-one__header">
        <base-title type="h2">{{ t(`${baseT}title`) }}</base-title>
        <div class="constructor-step-one__desc">{{ t(`${baseT}desc`) }} <b>{{ priceBlockchain }}</b></div>
      </header>
      <div class="row">
        <div class="col-lg-10 offset-lg-1">
          <div class="constructor-step-one__form">
            <ConstructorFormName :key="`name-${keyComponent}`"/>
            <ConstructorFormProtocol :key="`protocol-${keyComponent}`"/>
            <ConstructorFormEmission :key="`emission-${keyComponent}`"/>
            <ConstructorFormStake :key="`stake-${keyComponent}`"/>
            <ConstructorFormDistribution/>
            <ConstructorFormSeed/>
          </div>
          <div class="constructor-step-one__message fw-600">
            {{ t(`${baseT}need_save`) }}
          </div>
          <div class="constructor-step-one__buttons">
            <span class="constructor-step-one__link" @click="reset">{{ t(`${baseT}button_reset`) }}</span>
            <base-button @action="save" :disabled="disabledSave">{{ t(`${baseT}button_save`) }}</base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ConstructorStepTitle from '@/pages/Constructor/components/ConstructorStepTitle'
import {useI18n} from 'vue-i18n'
import BaseTitle from '@/components/Base/BaseTitle'
import ConstructorFormName from '@/pages/Constructor/components/ConstructorFormName'
import ConstructorFormProtocol from '@/pages/Constructor/components/ConstructorFormProtocol'
import ConstructorFormEmission from '@/pages/Constructor/components/ConstructorFormEmission'
import ConstructorFormStake from '@/pages/Constructor/components/ConstructorFormStake'
import ConstructorFormSeed from '@/pages/Constructor/components/ConstructorFormSeed'
import ConstructorFormDistribution from '@/pages/Constructor/components/ConstructorFormDistribution'
import BaseButton from '@/components/Base/BaseButton'
import {computed, provide, defineEmits} from 'vue'
import {useStore} from 'vuex'
import {numberToCurrency} from '@/helpers'
import priceList from '@/helpers/priceList'

const emit = defineEmits(['save', 'reset'])

const {t, locale} = useI18n()
const baseT = 'constructor.steps.one.'

const store = useStore()

const keyComponent = computed(() => store.state.keyComponents)

const priceBlockchain = computed(() => {
  return numberToCurrency(priceList[locale.value].blockchain, locale.value)
})

const disabledSave = computed(() => {
  return store.getters['general/sumInvestToken'] > store.state.emission.investTokenCount
      || store.getters['general/sumFuelToken'] > store.state.emission.fuelTokenCount
      || store.state.general.status
})

const save = () => emit('save')
const reset = () => emit('reset')

provide('baseT', baseT)
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.constructor-step-one {
  &__header {
    margin-top: 30px;
    margin-bottom: 80px;
    text-align: center;
  }

  &__desc {
    margin-top: $row;
    font-size: 24px;
    line-height: 28px;

    b {
      font-weight: 500;
    }
  }

  &__form {
    display: grid;
    gap: 73px;
    margin-bottom: 86px;
  }

  &__message {
    margin-bottom: 30px;
    text-align: center;
  }

  &__success, &__error {
    border: 1px solid transparent;
    border-radius: 14px;
    padding: 20px;
  }

  &__success {
    border-color: green;
    color: green;
  }

  &__error {
    border-color: red;
    color: red;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 38px;

    .base-button {
      padding: 0 52px;
    }
  }

  &__link {
    cursor: pointer;
    font-weight: 500;
    color: $color-primary;
  }
}

@media (max-width: 767px) {
  .constructor-step-one {
    &__buttons {
      margin-top: 40px;
      flex-direction: column;
      gap: 14px;
    }
  }
}
</style>

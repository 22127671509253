import priceList from '@/helpers/priceList'
import i18n from '@/i18n'

const vat = {
  ru: 0.2,
  en: 0.07,
}

export default {
  namespaced: true,
  state() {
    return {
      items: [],
    }
  },
  getters: {
    total(state) {
      return state.items.reduce((acc, {price}) => acc + +price, 0)
    },
    vat(_, getters) {
      return getters.total * vat[i18n.global.locale.value]
    },
    totalAndVat(_, getters) {
      return getters.total + getters.vat
    },
  },
  mutations: {
    setCart(state, cart) {
      state.items = cart
    },
  },
  actions: {
    init({dispatch}) {
      dispatch('updateCart')
    },
    reset({commit}) {
      commit('setCart', [])
    },
    updateCart({commit, rootState}) {
      const cart = [
        {
          name: 'blockchain',
          price: priceList[i18n.global.locale.value].blockchain,
          removeDispatch: null,
        },
      ]

      if (rootState.general.desktop) {
        cart.push({
          name: 'desktop',
          price: priceList[i18n.global.locale.value].desktop,
          removeDispatch: ['general/updateDesktop', false],
        })
      }

      if (rootState.general.web) {
        cart.push({
          name: 'web',
          price: priceList[i18n.global.locale.value].web,
          removeDispatch: ['general/updateWeb', false],
        })
      }

      if (rootState.general.mobile) {
        cart.push({
          name: 'mobile',
          price: priceList[i18n.global.locale.value].mobile,
          removeDispatch: ['general/updateMobile', false],
        })
      }

      if (rootState.general.installation === 'professional') {
        if (rootState.general.machine) {
          const name = `node${rootState.general.machine}`
          cart.push({
            name,
            price: priceList[i18n.global.locale.value][name],
            removeDispatch: ['general/updateInstallation', 'self'],
          })
        }

        if (rootState.general.rent === 'professional') {
          cart.push({
            name: 'rent',
            price: priceList[i18n.global.locale.value].rent,
            removeDispatch: ['general/updateRent', 'self'],
          })
        }
      }

      if (rootState.general.sla) {
        const name = `sla_${rootState.general.sla}_${rootState.general.slaPeriod}`
        cart.push({
          name,
          price: priceList[i18n.global.locale.value][name] || 0,
          removeDispatch: ['general/updateSLA', 'free'],
        })
      }

      commit('setCart', cart)
    },
  },
}

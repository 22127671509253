const DEFAULT_VALUES = {
  investTokenName: '',
  investTokenCount: null,
  investTokenImage: null,
  fuelTokenName: '',
  fuelTokenCount: null,
  fuelTokenImage: null,
}

export default {
  namespaced: true,
  state() {
    return {
      ...DEFAULT_VALUES,
    }
  },
  mutations: {
    UPDATE_EMISSION(state, {field, value}) {
      state[field] = value
    },
  },
  actions: {
    async reset({commit}) {
      Object.entries(DEFAULT_VALUES).forEach(([field, value]) => {
        commit('UPDATE_EMISSION', {field, value})
      })
    },
    UPDATE_EMISSION({commit, dispatch}, {field, value}) {
      commit('UPDATE_EMISSION', {field, value})
      dispatch('general/resetConfigStatus', null, {root: true})
    },
  },
}

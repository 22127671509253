<template>
  <div
      :class="['base-switch', {'base-switch_active':active}]"
      @click="toggleSwitch"
  >
    <div class="base-switch__circle"/>
    <div class="base-switch__label">
      {{ active ? t('switch.on') : t('switch.off') }}
    </div>
  </div>
</template>

<script setup>
import {ref, toRefs, defineProps, defineEmits, watch} from 'vue'
import {useI18n} from 'vue-i18n'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue'])

const {t} = useI18n()
const {modelValue} = toRefs(props)
const active = ref(modelValue.value)
const toggleSwitch = () => {
  active.value = !active.value
}

watch(active, () => {
  if (active.value !== modelValue.value) {
    emit('update:modelValue', active.value)
  }
})

watch(modelValue, () => {
  if (active.value !== modelValue.value) {
    active.value = modelValue.value
  }
})
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.base-switch {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  background-color: #E6E6F2;
  border-radius: 50px;
  height: 20px;
  width: 52px;
  max-width: 52px;
  padding: 0 2px;
  cursor: pointer;
  color: $border-color;
  text-align: left;

  &_active {
    background-color: $color-danger;
    color: white;
    text-align: right;

    .base-switch__circle {
      order: 1;
    }
  }

  &__circle {
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 8px;
  }

  &__label {
    flex: 1;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }
}
</style>

<template>
  <component
      :is="type"
      :href="href"
      :class="['base-button', `base-button_color-${color}`, `base-button_size-${size}`]"
      :data-responsive="responsive"
      target="_blank"
      @click="onAction"
  >
    <slot/>
  </component>
</template>

<script>
import {computed} from 'vue'

export default {
  props: {
    href: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'danger', 'light', 'white'].includes(value)
      },
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['small', 'medium', 'big'].includes(value)
      },
    },
    responsive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['action'],
  setup(props, {emit}) {
    const type = computed(() => props.href ? 'a' : 'button')
    const onAction = event => {
      if (!props.href) {
        event.preventDefault()
        emit('action')
      }
    }
    return {
      type,
      onAction,
    }
  },
}
</script>

<style lang="scss">
@import "../../scss/variables";

.base-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 36px;
  border: none;
  transition: $transition-default;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  z-index: 1;

  &[data-responsive="true"] {
    width: 100%;
  }

  & > * {
    position: relative;
  }

  &__pointer {
    $width: 30px;

    position: absolute;
    width: $width * 2;
    height: $width * 2;
    left: 50%;
    top: 50%;
    margin-left: -$width;
    margin-top: -$width;
    border-radius: $width;
    background-color: #ffb5d2;
    z-index: 0;
    opacity: 0;
    visibility: hidden;
    transition: $transition-default;
  }

  &_color {
    &-primary {
      background: linear-gradient(62.35deg, #5856D6 8.46%, #C644FC 126.57%);
      box-shadow: 0 10px 13px -8px rgba(118, 83, 219, 0.75);
      color: white;

      &:before {
        position: absolute;
        content: '';
        inset: 0;
        background: linear-gradient(62.35deg, #4F4DBC 8.46%, #9D45C1 126.57%);
        opacity: 0;
        transition: .5s;
        z-index: -1;
      }

      &:hover {
        color: white;
        box-shadow: 0 10px 13px -8px rgba(118, 83, 219, 0);

        &:before {
          opacity: 1;
        }
      }
    }

    &-light {
      background: transparent;
      color: $color-primary;
      box-shadow: inset 0 0 0 1px $color-primary;

      &:before {
        position: absolute;
        content: '';
        inset: 0;
        background: linear-gradient(62.35deg, #5856D6 8.46%, #C644FC 126.57%);
        opacity: 0;
        transition: .5s;
        z-index: -1;
      }

      &:hover {
        color: white;
        box-shadow: inset 0 0 0 1px transparent;

        &:before {
          opacity: 1;
        }
      }
    }

    &-danger {
      background: linear-gradient(76.09deg, #F44D61 13.91%, #FE7443 107.34%);
      box-shadow: 0 10px 13px -8px rgba(244, 97, 77, 0.5);
      color: white;

      &:before {
        position: absolute;
        content: '';
        inset: 0;
        background: linear-gradient(76.09deg, #D93F52 13.91%, #E0663B 107.34%);
        opacity: 0;
        transition: .5s;
        z-index: -1;
      }

      &:hover {
        color: white;
        box-shadow: 0 10px 13px -8px rgba(244, 97, 77, 0);

        &:before {
          opacity: 1;
        }
      }
    }

    &-white {
      background: white;
      color: black;

      &:before {
        position: absolute;
        content: '';
        inset: 0;
        background: linear-gradient(62.35deg, #5856D6 8.46%, #C644FC 126.57%);
        opacity: 0;
        transition: .5s;
        z-index: -1;
      }

      &:hover {
        color: white;

        &:before {
          opacity: 1;
        }
      }
    }
  }

  &_size {
    &-small {
      height: 43px;
      line-height: 43px;
      font-size: 16px;
      border-radius: 22px;
    }

    &-medium {
      height: 53px;
      line-height: 53px;
      font-size: 18px;
      border-radius: 27px;
    }
  }

  &:hover {
    .base-button__pointer {
      opacity: 1;
      visibility: visible;
    }
  }

  &:disabled {
    opacity: .7;
    cursor: no-drop;
  }
}
</style>

<template>
  <div class="constructor-deployment-option" :class="{'constructor-deployment-option_active':active}">
    <div class="constructor-deployment-option__disabled"/>
    <header class="constructor-deployment-option__header">
      <div class="constructor-deployment-option__icon">
        <slot name="icon"/>
      </div>
      <div class="constructor-deployment-option__title" v-if="$slots.title">
        <slot name="title"/>
      </div>
      <div class="constructor-deployment-option__desc" v-if="$slots.desc">
        <slot name="desc"/>
      </div>
    </header>
    <div class="constructor-deployment-option__wrapper">
      <slot/>
    </div>
  </div>
</template>

<script setup>
import {inject} from 'vue'

const active = inject('active')
</script>

<style lang="scss">
@import "~@/scss/variables.scss";

.constructor-deployment-option {
  position: relative;
  border: 1px solid $border-color;
  border-radius: 29px;
  padding: 36px;
  color: $border-color;
  height: 100%;
  line-height: 130%;

  &__disabled {
    position: absolute;
    inset: 0;
  }

  &__header {
    display: grid;
    grid-template-columns: 76px 1fr;
    align-items: center;
    gap: 12px 24px;
    margin-bottom: 38px;
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
  }

  &__icon {
    grid-row: 1 / 3;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 76px;
    height: 76px;
    border: 1px solid $border-color;
    border-radius: 20px;
    color: $border-color;
    background: transparent;
  }

  &_active {
    border: 1px solid $color-danger;
    box-shadow: 0 13px 76px -27px rgba(45, 54, 136, 0.33);
    color: $color-text;

    .constructor-deployment-option {
      &__disabled {
        display: none;
      }

      &__icon {
        border: 1px solid transparent;
        color: white;
        background: radial-gradient(50% 50% at 50% 50%, #C03446 0%, #EA4B5F 100%);
        box-shadow: inset 0 0 8px rgba(255, 233, 233, 0.44);
      }
    }
  }
}
</style>

<template>
  <BaseInputRange
      v-model:rangeValue="minStake"
      min="0.001"
      max="0.1"
      step="0.001"
      min-view="0.001%"
      max-view="0.1%"
      :current-value="minStakeView"
  />
</template>

<script>
import BaseInputRange from '../../Base/BaseInputRange.vue'
import {computed, inject, ref, watch} from 'vue'
import {useStore} from 'vuex'
import {numberFormat} from '@/helpers'
import {useI18n} from 'vue-i18n'

export default {
  name: 'RangeMinStake',
  components: {BaseInputRange},
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const store = useStore()
    const {t} = useI18n()
    const baseT = inject('baseT')
    const minStake = ref(props.modelValue)
    const minStakeView = computed(() => {
      const investCount = store.state.emission.investTokenCount
      const investName = store.state.emission.investTokenName || t(`${baseT}emission.invest_name_placeholder`)
      const minStakeToken = numberFormat(minStake.value / 100 * investCount)
      return `${minStake.value}% = ${minStakeToken} ${investName}`
    })

    watch(minStake, () => emit('update:modelValue', minStake.value))

    return {
      minStake,
      minStakeView,
    }
  },
}
</script>

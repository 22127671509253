<template>
  <base-form-block>
    <template #title>
      {{ t(`${baseT}name.title`) }}
      <BaseRequired v-if="errorRequired"/>
    </template>
    <template #content>
      <BaseInput v-model="name" :placeholder="t(`${baseT}name.placeholder`)" required/>
    </template>
  </base-form-block>
</template>

<script setup>
import {computed, inject, ref, watch} from 'vue'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import BaseFormBlock from '../../../components/Base/BaseFormBlock.vue'
import BaseInput from '../../../components/Base/BaseInput.vue'
import BaseRequired from '@/components/Base/BaseRequired'

const {t} = useI18n()
const baseT = inject('baseT')

const saveForm = inject('saveForm')
const errorRequired = computed(() => saveForm.value && !name.value)

const store = useStore()
const name = ref(store.state.general.name)


watch(name, () => store.dispatch('general/updateName', name.value))
</script>

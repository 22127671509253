<template>
  <div class="constructor-step-two" id="step-two">
    <constructor-step-title>{{ t('constructor.steps.title', {number: 2}) }}</constructor-step-title>
    <div class="container">
      <header class="constructor-step-two__header">
        <base-title type="h2">{{ t(`${baseT}title`) }}</base-title>
        <div class="row">
          <div class="constructor-step-two__desc col-lg-6 offset-lg-3">
            {{ t(`${baseT}desc`) }}
          </div>
        </div>
      </header>
      <div class="constructor-step-two__list">
        <div class="row gy-4">
          <div class="col-lg-6 col-xl-4" v-for="{name, image} in applications" :key="name">
            <constructor-application-block
                :icon-url="image"
                :status="store.state.general[name]"
                :price="prices[name]"
                @changeStatus="changeAppStatus(name)"
            >
              <template #title>{{ t(`${baseT}applications.${name}.title`) }}</template>
              <template #desc>{{ t(`${baseT}applications.${name}.desc`) }}</template>
              <template #info>{{ t(`${baseT}applications.${name}.info`) }}</template>
            </constructor-application-block>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-10 offset-xl-1">
          <div class="constructor-step-two__logo">
            <ConstructorLogo/>
          </div>
          <div v-if="web || openForm" class="constructor-step-two__web">
            <ConstructorWeb/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ConstructorStepTitle from '@/pages/Constructor/components/ConstructorStepTitle'
import {useI18n} from 'vue-i18n'
import BaseTitle from '@/components/Base/BaseTitle'
import ConstructorApplicationBlock from '@/pages/Constructor/components/ConstructorApplicationBlock'
import ConstructorLogo from '@/pages/Constructor/components/ConstructorLogo'
import ConstructorWeb from '@/pages/Constructor/components/ConstructorWeb'
import {computed, inject} from 'vue'
import {useStore} from 'vuex'
import priceList from '@/helpers/priceList'

const {t, locale} = useI18n()
const baseT = 'constructor.steps.two.'

const store = useStore()
const web = computed(() => store.state.general.web)
const openForm = inject('openForm')

const prices = computed(() => {
  return priceList[locale.value]
})

const applications = [
  {name: 'desktop', price: 0, image: require('@/assets/app-desktop.png')},
  {name: 'web', price: 1499, image: require('@/assets/app-web.png')},
  {name: 'mobile', price: 1999, image: require('@/assets/app-mobile.png')},
]
const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
}

const changeAppStatus = app => {
  store.dispatch(`general/update${capitalizeFirstLetter(app)}`, !store.state.general[app])
}
</script>

<style lang="scss" scoped>
.constructor-step-two {
  &__header {
    text-align: center;
    margin-bottom: 76px;
    margin-top: 30px;
  }

  &__desc {
    margin-top: 24px;
  }
}
</style>

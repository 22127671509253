import {createStore} from 'vuex'
import general from './modules/general.js'
import protocol from './modules/protocol.js'
import emission from './modules/emission.js'
import distribution from './modules/distribution.js'
import stake from './modules/stake.js'
import style from './modules/style.js'
import cart from '@/store/modules/cart'
import checkout from '@/store/modules/checkout'

export default createStore({
  modules: {
    general,
    protocol,
    emission,
    stake,
    distribution,
    style,
    cart,
    checkout,
  },
  state() {
    return {
      keyComponents: 0,
    }
  },
  mutations: {
    updateKeyComponents(state) {
      ++state.keyComponents
    },
  },
  actions: {
    async init({dispatch}) {
      await dispatch('general/init')
      await dispatch('cart/init')
    },
    async resetConfig({dispatch}) {
      await dispatch('emission/reset')
      await dispatch('general/reset')
      await dispatch('protocol/reset')
      await dispatch('distribution/reset')
      await dispatch('stake/reset')
      await dispatch('style/reset')
    },
    async reset({dispatch, commit}) {
      await dispatch('resetConfig')
      await dispatch('checkout/reset')
      await dispatch('cart/reset')
      await commit('updateKeyComponents')
    },
    saveConfig({dispatch}) {
      dispatch('general/updateConfigStatus', true)
    },
  },
})

<template>
  <div class="home-advantage-item-block">
    <div class="row gy-4">
      <div class="col-lg-5 order-last order-lg-first">
        <div class="home-advantage-item-block__why">
          <div class="home-advantage-item-block__why-image">
            <img :src="imageBlockWhy" alt="block why">
          </div>
          <div class="home-advantage-item-block__why-name">
            {{ t(`${baseT}why_name`) }}
          </div>
          <div class="home-advantage-item-block__why-value">
            {{ t(`${baseT}why_value`) }}
          </div>
        </div>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <header class="home-advantage-item-block__header">
          <base-title type="h3">{{ t(`${baseT}title`) }}</base-title>
        </header>
        <div class="home-advantage-item-block__desc">
          <p>{{ t(`${baseT}desc_1`) }}</p>
          <p>{{ t(`${baseT}desc_2`) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'
import imageBlockWhy from '@/assets/block-why.png'

const {t} = useI18n()
const baseT = 'home.advantage.items.block.'
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.home-advantage-item-block {
  &__why {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0 4.60703px 30.9329px -5.26518px rgba(45, 74, 136, 0.24);
    border-radius: 29px;
    padding: $row $row * 1.5;

    &-image {
      position: absolute;
      top: -138px;
      right: -177px;
    }

    &-name {
      font-weight: 500;
      font-size: 24px;
      line-height: 34px;
      padding-right: 100px;
      margin-bottom: $row;
    }
  }

  &__desc {
    margin-top: $row * 1.5;
    font-size: 24px;
    line-height: 34px;
  }
}
</style>

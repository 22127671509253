<template>
  <div class="technology-on-chain">
    <div class="container">
      <div class="row gy-4">
        <div class="col-md-5">
          <header class="technology-on-chain__header">
            <base-title type="h3">{{ t(`${baseT}title`) }}</base-title>
          </header>
          <div class="technology-on-chain__desc">
            <p>{{ t(`${baseT}desc_1`) }}</p>
            <p>{{ t(`${baseT}desc_2`) }}</p>
          </div>
        </div>
        <div class="col-md-6 offset-md-1">
          <div class="technology-on-chain__image">
            <img :src="imageOnChain" alt="on chain">
          </div>
        </div>
      </div>
      <div class="row gy-4">
        <div class="col-md-5">
          <home-block-darken>
            <template #title>{{ t(`${baseT}price.title`) }}</template>
            <template #desc>{{ t(`${baseT}price.desc`) }}</template>
          </home-block-darken>
        </div>
        <div class="col-md-6 offset-md-1">
          <div class="technology-on-chain__desc">
            <p>{{ t(`${baseT}desc_3`) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useI18n} from 'vue-i18n'
import BaseTitle from '@/components/Base/BaseTitle'
import HomeBlockDarken from '@/pages/Home/components/HomeBlockDarken'
import imageOnChain from '@/assets/on-chain.png'

const {t} = useI18n()
const baseT = 'home.on_chain.'
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.technology-on-chain {
  overflow: hidden;
  font-size: 24px;
  line-height: 34px;

  &__header {
    margin-bottom: $row * 1.5;
  }

  &__image {
    margin-bottom: 56px;
  }
}

@media (max-width: 767px) {
  .technology-on-chain__image {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
</style>

<template>
  <home-capability-block class="home-capability-applications">
    <template #title>{{ t(`${baseT}title`) }}</template>
    <template #info>
      <p>{{ t(`${baseT}desc_1`) }}</p>
    </template>
    <template #image>
      <div class="home-capability-applications__grid">
        <div class="home-capability-applications__image">
          <div class="home-capability-applications__man">
            <img :src="imageApplicationsMan" alt="app man">
            <img
                class="home-capability-applications__shadow"
                :src="capabilityShadow"
                alt="capability shadow"
            >
          </div>
        </div>
        <HomeCapabilityApps/>
      </div>
    </template>
    <template #after>
      <HomeCapabilityPlatforms class="home-capability-applications__platforms"/>
    </template>
  </home-capability-block>
</template>

<script setup>
import {useI18n} from 'vue-i18n'
import {provide} from 'vue'
import HomeCapabilityBlock from '@/pages/Home/components/HomeCapabilityBlock'
import HomeCapabilityPlatforms from '@/pages/Home/components/HomeCapabilityPlatforms'
import capabilityShadow from '@/assets/capability-shadow.png'
import imageApplicationsMan from '@/assets/capability-app.png'
import HomeCapabilityApps from '@/pages/Home/components/HomeCapabilityApps'

const {t} = useI18n()
const baseT = 'home.capability.applications.'
provide('baseT', baseT)
</script>

<style lang="scss">
.home-capability-applications {
  &__platforms {
    margin-top: 68px;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 144px;
  }

  &__image, &__man {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__man {
    position: relative;
    z-index: 0;
  }

  &__shadow {
    position: absolute;
    z-index: -1;
    width: 360.59%;
    height: auto;
  }
}
</style>

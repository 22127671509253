import {ref} from 'vue'

export default function() {
  const error = ref('')
  const success = ref(false)

  const setError = (errorText, timeout) => {
    error.value = errorText
    if (timeout) {
      setTimeout(() => {
        error.value = ''
      }, timeout)
    }
  }

  const reset = fields => {
    for (let key = 0; key < fields.length; key++) {
      if (typeof fields[key].value === 'boolean') fields[key].value = false
      else if (typeof fields[key].value === 'string') fields[key].value = ''
      else fields[key].value = null
    }
  }

  return {
    error,
    success,
    setError,
    reset,
  }
}

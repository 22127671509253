<template>
  <div class="checkout-info">
    <header class="checkout-info__header">
      <div class="checkout-info__title">{{ t(`${baseT}title`) }}</div>
    </header>
    <div class="checkout-info__list">
      <div
          v-for="{name, price} in items"
          class="checkout-info__item"
          :key="name"
      >
        <div>{{ t(`${baseT}${name}`) }}</div>
        <div>{{ outputPrice(price) }}</div>
      </div>
    </div>
    <div class="checkout-info__total">
      <CartTotal/>
    </div>
  </div>
</template>

<script setup>
import {useI18n} from 'vue-i18n'
import {computed} from 'vue'
import {useStore} from 'vuex'
import {numberToCurrency} from '@/helpers'
import CartTotal from '@/components/Cart/CartTotal'

const {t, locale} = useI18n()
const baseT = 'checkout.info.'

const store = useStore()

const items = computed(() => store.state.cart.items)

const outputPrice = number => {
  if (number === null) {
    return t(`${baseT}under_contract`)
  }

  return numberToCurrency(number, locale.value)
}
</script>

<style lang="scss" scoped>
.checkout-info {
  position: relative;
  background: linear-gradient(90deg,
      rgba(24, 175, 242, 1) 0%,
      rgba(95, 82, 216, 1) 27%,
      rgba(170, 66, 185, 1) 53%,
      rgba(244, 77, 97, 1) 75%,
      rgba(254, 116, 67, 1) 100%
  ), #FFFFFF;
  border-radius: 14px;
  padding: 24px 20px 0;

  &::before {
    position: absolute;
    content: '';
    inset: 1px;
    background-color: white;
    border-radius: 13px;
  }

  & > * {
    position: relative;
  }

  &__header {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 22px;
  }

  &__item {
    display: grid;
    grid-template-columns: 1fr 110px;

    div:last-child {
      text-align: right;
    }
  }

  &__item {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 13px;
  }

  &__total {
    border-top: 1px solid #171717;
    margin-top: 22px;
  }
}
</style>

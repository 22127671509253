<template>
  <section class="technology-base">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5">
          <header>
            <base-title type="h2">{{ t(`${baseT}title`) }}</base-title>
          </header>
          <div class="technology-base__desc">
            <p>{{ t(`${baseT}desc_1`) }}</p>
            <p>{{ t(`${baseT}desc_2`) }}</p>
          </div>
          <base-button :href="erachainSite" color="danger">{{ t(`${baseT}button`) }}</base-button>
        </div>
        <div class="technology-base__image d-none d-lg-block col-lg-6 offset-lg-1">
          <img :src="imageHomeTechnology" alt="Home technology">
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import BaseTitle from '@/components/Base/BaseTitle'
import BaseButton from '@/components/Base/BaseButton'
import imageHomeTechnology from '@/assets/technology-base.png'
import {useI18n} from 'vue-i18n'

const {t, locale} = useI18n()
const baseT = 'technology.base.'

const erachainSite = locale.value === 'ru'
    ? 'https://erachain.ru'
    : 'https://erachain.org'
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.technology-base {
  &__title {
    text-align: center;
    margin-bottom: 143px;
  }

  &__desc {
    margin: 34px 0 30px;
    font-size: 24px;
    line-height: 34px;
  }

  &__image {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
</style>

<template>
  <div class="constructor-price-notes">
    <div class="constructor-price-notes__item">
      <p>{{ t(`${baseT}notes.p1`) }}</p>
      <p>{{ t(`${baseT}notes.p2`) }}</p>
      <p>{{ t(`${baseT}notes.p3`) }}</p>
    </div>
    <div class="constructor-price-notes__item">
      <p>{{ t(`${baseT}notes.p4`) }}</p>
    </div>
    <div class="constructor-price-notes__item">
      <p>{{ t(`${baseT}notes.p5`) }}</p>
    </div>
  </div>
</template>

<script setup>
import {useI18n} from 'vue-i18n'
import {inject} from 'vue'

const {t} = useI18n()
const baseT = inject('baseT')
</script>

<style lang="scss" scoped>
.constructor-price-notes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  box-shadow: 0 4.60703px 52px -15px rgba(45, 74, 136, 0.24);
  border-radius: 29px;
  padding: 32px 0;

  &__item {
    padding: 0 40px;

    &:not(:first-child) {
      border-left: 1px solid #B8BFD6;
    }
  }

  p {
    margin: 0;
  }
}

@media (max-width: 991px) {
  .constructor-price-notes {
    grid-template-columns: 1fr 1fr;
    row-gap: 32px;

    &__item {
      &:last-child {
        border-left: none;
        border-right: 1px solid #B8BFD6;
      }
    }
  }
}

@media (max-width: 575px) {
  .constructor-price-notes {
    grid-template-columns: 1fr;

    &__item {
      &:not(:first-child) {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }
    }
  }
}
</style>

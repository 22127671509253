<template>
  <div class="base-radio">
    <input
        class="base-radio__item"
        name="radio-input"
        type="radio"
        :value="option"
        :id="id"
        :checked="modelValue === option"
        @change="changeRadio"
    >
    <label :for="id">
      <slot/>
    </label>
  </div>
</template>

<script setup>
import {computed, defineProps, defineEmits, toRefs} from 'vue'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  option: {
    type: [String, Number, Boolean],
    required: true,
  },
  modelValue: {
    type: [String, Number, Boolean],
  },
})
const {option} = toRefs(props)
const id = computed(() => `base-radio-${option.value}`)

const changeRadio = () => {
  emit('update:modelValue', option.value)
}
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.base-radio {
  position: relative;
  display: inline-block;

  &__item {
    position: absolute;
    z-index: -1;
    opacity: 0;

    & + label {
      position: relative;
      display: inline-flex;
      align-items: center;
      padding: 0 10px 0 22px;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 16px;
        height: 16px;
        border: 2px solid $border-color;
        border-radius: 50%;
        background: #FFF;
      }

      &::after {
        content: '';
        position: absolute;
        left: 5px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: $color-danger;
        opacity: 0;
        transition: .2s;
      }
    }

    &:checked + label::after {
      opacity: 1;
    }

    &:checked + label::before {
      border: 2px solid $color-danger;
    }
  }
}
</style>

<template>
  <div class="technology-network-protection">
    <div class="container">
      <div class="row align-items-center gy-4">
        <div class="col-md-5">
          <header class="technology-network-protection__header">
            <base-title type="h3">{{ t(`${baseT}title`) }}</base-title>
          </header>
          <div class="technology-network-protection__desc">
            {{ t(`${baseT}desc`) }}
          </div>
        </div>
        <div class="technology-network-protection__image col-md-7">
          <img :src="imageNetworkProtection" alt="network protection">
        </div>
      </div>
      <div class="technology-network-protection__info row gy-4">
        <div class="col-md-5">
          <home-block-darken>
            <template #title>{{ t(`${baseT}verify.title`) }}</template>
            <template #desc>{{ t(`${baseT}verify.desc`) }}</template>
          </home-block-darken>
        </div>
        <div class="technology-network-protection__info-desc col-md-6 offset-md-1">
          {{ t(`${baseT}rule`) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import imageNetworkProtection from '@/assets/network-protection.png'
import {useI18n} from 'vue-i18n'
import BaseTitle from '@/components/Base/BaseTitle'
import HomeBlockDarken from '@/pages/Home/components/HomeBlockDarken'

const {t} = useI18n()
const baseT = 'home.network_protection.'
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.technology-network-protection {
  overflow: hidden;

  &__desc {
    margin-top: $row * 1.5;
    font-size: 24px;
    line-height: 34px;
  }

  &__image {
    text-align: right;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__info {
    margin-top: $row * 2;

    &-desc {
      font-size: 24px;
      line-height: 34px;
    }
  }
}
</style>

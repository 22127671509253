<template>
  <div class="base-form-block">
    <h3 v-if="$slots.title" class="base-form-block__title">
      <slot name="title"/>
    </h3>
    <div class="base-from-block__content">
      <slot name="content"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseFormBlock',
}
</script>

<style lang="scss">
.base-form-block {
  &__title {
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 24px;
    font-weight: normal;
    margin: 0 0 40px;

    .base-tooltip {
      align-self: flex-start;
    }
  }
}
</style>

<template>
  <input
      :value="viewValue"
      type="text"
      :placeholder="placeholder"
      class="base-input"
      :class="{'base-input__error': error && saveForm}"
      @input="onInput"
      @keypress="onKeypress"
      @change="setInputValue"
      @focus="onFocus"
      @focusout="onFocusOut"
      :required="required"
  >
</template>

<script setup>
import {ref, toRefs, watch, defineProps, defineEmits, computed, inject} from 'vue'

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
  type: {
    type: String,
    default: 'text',
  },
  placeholder: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  postfix: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['update:modelValue'])
const {modelValue, required, postfix, type} = toRefs(props)
const inputValue = ref(modelValue.value)
const setInputValue = () => emit('update:modelValue', inputValue.value)
const error = computed(() => required.value && !inputValue.value)
const saveForm = inject('saveForm')

const correctOutput = () => {
  let output = ''
  if (inputValue.value) {
    if (type.value === 'number') {
      output += new Intl.NumberFormat('ru-RU').format(+inputValue.value)
    } else {
      output += inputValue.value
    }
  }
  if (inputValue.value && postfix.value) output += postfix.value
  return output
}

const viewValue = ref(correctOutput())

const onInput = event => {
  inputValue.value = event.target.value
  viewValue.value = event.target.value
}

const onKeypress = e => {
  if (type.value === 'number') {
    let x = e.charCode || e.keyCode
    if (isNaN(String.fromCharCode(e.which)) && x !== 46 || x === 32 || x === 13 ||
        (x === 46 && e.currentTarget.innerText.includes('.'))) e.preventDefault()
  }
}

const onFocus = () => {
  viewValue.value = inputValue.value
}

const onFocusOut = () => {
  viewValue.value = correctOutput()
}

watch(modelValue, value => {
  if (modelValue.value !== inputValue.value) {
    inputValue.value = value
    viewValue.value = correctOutput()
  }
})
</script>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

.base-input {
  width: 100%;
  border: none;
  border-bottom: 1px solid $border-color;
  outline: none;
  color: $color-text;
  padding: 0;
  font-feature-settings: 'pnum' on, 'lnum' on;
  background-color: transparent;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: $border-color;
  }

  &:focus {
    border-bottom: 1px solid $color-primary;
  }

  &:disabled {
    background-color: transparent;
    border-bottom: 1px solid $color-text;
  }

  &__error {
    border-bottom: 1px solid $color-danger !important;
  }
}
</style>

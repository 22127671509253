<template>
  <div class="technology-encryption">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-5">
          <header class="technology-encryption__header">
            <base-title type="h3">{{ t(`${baseT}title`) }}</base-title>
          </header>
          <div class="technology-encryption__desc">
            {{ t(`${baseT}desc`) }}
          </div>
        </div>
        <div class="technology-encryption__image col-md-5 offset-md-1">
          <img :src="imageEncrypted" alt="Encrypted">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'
import imageEncrypted from '@/assets/encrypted.png'

const {t} = useI18n()
const baseT = 'home.encryption.'
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.technology-encryption {
  font-size: 24px;
  line-height: 34px;

  &__header {
    margin-bottom: $row * 1.5;
  }

  &__image {
    text-align: center;
    max-width: 100%;
  }
}

</style>

<template>
  <div class="constructor-web">
    <base-form-block>
      <template #title>{{ t(`${baseT}title`) }}</template>
      <template #content>
        <div class="constructor-web__grid">
          <base-form-field>
            <template #label>{{ t(`${baseT}sidebar`) }}</template>
            <BaseColorPicker v-model:color="colorSidebar"/>
          </base-form-field>
          <base-form-field>
            <template #label>{{ t(`${baseT}button`) }}</template>
            <BaseColorPicker v-model:color="colorButton"/>
          </base-form-field>
          <base-form-field>
            <template #label>{{ t(`${baseT}link`) }}</template>
            <BaseColorPicker v-model:color="colorLink"/>
          </base-form-field>
        </div>
      </template>
    </base-form-block>
    <div class="constructor-web__preview">
      <ConstructorWebInterface/>
    </div>
  </div>
</template>

<script setup>
import {useI18n} from 'vue-i18n'
import BaseFormBlock from '@/components/Base/BaseFormBlock'
import BaseFormField from '@/components/Base/BaseFormField'
import BaseColorPicker from '@/components/Base/BaseColorPicker'
import ConstructorWebInterface from '@/pages/Constructor/components/ConstructorWebInterface'
import useCheckout from '@/use/checkout'
import {computed} from 'vue'

const {t} = useI18n()
const baseT = 'constructor.steps.two.web.'

const {addSetGetField} = useCheckout()

const base = 'style'
const action = 'UPDATE_STYLE'
const colorSidebar = computed(addSetGetField({base, action, field: 'colorSidebar'}))
const colorButton = computed(addSetGetField({base, action, field: 'colorButton'}))
const colorLink = computed(addSetGetField({base, action, field: 'colorLink'}))
</script>

<style lang="scss" scoped>
.constructor-web {
  &__grid {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 4fr;
    gap: 32px;
  }

  &__preview {
    margin-top: 55px;
  }
}

@media (max-width: 767px) {
  .constructor-web__grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 575px) {
  .constructor-web__grid {
    grid-template-columns: 1fr;
  }
}
</style>

<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__top">
        <div class="footer__logo">
          <a :href="linkErachain" target="_blank">
            <img :src="imageLogo" alt="Erachain">
          </a>
        </div>
        <div class="footer__widgets">
          <footer-widget class="footer__widget-contacts" router="contacts">
            <template #label>
              <span v-html="t('footer.consult_label')"/>
            </template>
            <template #button>{{ t('footer.consult_button') }}</template>
          </footer-widget>
          <footer-widget router="constructor" type="white">
            <template #label>
              <span v-html="t('footer.construct_label')"/>
            </template>
            <template #button>{{ t('footer.construct_button') }}</template>
          </footer-widget>
        </div>
      </div>
      <div class="footer__bottom">
        <div>
          <div class="footer__copyright">&copy; Erachain, {{ year }}</div>
          <a class="footer__link" :href="policyLink" target="_blank">
            {{ t('footer.policy') }}
          </a>
        </div>
        <div class="footer__socials">
          <a
              v-for="{url, component} in socials" :key="url"
              class="footer__social"
              :href="url"
              target="_blank"
          >
            <component :is="component"/>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import imageLogo from '@/assets/powered-white.svg'
import FooterWidget from '@/components/Footer/FooterWidget'
import {useI18n} from 'vue-i18n'
import {computed} from 'vue'
import {getSpecifiedSocial} from '@/helpers/socials'
import config from '@/config'

const {t, locale} = useI18n()
const year = new Date().getFullYear()

const socials = computed(() => locale.value === 'ru'
    ? getSpecifiedSocial(['youtube', 'telegram-ru', 'vk'])
    : getSpecifiedSocial(['linkedin', 'telegram']),
)
const linkErachain = computed(() => locale.value === 'ru'
    ? 'https://erachain.ru/home'
    : 'https://erachain.org/home',
)
const policyLink = computed(() => {
  return locale.value === 'ru'
      ? `${config.baseURL}/assets/policy-ru.pdf`
      : `${config.baseURL}/assets/policy-en.pdf`
})
</script>

<style lang="scss" scoped>
@import "../scss/variables";

.footer {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 48px 0 38px;
  background-color: #02071C;
  color: #E3E3E3;
  font-size: 14px;
  line-height: 16px;
  margin-top: auto;

  &__logo {
    img {
      height: 38px;
      width: auto;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__widgets {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 122px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #4B5059;
    padding-top: 20px;
    margin-top: 38px;
  }

  &__link {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: $color-danger;
    }
  }

  &__copyright {
    display: inline-block;
    margin-right: 30px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
  }

  &__widget-contacts {
    margin-right: -30px;
  }

  &__socials {
    display: flex;
    gap: 24px;
  }

  &__social {
    color: #E3E3E3;
    transition: .5s;

    &:hover {
      color: $color-danger
    }
  }
}

@media (max-width: 991px) {
  .footer {
    &__top {
      display: block;
    }

    &__widgets {
      margin-top: 24px;
      gap: 24px;
    }
  }
}

@media (max-width: 767px) {
  .footer {
    &__widgets {
      grid-template-columns: 1fr;
    }

    &__bottom {
      display: block;
    }

    &__socials {
      margin-top: 24px;
    }
  }
}
</style>

<template>
  <div class="cart-actions" :class="{'cart-actions_mini':mini}">
    <span class="cart-actions__reset" @click="toggleModalReset(true)">
      {{ mini ? t(`${baseT}reset_mini`) : t(`${baseT}reset`) }}
    </span>
    <base-button @action="goCheckout">
      {{ mini ? t(`${baseT}button_mini`) : t(`${baseT}button`) }}
    </base-button>
  </div>
  <teleport to="body">
    <base-modal-confirm
        v-if="openModalReset"
        @close="toggleModalReset(false)"
        @success="successModalReset"
    >
      <template #default>
        <p>{{ t(`${baseT}reset_desc_1`) }}</p>
        <p>{{ t(`${baseT}reset_desc_2`) }}</p>
      </template>
      <template #success>{{ t(`${baseT}reset_mini`) }}</template>
    </base-modal-confirm>
  </teleport>
</template>

<script setup>
import BaseButton from '@/components/Base/BaseButton'
import {computed, defineProps, defineEmits, ref} from 'vue'
import {useRouter} from 'vue-router'
import {useI18n} from 'vue-i18n'
import {useStore} from 'vuex'
import BaseModalConfirm from '@/components/Base/Modal/BaseModalConfirm'
import VueScrollTo from 'vue-scrollto'

defineProps({
  mini: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['save'])

const {t} = useI18n()
const baseT = 'checkout.info.'

const openModalReset = ref(false)
const toggleModalReset = status => openModalReset.value = status
const successModalReset = () => {
  reset()
  toggleModalReset(false)
  VueScrollTo.scrollTo('#step-one')
}

const store = useStore()
const router = useRouter()

const configStatus = computed(() => store.state.general.status)

const reset = () => {
  store.dispatch('reset')
}

const goCheckout = () => {
  if (!configStatus.value) {
    emit('save')
  } else {
    router.push({name: 'checkout'})
  }
}
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.cart-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__reset {
    color: $color-primary;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      color: $color-danger;
    }
  }

  &_mini {
    .base-button {
      font-size: 16px;
      padding: 12px 26px;
      height: 43px;
      line-height: 43px;
    }
  }
}
</style>

<template>
  <constructor-deployment-option class="constructor-deployment-professional">
    <template #icon>
      <IconDeploymentProfessional/>
    </template>
    <template #title>{{ t(`${baseT}tab`) }}</template>
    <template #desc>{{ t(`${baseT}desc`) }}</template>
    <template #default>
      <div class="constructor-deployment-professional__count">
        <div class="constructor-deployment-professional__desc">{{ t(`${baseT}count_desc`) }}</div>
        <div class="constructor-deployment-professional__count-list">
          <div
              v-for="({count, price}, idx) in nodes" :key="count"
              class="constructor-deployment-professional__count-item"
              :class="{'constructor-deployment-professional__count-item_active': activeMachine === count}"
              @click="changeMachine(count)"
          >{{ t(`${baseT}count_${idx + 1}`) }} - {{ price }}
          </div>
        </div>
      </div>
    </template>
  </constructor-deployment-option>
</template>

<script setup>
import {useI18n} from 'vue-i18n'
import {computed, watch} from 'vue'
import {useStore} from 'vuex'
import ConstructorDeploymentOption from '@/pages/Constructor/components/ConstructorDeploymentOption'
import IconDeploymentProfessional from '@/components/Icon/IconDeploymentProfessional'
import {defineProps, provide, toRefs} from 'vue'
import priceList from '@/helpers/priceList'
import {numberToCurrency} from '@/helpers'

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
})

const {active} = toRefs(props)
provide('active', active)

const {t, locale} = useI18n()
const baseT = 'constructor.steps.three.deployment.professional.'

const store = useStore()

const nodes = computed(() => ([
  {count: 4, price: numberToCurrency(priceList[locale.value].node4, locale.value)},
  {count: 8, price: numberToCurrency(priceList[locale.value].node8, locale.value)},
  {count: 16, price: numberToCurrency(priceList[locale.value].node16, locale.value)},
]))

const activeMachine = computed(() => store.state.general.machine)
const activeDeployment = computed(() => store.state.general.installation)

const changeMachine = (count) => {
  if (activeMachine.value !== count) {
    store.dispatch('general/updateMachine', count)
  }
}

watch(activeDeployment, value => {
  if (value === 'self') {
    changeMachine(null)
  }
})
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

@mixin button-style {
  border: 1px solid currentColor;
  border-radius: 42px;
  padding: 15px 20px;
  cursor: pointer;
}

.constructor-deployment-professional {
  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }

  &__desc {
    margin-top: 7px;
    margin-bottom: 20px;
  }

  &__count {
    &-list {
      display: flex;
      align-items: center;
      gap: 14px;
    }

    &-item {
      @include button-style();

      &_active {
        background: $color-danger;
        color: white;
        cursor: default;
      }

      &:hover:not(.constructor-deployment-professional__count-item_active) {
        border-color: $color-danger;
      }
    }
  }

  &__rent {
    &-list {
      display: flex;
      align-items: center;
      gap: 23px;
    }

    &-item {
      @include button-style();

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      &_active {
        background: $color-primary;
        color: white;
        cursor: default;
      }

      &:hover:not(.constructor-deployment-professional__rent-item_active) {
        border-color: $color-primary;
      }
    }

    &-note {
      margin-top: 25px;
      font-size: 16px;
      line-height: 22px;
      color: $border-color;
    }
  }
}

@media (max-width: 767px) {
  .constructor-deployment-professional {
    &__count-list, &__rent-list {
      flex-direction: column;
      align-items: stretch;
      gap: 12px;
    }
  }
}
</style>

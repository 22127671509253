<template>
  <div class="constructor-distribution-address">
    <div class="constructor-distribution-address__field">
      <span>
        <span class="constructor-distribution-address__number">№</span>
        {{ number }}
      </span>
    </div>
    <div class="constructor-distribution-address__field">
      <div class="constructor-distribution-address__label">
        {{ t(`${baseT}distribution.th_address`) }}
      </div>
      <BaseInput :model-value="address" disabled/>
    </div>
    <div class="constructor-distribution-address__field">
      <div class="constructor-distribution-address__label">
        {{ t(`${baseT}distribution.th_invest`) }}
      </div>
      <BaseInput
          v-model="currentInvestCount"
          type="number"
          :disabled="autoDistribution"
          :error="errorInvestToken"
      />
    </div>
    <div class="constructor-distribution-address__field">
      <div class="constructor-distribution-address__label">
        {{ t(`${baseT}distribution.th_fuel`) }}
      </div>
      <BaseInput
          v-model="currentFuelToken"
          type="number"
          :disabled="autoDistribution"
          :error="errorFuelToken"
      />
    </div>
    <div
        v-if="isRemove"
        class="constructor-distribution-address__remove"
        @click="removeAddress"
    >&times;
    </div>
  </div>
</template>

<script>
import BaseInput from '../../../components/Base/BaseInput.vue'
import {inject, ref, watch} from 'vue'
import {useI18n} from 'vue-i18n'

export default {
  name: 'constructorDistributionAddress',
  components: {BaseInput},
  props: {
    number: {
      type: Number,
      required: true,
    },
    address: {
      type: String,
      required: true,
    },
    investCount: {
      type: Number,
      default: 0,
    },
    fuelCount: {
      type: Number,
      default: 0,
    },
    isRemove: {
      type: Boolean,
      default: false,
    },
    autoDistribution: {
      type: Boolean,
      default: false,
    },
    errorInvestToken: {
      type: Boolean,
      default: false,
    },
    errorFuelToken: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:investCount', 'update:fuelCount', 'removeAddress'],
  setup(props, {emit}) {
    const currentInvestCount = ref(props.investCount)
    const currentFuelToken = ref(props.fuelCount)
    const removeAddress = () => emit('removeAddress')
    const {t} = useI18n()
    const baseT = inject('baseT')

    watch(currentInvestCount, value => emit('update:investCount', Number(value)))
    watch(currentFuelToken, value => emit('update:fuelCount', Number(value)))

    return {
      removeAddress,
      currentInvestCount,
      currentFuelToken,
      t,
      baseT,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.constructor-distribution-address {
  position: relative;
  display: grid;
  grid-template-columns: 24px 1.3fr 1fr 1fr;
  gap: 32px;

  &__remove {
    position: absolute;
    padding: 0 10px;
    top: 0;
    right: -30px;
    font-size: 30px;
    color: $color-danger;
    cursor: pointer;
  }

  &__field {
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  &__number {
    display: none;
  }

  &__label {
    display: none;
    color: $border-color;
    margin-bottom: 2px;
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .constructor-distribution-address {
    border: 1px solid $border-color;
    border-radius: 20px;
    padding: 20px;
    grid-template-columns: 1fr;
    gap: 14px;

    &__label {
      display: block;
    }

    &__number {
      display: inline;
    }

    &__remove {
      top: 10px;
      right: 10px;
    }
  }
}
</style>

import {createApp} from 'vue/dist/vue.esm-bundler'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store/index.js'
import './scss/main.scss'
import VueScrollTo from 'vue-scrollto'

const app = createApp(App)

app.use(i18n)
app.use(store)
app.use(router)
app.use(VueScrollTo, {easing: 'linear'})

app.mount('#app')

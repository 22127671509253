<template>
  <div class="mobile-menu">
    <nav class="mobile-menu__navigation">
      <ul class="mobile-menu__navigation-list">
        <li class="mobile-menu__navigation-item">
          <router-link :to="{name: 'constructor'}">{{ t('navigation.constructor') }}</router-link>
        </li>
        <li class="mobile-menu__navigation-item">
          <router-link :to="{name: 'technology'}">{{ t('navigation.technology') }}</router-link>
        </li>
        <li class="mobile-menu__navigation-item">
          <router-link :to="{name: 'faq'}">{{ t('navigation.faq') }}</router-link>
        </li>
        <li class="mobile-menu__navigation-item">
          <a :href="erachainLink" target="_blank">{{ t('navigation.home') }}</a>
        </li>
        <li class="mobile-menu__navigation-item">
          <router-link :to="{name: 'contacts'}">{{ t('navigation.contacts') }}</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import {useI18n} from 'vue-i18n'

const {t, locale} = useI18n()
const erachainLink = locale.value === 'ru'
    ? 'https://erachain.ru'
    : 'https://erachain.org'
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.mobile-menu {
  position: fixed;
  inset: 0;
  background-color: white;
  padding: 20vh 0 8vh;
  text-align: center;

  &__navigation {
    &-list {
      padding: 0;
      margin: 0;
    }

    &-item {
      list-style: none;
      margin-bottom: 22px;

      a {
        position: relative;
        font-size: 20px;
        line-height: 32px;
        text-decoration: none;

        &.router-link-active {
          font-weight: 600;

          &::after {
            position: absolute;
            left: -10px;
            top: 0;
            content: '';
            width: 6px;
            height: 6px;
            background-color: $color-primary;
            border-radius: 3px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="home-advantage-item-flexible">
    <div class="row gy-4">
      <div class="col-md-5 order-last order-md-first">
        <div class="home-advantage-item-flexible__table">
          <div class="home-advantage-item-flexible__thead">
            {{ t(`${baseT}thead`) }}
          </div>
          <div class="home-advantage-item-flexible__tbody">
            <div class="home-advantage-item-flexible__tr">
              <div class="home-advantage-item-flexible__td">{{ t(`${baseT}th_size`) }}</div>
              <div class="home-advantage-item-flexible__td">{{ t(`${baseT}td_size`) }}</div>
            </div>
            <div class="home-advantage-item-flexible__tr">
              <div class="home-advantage-item-flexible__td">{{ t(`${baseT}th_time`) }}</div>
              <div class="home-advantage-item-flexible__td">{{ t(`${baseT}td_time`) }}</div>
            </div>
          </div>
          <div class="home-advantage-item-flexible__tfoot">
            {{ t(`${baseT}tfoot`) }}
          </div>
        </div>
      </div>
      <div class="col-md-6 offset-md-1">
        <header class="home-advantage-item-flexible__header">
          <base-title type="h3">{{ t(`${baseT}title`) }}</base-title>
        </header>
        <div class="home-advantage-item-flexible__desc">{{ t(`${baseT}desc`) }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
const baseT = 'home.advantage.items.flexible.'
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.home-advantage-item-flexible {
  &__table {
    position: relative;
    border: 2px solid #F5733B;
    border-radius: 29px;
    padding: $row;
  }

  &__thead {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
  }

  &__tbody {
    margin: 28px 0;
  }

  &__tr {
    display: grid;
    grid-template-columns: 1fr 130px;
    border-bottom: 1px solid #F5733B;

    &:first-child {
      border-top: 1px solid #F5733B;
    }
  }

  &__td {
    padding: 6px 0;
    font-weight: 500;
  }

  &__desc {
    margin-top: $row * 1.5;
    font-size: 24px;
    line-height: 34px;
  }
}
</style>

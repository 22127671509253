<template>
  <div class="price-item-option">
    <div class="price-item-option__desc">
      <slot/>
    </div>
    <div v-if="$slots.note" class="price-item-option__note">
      <slot name="note"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PriceItemOption',
}
</script>

<style lang="scss" scoped>
.price-item-option {
  font-size: 16px;
  line-height: 19px;

  &__note {
    margin-top: 4px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.04em;
    color: #404453;
  }
}
</style>

<template>
  <div
      contenteditable="true"
      :placeholder="placeholder"
      class="base-textarea-flexible"
      ref="input"
      @input="setInputValue"
  />
</template>

<script>
import {ref, toRefs, watch} from 'vue'

export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const {modelValue} = toRefs(props)
    const input = ref(null)
    const setInputValue = event => emit('update:modelValue', event.target.textContent || '')
    watch(modelValue, value => {
      if (input?.value?.textContent && input.value.textContent !== value) {
        input.value.textContent = value
      }
    })
    return {
      input,
      setInputValue,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

.base-textarea-flexible {
  position: relative;
  border-bottom: 1px solid $border-color;
  outline: none;

  &[placeholder]:empty:before {
    position: absolute;
    content: attr(placeholder);
    color: $border-color;
  }

  &[placeholder]:empty:focus::before {
    content: "";
  }

  &:focus {
    border-bottom: 1px solid $color-primary;
  }
}
</style>

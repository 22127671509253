<template>
  <div class="home-projects">
    <div class="container">
      <div class="home-projects__wrapper">
        <header class="home-projects__header">
          <base-title type="h2">{{ t(`${baseT}title`) }}</base-title>
        </header>
        <swiper :modules="modules" :slidesPerView="'auto'" navigation :space-between="32">
          <swiper-slide
              v-for="([projectOne, projectTwo], idx) in projects"
              class="home-projects__slide"
              :key="idx"
          >
            <HomeProjectItem
                v-if="projectOne"
                :class="['home-projects__item', `home-projects__item-${projectOne.name}`]"
                :data="projectOne"
            />
            <HomeProjectItem
                v-if="projectTwo"
                :class="['home-projects__item', `home-projects__item-${projectTwo.name}`]"
                :data="projectTwo"
            />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'
import {Navigation} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue.js'
import 'swiper/swiper-bundle.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import HomeProjectItem from '@/pages/Home/components/HomeProjectItem'

const modules = [Navigation]

const {t} = useI18n()
const baseT = 'home.projects.'

const projects = [
  [
    {
      name: 'foil',
      image: require('@/assets/project-foil.png'),
      link: 'https://foil.network/',
      tag: 'blockchain',
    },
    {
      name: 'yaenerg',
      image: require('@/assets/project-yaenerg.png'),
      link: 'https://yaenergetik.ru/',
      tag: 'integration',
    },
  ],
  [
    {
      name: 'nft',
      image: require('@/assets/project-nft.png'),
      link: 'https://web.foil.network/services/nft/shop',
      tag: 'd_app',
    },
    {
      name: 'domdara',
      image: require('@/assets/project-domdara.png'),
      link: 'https://domdara.com/',
      tag: 'blockchain',
    },
  ],
  [
    {
      name: 'iplab',
      image: require('@/assets/project-iplab.png'),
      link: 'https://iplab.su/en/Glavnaya',
      tag: 'blockchain',
    },
    {
      name: 'safepay',
      image: require('@/assets/project-safepay.png'),
      link: 'https://safe-pay.ru/',
      tag: 'integration',
    },
  ],
  [
    {
      name: 'vomer',
      image: require('@/assets/project-vomer.png'),
      link: 'https://vomer.net/',
      tag: 'blockchain',
    },
    {
      name: 'bill',
      image: require('@/assets/project-bill.png'),
      tag: 'd_app',
    },
  ],
]
</script>

<style lang="scss">
@import "~@/scss/variables.scss";
@import "~@/scss/functions.scss";

.home-projects {
  &__wrapper {
    position: relative;
    z-index: 1;

    &:after {
      position: absolute;
      content: '';
      width: getWidthPercentShadow(713);
      padding-top: getHeightPercentShadowPrimary(713);
      background-image: url("~@/assets/shadow-primary.svg");
      background-size: cover;
      top: -5%;
      left: -20%;
      filter: blur(90px);
      opacity: .45;
      z-index: -1;
    }

    &:before {
      position: absolute;
      content: '';
      width: getWidthPercentShadow(604);
      padding-top: getHeightPercentShadowDanger(494);
      background-image: url("~@/assets/shadow-danger.svg");
      background-size: cover;
      bottom: -20%;
      right: -15%;
      z-index: -1;
      filter: blur(90px);
    }
  }

  &__header {
    margin-bottom: 55px;
  }

  &__slide {
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 491px;
  }

  &__item {
    color: $color-text;

    @media all and (max-width: 767px) {
      img {
        max-width: 40%;
      }
    }

    &-foil .home-project-item__image img {
      bottom: 0;
      right: 10px;
    }

    &-yaenerg .home-project-item__image img {
      bottom: 7px;
      right: 12px;
    }

    &-nft .home-project-item__image img {
      bottom: 12px;
      right: 12px;
    }

    &-domdara .home-project-item__image img {
      bottom: 23px;
      right: 12px;
    }

    &-iplab .home-project-item__image img {
      bottom: 41px;
      right: 3px;
    }

    &-safepay .home-project-item__image img {
      bottom: 0;
      right: 27px;
    }

    &-vomer .home-project-item__image img {
      bottom: 25px;
      right: 37px;
    }

    &-bill .home-project-item__image img {
      bottom: 41px;
      right: 23px;
    }
  }

  .swiper {
    padding-top: 111px;
    margin-top: -111px;
    overflow: visible;

    &-button-prev, &-button-next {
      top: 0;
      margin-top: 0;
      right: 0;
      left: auto;

      @media all and (max-width: 767px) {
        top: 50px;
      }
    }

    &-button-prev {
      right: 55px;
    }
  }
}
</style>

<template>
  <div class="home-consultation">
    <div class="container">
      <div class="home-consultation__top d-none d-md-grid">
        <div class="home-consultation__image">
          <img :src="imageConsultationStart" alt="consultation start">
        </div>
        <div class="home-consultation__text-1">{{ t(`${baseT}text_1`) }}</div>
        <div class="home-consultation__text-2">{{ t(`${baseT}text_2`) }}</div>
        <div class="home-consultation__text-3">{{ t(`${baseT}text_3`) }}</div>
      </div>
      <div class="home-consultation__wrapper">
        <div class="row">
          <div class="col-lg-6">
            <div class="home-consultation__info">
              <header class="home-consultation__header">
                <base-title type="h2">{{ t(`${baseT}title`) }}</base-title>
              </header>
            </div>
          </div>
          <div class="col-lg-5 offset-lg-1 d-flex align-content-stretch">
            <div class="home-consultation__start">
              <div class="home-consultation__start-desc" v-html="t(`${baseT}start_desc`)"/>
              <base-button color="danger" @action="goContacts">{{ t(`${baseT}button`) }}</base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'
import BaseButton from '@/components/Base/BaseButton'
import useActionRouter from '@/use/actionRouter'
import imageConsultationStart from '@/assets/consultation-start.png'

const {goContacts} = useActionRouter()

const {t} = useI18n()
const baseT = 'home.consultation.'
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";

.home-consultation {
  &__top {
    display: grid;
    justify-content: center;
    margin-bottom: 108px;
    font-weight: 600;
    font-size: 82px;
    line-height: 96px;
  }

  &__image {
    position: relative;
    margin-bottom: -175px;
    margin-left: -145px;
  }

  &__text-1 {
    margin-left: 80px;
  }

  &__text-2 {
    margin-top: -37px;
    margin-left: 125px;
  }

  &__text-3 {
    margin-top: -25px;
    margin-left: 0;
  }

  &__wrapper {
    border: 1px solid $color-danger;
    border-radius: 23px;
  }

  &__info {
    padding-left: 72px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__header {
    margin-bottom: 22px;
  }

  &__start {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1E1A42;
    border-radius: 15px;
    padding: 22px 15% 55px;
    margin: 24px 24px 24px 0;

    &-desc {
      padding: 30px;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      text-align: center;

      @media (max-width: 767px) {
        padding: 30px 0;
      }
    }

    .base-button {
      box-shadow: 0 10px 13px -8px rgba(244, 97, 77, 0.3);

      &:hover {
        box-shadow: none;
      }
    }
  }
}

@media (max-width: 991px) {
  .home-consultation {
    &__info {
      padding: 40px;
    }

    &__start {
      margin: 0;
      border-radius: 0 0 22px 22px;
    }
  }
}

@media (max-width: 767px) {
  .home-consultation__faq {
    grid-template-columns: 1fr;
    gap: 24px;
  }
}
</style>

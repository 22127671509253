<template>
  <div class="checkout-page">
    <div class="container">
      <header class="checkout-page__header">
        <base-title>{{ t(`${baseT}title`) }}</base-title>
      </header>
      <div class="checkout-page__wrapper row gy-4">
        <div class="col-lg-7">
          <CheckoutForm/>
        </div>
        <div class="col-lg-5 col-xl-4 offset-xl-1 order-first order-lg-last">
          <CheckoutInfo/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseTitle from '@/components/Base/BaseTitle'
import {useI18n} from 'vue-i18n'
import CheckoutForm from '@/pages/Checkout/components/CheckoutForm'
import CheckoutInfo from '@/pages/Checkout/components/CheckoutInfo'

const {t} = useI18n()
const baseT = 'checkout.'
</script>

<style lang="scss" scoped>
.checkout-page {
  margin-top: 75px;

  &__header {
    text-align: center;
  }

  &__wrapper {
    margin-top: 108px;
    margin-bottom: 180px;
  }
}
</style>

<template>
  <div class="home-capability">
    <div class="container">
      <div class="home-capability__tabs">
        <div
            v-for="(tab, idx) in tabs"
            class="home-capability__tab"
            :class="{'home-capability__tab_active': idx === tabActiveIdx}"
            @click="changeTab(idx)"
            :key="tab"
        >{{ t(`${baseT}tab_${tab}`) }}
        </div>
      </div>
      <div class="home-capability__content">
        <HomeCapabilityBlockBlockchain v-if="tabActiveIdx === 0"/>
        <HomeCapabilityBlockApplications v-if="tabActiveIdx === 1"/>
        <HomeCapabilityBlockBranding v-if="tabActiveIdx === 2"/>
      </div>
      <div class="home-capability__help">
        <div class="row">
          <div class="col-md-5 home-capability__help-title">
            {{ t(`${baseT}help_title`) }}
          </div>
          <div class="col-md-6 offset-md-1">
            {{ t(`${baseT}help_desc`) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import HomeCapabilityBlockBlockchain from '@/pages/Home/components/HomeCapabilityBlockBlockchain'
import HomeCapabilityBlockApplications from '@/pages/Home/components/HomeCapabilityBlockApplications'
import {ref} from 'vue'
import {useI18n} from 'vue-i18n'
import HomeCapabilityBlockBranding from '@/pages/Home/components/HomeCapabilityBlockBranding'

const {t} = useI18n()
const baseT = 'home.capability.'

const tabs = ['blockchain', 'app', 'brand']
const tabActiveIdx = ref(0)

const changeTab = tabIdx => {
  if (tabActiveIdx.value !== tabIdx) {
    tabActiveIdx.value = tabIdx
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.home-capability {
  &__header {
    text-align: center;
  }

  &__tabs {
    display: flex;
    align-items: center;
    gap: 83px;
    font-size: 24px;
    line-height: 1.17;
    text-transform: uppercase;
    margin-bottom: 66px;

    @media (max-width: 767px) {
      font-size: 18px;
      gap: 10px 40px;
      flex-wrap: wrap;
    }
  }

  &__tab {
    padding-bottom: 8px;
    border-bottom: 3px solid transparent;
    cursor: pointer;

    &_active {
      border-bottom: 3px solid currentColor;
      text-shadow: 0 0 .7px black, 0 0 .7px black;
    }

    &:hover:not(.home-capability__tab_active) {
      text-shadow: 0 0 .7px black, 0 0 .7px black;
    }
  }

  &__content {
    position: relative;
    margin-bottom: 96px;
  }

  &__help {
    padding: 51px 0;
    border-top: 1px solid #79778E;
    border-bottom: 1px solid #79778E;
    font-size: 24px;
    line-height: 34px;

    &-title {
      font-size: 42px;
      line-height: 49px;
    }
  }
}
</style>
